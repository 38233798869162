
import React, { useEffect, useRef, useState } from 'react'
import "../../Styles/BuyPageStyle/AgentDetail.css"
import "../../Styles/RentPageStyle/MainRent.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, } from '@fortawesome/free-regular-svg-icons'
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import userPlaceIcon from "../../assets/BuyDetailPage/user_icon.png"
import { useParams } from 'react-router-dom'
import config, { axiosbaseurl } from '../../config'
import CustomLoader from '../CustomLoader'
import HorizontalPropertyCard from './HorizontalPropertyCard'
import VerticlePropertyCard from './VerticlePropertyCard'
import ReadMore from '../../Common/ReadMore'
import WorkModalForm from './WorkModalForm'

const AgentDetails = () => {

  const [proprenuerData, setPropreneurData] = useState(null)
  const [allProperties, setAllProperties] = useState([{}])

  const [currentProperties, setCurrentProperties] = useState(
    {
      ptype: 'sale',
      properties: []
    }
  )
  const [pastProperties, setPastProperties] = useState(
    {
      ptype: 'sale',
      properties: []
    }
  )

  const [loading, setLoading] = useState(false);

  const [workModal, setWorkModal] = useState(false);


  const { prop_id } = useParams()

  useEffect(() => {
    const controller = new AbortController();
    async function getProprenuerDetails() {
      setLoading(true)

      try {
        const { data } = await axiosbaseurl.get(`/proprenuer/${prop_id}`);

        if (data.success == true) {
          setPropreneurData(data.data)

          const all_properties = data.data.property
            ?.map(prop => ({ ...prop, image: prop.images.filter(img => img.upload_type === 'image')[0]?.upload_name }))
            ?.sort((a, b) => (b.id - a.id))

          const current_properties = all_properties.filter(item => (item.property_availablity !== 'SOLD' && item.property_on.toLowerCase() == 'sale'))

          const past_properties = all_properties.filter(item => (item.property_availablity === 'SOLD' && item.property_on.toLowerCase() == 'sale'))

          setAllProperties(
            {
              current_properties: all_properties.filter(item => (item.property_availablity !== 'SOLD')),
              past_properties: all_properties.filter(item => (item.property_availablity === 'SOLD'))
            }
          )

          setCurrentProperties(
            {
              ptype: 'sale',
              properties: current_properties
            }
          )

          setPastProperties(
            {
              ptype: 'sale',
              properties: past_properties
            }
          )
        }

      } catch (error) {
        console.log(error)
      }
      finally {
        setLoading(false)

      }
    }

    getProprenuerDetails()

    return () => {
      controller.abort();
    };
  }, [])

  const sliderRef = useRef();

  const blogsSettings = {
    dots: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 2,
    swipeToSlide: true,
    autoplay: true,
    arrows: false,
    mobileFirst: true,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
          infinite: true,
        },
      },
    ],
  };

  const teamSettings = {
    dots: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 4,
    swipeToSlide: true,
    autoplay: true,
    arrows: false,
    mobileFirst: true,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleFilterProperty = (event, setProperties, prop_category) => {

    const filterBy = event.target.checked ? 'rent' : 'sale';
    const amount_type = (filterBy === 'rent') ? 'price_per_month' : 'price';

    const properties = allProperties[prop_category]?.filter(item => item.property_on.toLowerCase() == filterBy)
      ?.map(prop => ({ ...prop, price: prop[amount_type] }))

    setProperties(
      {
        ptype: filterBy,
        properties
      }
    )
  }


  const userImage =
    proprenuerData?.prop_avatar?.slice(-1) === '/' ? userPlaceIcon : proprenuerData?.prop_avatar
  return (
    <div>
      {loading && <CustomLoader />}

      <section className="pb-70">
        <div>
          <div className="container" style={{ maxWidth: "1260px" }}>
            <div className="row pt-130">
              <div className="col-md-4 pe-md-4">
                <div>
                  <div className="">
                    <div className="bd-agent-img mb-30">
                      <img className=" w100 " src={userImage} alt="" />
                    </div>
                    <div>
                      <div className="agent-social-icons d-flex justify-content-between">
                        <div className="">
                          <div className="bd-social-links fa-face">
                            <a href={proprenuerData?.facebook} target='_blank' className='text-decoration-none'>
                              <i className="fa fa-facebook bd-links"></i>
                            </a>
                          </div>
                        </div>
                        <div className="">
                          <div className="bd-social-links fa-twit">
                            <a href={proprenuerData?.twitter} target='_blank' className='text-decoration-none'>
                              <i className='bi bi-twitter-x bd-links fs-5'></i>
                            </a>
                          </div>
                        </div>
                        <div className=" ">
                          <div className="bd-social-links fa-insta">
                            <a href={proprenuerData?.instagram} target='_blank' className='text-decoration-none'>
                              <i className="fa fa-instagram bd-links"></i>
                            </a>
                          </div>
                        </div>
                        <div className="">
                          <div className="bd-social-links fa-linke">
                            <a href={proprenuerData?.linkedn} target='_blank' className='text-decoration-none'>
                              <i className="fa fa-linkedin bd-links"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div >
                    <div className="pb-30 pt-40">
                      <div className="" >
                        <h2 className="bd-about-header fw-bold mb-0">Awards</h2>
                        <div className="yellow-line"></div>
                      </div>
                      <div className="mb-10 mt-10">
                        {
                          proprenuerData?.company_name?.split("$")?.map((item, index) => {
                            return (
                              <p key={index} className="bd-awards-info bd-Social-info mt-2 mb-0" >
                                {item || "N/A"}
                              </p> 
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                  {
                    proprenuerData?.socialLinks?.length > 0 && (
                      <div className="pb-30">
                        <div className="">
                          <h2 className="bd-about-header fw-bold mb-0">Personal Bio</h2>
                          <div className="yellow-line"></div>
                        </div>
                        <div className="mb-10 mt-10">
                        {
                          proprenuerData?.socialLinks?.map((item) => (
                            <>
                              <a
                                href={item?.url?.startsWith('http') ? item?.url : `https://${item?.url}`}
                                target='_blank'
                                className='Bio-Links'
                              >
                                {item?.label}
                              </a>
                              <br />
                            </>
                          ))
                        }
                        </div>
                      </div>
                    )
                  }
                  <div className="pb-30">
                    <div>
                      <h2 className="bd-about-header mb-0">Active in Cities</h2>
                      <div className="yellow-line"></div>
                    </div>
                    <div className="mb-10 mt-15">
                      <div className="main-row flex-wrap">
                      {
                        proprenuerData?.activecities?.split(',').map((city, index) => {
                          // Split active_cities and trim spaces to get the correct city IDs
                          const cityId = proprenuerData?.active_cities?.split('$')[index]?.trim();

                          return (
                            <div key={index} className="me-3">
                              <a 
                                href={`${config.appUrl}propuser-locality/${cityId}`} 
                                className="bd-cities"
                              >
                                {city || "N/A"}
                              </a>
                            </div>
                          )
                        })
                      }
                      </div>
                    </div>
                  </div>
                  <div className="pb-30">
                    <div className=" " >
                      <h2 className="bd-about-header mb-0">Work Experience</h2>
                      <div className="yellow-line"></div>
                    </div>
                    <div className="mb-10 mt-15">
                      <p className="bd-Social-info">
                        {proprenuerData?.total_experience} Years Total Experiance</p>
                      <p className="bd-Social-info">
                        {proprenuerData?.real_estate_experience} Years of Experiance In Real Estate</p>
                      <p className="bd-Social-info">
                        {proprenuerData?.other_industries_experience} Years of Experiance In Other Industry</p>
                    </div>
                  </div>

                  <div className="pb-30">
                    <div className=" ">
                      <h2 className="bd-about-header mb-0">Education</h2>
                      <div className="yellow-line"></div>
                    </div>
                    <div className="mb-10 mt-15">
                      {
                        proprenuerData?.education?.split('$')?.map((item, index) => {
                          return <p key={index} className="bd-Social-info">{item || "N/A"}</p>
                        })
                      }
                    </div>
                  </div>

                  <div className="pb-30">
                    <div className="">
                      <h2 className="bd-about-header font-saira fw-bold mb-0">Memberships/Boards</h2>
                      <div className="yellow-line"></div>
                    </div>
                    <div className="mb-10 mt-15">
                      {
                        proprenuerData?.membership?.split('$')?.map((item, i) => {
                          return <p key={i} className="bd-Social-info font-saira">{item || "N/A"} </p>
                        })
                      }
                    </div>
                  </div>

                  <div>

                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <div>
                  <div className="mb-30">
                    <div className="">
                      {
                        proprenuerData &&
                        <h1 className="bd-Agent-header font-saira fw-bold mb-0">{`${proprenuerData?.fname} ${proprenuerData?.lname}`}</h1>
                      }
                      <div className="yellow-line"></div>
                    </div>
                    <div className="mt-10">
                      <p className="bd-details mb-3 fw-500 font-saira">RERA Licensed Real Estate Sales Person </p>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faEnvelope} className='me-2' />
                      <a href={`mailto:${proprenuerData?.propuser_email}`} className='bd-social-detail font-saira text-decoration-none'>{proprenuerData?.propuser_email}</a>
                    </div>
                    <div className='mt-1'>
                      <FontAwesomeIcon icon={faPhoneVolume} className='me-2' />
                      <a href={`tel:${proprenuerData?.contact}`} className='bd-social-detail font-saira text-decoration-none'>{proprenuerData?.contact}</a>
                    </div>
                    <div>
                      <p className="bd-main-area-info mt-3 font-saira" >RERA No: {proprenuerData?.rera}</p>
                    </div>
                    <div className="mb-5 mt-4">
                      <button className="bd-detail-btn font-saira" onClick={() => setWorkModal(true)}>Work with {proprenuerData?.name}</button>
                    </div>
                    <div className=" " >
                      <h2 className="bd-about-header font-saira fw-bold mb-0">About {proprenuerData?.name}</h2>
                      <div className="yellow-line"></div>
                    </div>
                    <div className="pb-35">
                      {/* <ReadMore initialLength={900}>
                        {proprenuerData?.description}
                      </ReadMore> */}
                      <p
                        className="bd-info-agent pt-1 justified-text"
                        dangerouslySetInnerHTML={{ __html: proprenuerData?.description }}
                      />

                    </div>
                  </div>
                  {
                    proprenuerData?.tesimonials?.length > 0 &&
                    <div className="pb-70">
                      <div className=" " >
                        <h2 className="bd-about-header font-saira fw-bold mb-0">Client Testimonials</h2>
                        <div className="yellow-line"></div>
                      </div>
                      <div className='row mt-4 '>
                        <Slider ref={sliderRef} {...blogsSettings} className='pb-4'>
                          {
                            proprenuerData?.tesimonials?.map((review) => {
                              return (
                                <div key={review?.id} className="col-md-6 mb-md-0 mb-5 px-2">
                                  <div className='review-container'>
                                    <div>
                                      <div className='position-relative'>
                                        <p className='font-saira fw-500' style={{ maxHeight: '230px', overflow: 'auto', scrollbarWidth: 'thin', position: 'relative', zIndex: 10, textIndent: '30px' }}>
                                          <p
                                            className="bd-info-agent pt-1"
                                            dangerouslySetInnerHTML={{ __html: review?.testimonial_comments }}
                                          />
                                          {/* {review?.testimonial_comments} */}
                                        </p>
                                        <div className='quote-top-icon'>“</div>
                                      </div>

                                      <div className='my-4 text-center'>
                                        <h5 className='bd-about-header font-saira'>{review?.name}</h5>
                                        <div className='font-saira mb-2'>{review?.profession}</div>

                                        <div className='mb-4'>
                                          {
                                            [...Array(5)].map((_, index) => {
                                              ++index;
                                              return (
                                                <i key={index} className={`bi bi-star${index <= review?.rating ? '-fill' : ''} text-yellow mx-1`}></i>
                                              )
                                            })
                                          }
                                        </div>
                                      </div>

                                      <div className='review-image'>
                                        <img src={review?.image} alt="client_img" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          }

                        </Slider>
                      </div>
                    </div>
                  }

                  <div>
                    <div className=" " >
                      <h2 className="bd-about-header font-saira fw-bold mb-0">Team Members</h2>
                      <div className="yellow-line"></div>
                    </div>
                    <div className="textCenter  ">
                      {/* Team members */}
                      <div >
                        <Slider ref={sliderRef} {...teamSettings} className='pb-2' >
                          {
                            proprenuerData?.teammates?.map((team) => {
                              return (
                                <div className=" px-2 my-3" key={team?.id}>
                                  <div className="bd-team-section ">
                                    <div className="">
                                      <img className="w100 team-img " src={team?.image} alt="" />
                                    </div>
                                    <div className="bd-vertical-sect my-2"></div>
                                    <div>
                                      <h5 className="mb-0 font-saira text-dark-blue fw-500">{team?.name}</h5>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          }
                        </Slider>
                        {
                          proprenuerData?.teammates.length === 0 && (
                            <h5 className='text-center w-100'>Teammates Not Available !</h5>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className=" pb-50" >
            <div className="textCenter main-heading-agent mb-30 d-flex flex-column align-items-center">
              <div>
                <h2 className="bd-agent-head font-saira">{proprenuerData?.name}’s Property Listings</h2>
                <div className='yellow-line'></div>
              </div>
            </div>
            <div >
              <div className="btn-container">
                <label className="switch btn-color-mode-switch">
                  <input type="checkbox" name="color_mode" id="color_mode" value={currentProperties.ptype}
                    onChange={(e) => handleFilterProperty(e, setCurrentProperties, 'current_properties')} />
                  <label htmlFor="color_mode" data-on="Rent" data-off="Sales" className="btn-color-mode-switch-inner font-saira fw-500"></label>
                </label>
              </div>
            </div>
          </div>

          <div className="container " style={{ maxWidth: "1260px" }}>

            <div className="row">
              {
                currentProperties?.properties?.map((property) => {
                  return (
                    <div className="col-md-3 mb-40" key={property?.id}>
                      <VerticlePropertyCard property={property} ptype={currentProperties.ptype} />
                    </div>
                  )
                })
              }
              {
                currentProperties?.properties?.length === 0 &&
                <h5 className='text-center'>Properties Not Available !</h5>
              }

            </div>
          </div>
        </div>

      </section>

      <section className="pt-80">
        <div>
          <div>
            <div className=" pb-50" >
              <div className="textCenter main-heading-agent mb-30 d-flex flex-column align-items-center">
                <div>
                  <h2 className="bd-agent-head font-saira">{proprenuerData?.name}’s Past Property</h2>
                  <div className='yellow-line'></div>
                </div>
              </div>
              <div >
                <div className="btn-container">
                  <label className="switch btn-color-mode-switch">
                    <input type="checkbox" name="color_mode" id="past_property_btn" value={currentProperties.ptype}
                      onChange={(e) => handleFilterProperty(e, setPastProperties, 'past_properties')} />
                    <label htmlFor="past_property_btn" data-on="Rent" data-off="Sales" className="btn-color-mode-switch-inner font-saira fw-500"></label>
                  </label>
                </div>
              </div>
            </div>

            <div className="container" style={{ maxWidth: "1260px" }}>

              <div className="row mb-5">
                {
                  pastProperties?.properties?.map((property) => {
                    return (
                      <div className="col-md-3 mb-40" key={property?.id}>
                        <VerticlePropertyCard property={property} ptype={pastProperties.ptype} />
                      </div>
                    )
                  })
                }
                {
                  pastProperties?.properties?.length === 0 &&
                  <h5 className='text-center'>Properties Not Available !</h5>
                }

              </div>

            </div>
          </div>
        </div>
      </section>

      <WorkModalForm workModal={workModal} setWorkModal={setWorkModal} propreneur_id={proprenuerData?.id} />
    </div>
  )
}

export default AgentDetails