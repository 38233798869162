import React, { useContext, useEffect, useState } from "react";
import CustomLoader from "../CustomLoader";
import "../../assets/Css/Homeloan.css";
import { axiosbaseurl } from "../../config";
import CalculateLoan from "./CalculateLoan";
import { customToastOptions } from "../../Styles/popup_style";
import toast, { Toaster } from "react-hot-toast";

import secureLocalStorage from "react-secure-storage";
import { AppGlobalContext } from "../../GlobalContext";

const HomeLoanIndex = () => {
  const [loading, setLoading] = useState(false);
  const [contactDetails, setContactDetails] = useState({});
  const [bankData, setBankData] = useState([]);
  const [isExpanded, setIsExpanded] = useState(true);
  const [visibleBanks, setVisibleBanks] = useState([]);
  const [loanHeading, setLoanHeading] = useState([]);
  const [isShown, setIsShown] = useState(false);
  const [activeFilter, setActiveFilter] = useState('')

  const { current_location } = useContext(AppGlobalContext);
  const credentials = secureLocalStorage.getItem("credentials");

  const handleClick = event => {
    setIsShown(current => !current);
  };

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    axiosbaseurl
      .get("contactUsData")
      .then((res) => {
        setContactDetails(res.data.data);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    axiosbaseurl
      .get("/loan")
      .then((res) => {
        setLoanHeading(res.data.titles)

        const modifyBankData = res.data?.data?.banks?.filter(item => item?.status === "1").map((item) => ({
          id: item.id,
          // img: `https://demo.propusers.com/admin/public/images/banks/${item.bank_image}`,
          img: `${item.bank_image}`,
          tenure: item.tenure,
          interest: item.interest_rate,
          processingFees: item.fee,
          apply_link: item.apply_link,
          bank_name: item.bank_name
        }));
        setBankData(modifyBankData);
        setVisibleBanks(modifyBankData);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));

    return () => {
      controller.abort();
    };
  }, []);

  const handleSeeMoreClick = () => {
    if (isExpanded) {
      setVisibleBanks(bankData.slice(0, 5)); // Show only 5 banks
    } else {
      setVisibleBanks(bankData); // Show all banks
    }
    setIsExpanded(!isExpanded); // Toggle the expanded state
  };

  const handleApply = async (link) => {
    if (credentials) {
      setLoading(true);
      const post_data = {
        user_id: credentials?.user_id,
        location: current_location?.city_name
      }
      const response = await axiosbaseurl.post(`loanEnquiry`, post_data)
      
      if (response.data.success) {
        setLoading(false);

        const newWindow = window.open(link, '_blank', "noopener,noreferrer");
        if (!newWindow) {
          alert("Popup was blocked. Please enable popups for this site.");
        }
      }
    } else {
      toast.error("Please Login to apply.");
    }

  }

  // main loan amount calculation

  const [schedule, setSchedule] = useState([]);
  const [emi, setEmi] = useState(0);
  const [totalLoanAmt, setTotalLoanAmt] = useState(0);
  const [totalYears, setTotalYears] = useState(0);

  const calculateEMI = (P, r, y) => {
    const interest = r / (12 * 100);
    const durationInMonth = y * 12;

    const monthlyEmiAmt = P * interest *
      (Math.pow(1 + interest, durationInMonth) / (Math.pow(1 + interest, durationInMonth) - 1))

    return monthlyEmiAmt;
  };

  const calculateSchedule = (P, r, y, monthlyEmiAmt) => {

    r = r / (12 * 100); // Monthly interest rate

    // ===============================================================
    //Set initial values for loop to calculate yearly figures
    let paymentSchedules = [];
    let outstandingBalance = P;

    //Loop each year of the mortgage term
    for (let year = 1; year <= y; year++) {

      let monthInterestPaid = 0;
      let interestPaidMonthlyToYearlyIncrementer = 0;
      let monthPrincipalPaid = 0;
      let monthlyPrincipalRepaidToYearlyIncrementer = 0;

      //loop each month of the year as interest is calculated monthly
      for (let j = 1; j <= 12; j++) {
        monthInterestPaid = outstandingBalance * r;
        interestPaidMonthlyToYearlyIncrementer = interestPaidMonthlyToYearlyIncrementer + monthInterestPaid;
        monthPrincipalPaid = monthlyEmiAmt - monthInterestPaid;
        monthlyPrincipalRepaidToYearlyIncrementer = monthlyPrincipalRepaidToYearlyIncrementer + monthPrincipalPaid;
        outstandingBalance = outstandingBalance - monthPrincipalPaid;
      }

      //There's always around £10 left at the end which forces the fraph to go into minus. This just rounds the last figure off at £0.00.
      if (year === y) {
        outstandingBalance = 0;
      }

      paymentSchedules.push({
        year: year + new Date().getFullYear() - 1,
        principalPaid: parseFloat(monthlyPrincipalRepaidToYearlyIncrementer.toFixed(2)),
        interestPaid: parseFloat(interestPaidMonthlyToYearlyIncrementer.toFixed(2)),
        outstandingBalance: parseFloat(outstandingBalance.toFixed(2)),
        paidPercentage: ((P - parseFloat(outstandingBalance.toFixed(2))) / P) * 100
      });
    }
    // ===============================================================

    return paymentSchedules
  };

  const handleCalculateSubmit = (sliderLoanValue, sliderDownValue, sliderPeriodValue, sliderInterestValue) => {
    // e.preventDefault();
    if (sliderLoanValue && sliderPeriodValue && sliderInterestValue) {
      const principleAmt = (sliderLoanValue - sliderDownValue)

      setTotalLoanAmt(principleAmt)
      setTotalYears(sliderPeriodValue)

      const emiValue = calculateEMI(principleAmt, sliderInterestValue, sliderPeriodValue);

      setEmi(emiValue);
      const paymentSchedules =
        calculateSchedule(principleAmt, sliderInterestValue, sliderPeriodValue, emiValue);

      setSchedule(paymentSchedules);
    }
    else {
      toast.error("Please Select Required Fields!")
    }
  };

  const sortAlphabetically = (direction) => {
    setActiveFilter(direction);
    const sortedVisibleBank = [...visibleBanks].sort((a, b) => {
      const comparison = a.bank_name.localeCompare(b.bank_name);
      return direction === 'A-Z' ? comparison : -comparison;
    });
    const sortedAllBank = [...bankData].sort((a, b) => {
      const comparison = a.bank_name.localeCompare(b.bank_name);
      return direction === 'A-Z' ? comparison : -comparison;
    });
    setVisibleBanks(sortedVisibleBank)
    setBankData(sortedAllBank)
  };

  return (
    <div>
      <div id="HomeLoan">
        {loading && <CustomLoader />}
        <Toaster
          position="top-right"
          reverseOrder={false}
          toastOptions={customToastOptions}
        />

        {/* calculator */}
        <section className="pb-55">
          <div className="pt-90">
            <div
              className="bg-contact-img"
              style={{
                backgroundImage: `linear-gradient(#111111b0, #111111b0),url(${contactDetails?.image_url})`,
              }}
            >
              <div className="  pb-5 container-box">
                <div className="py-5 px-lg-5 ">
                  <div className=" d-flex justify-content-center">
                    <div>
                      <h2 className="text-white mb-0 font-saira" style={{fontSize:"35px"}}>
                        <span className="text-yellow fw-normal font-saira">
                          {loanHeading[0]?.title?.split(' ')[0]}
                        </span>{" "}
                        {loanHeading[0]?.title?.split(' ')?.slice(1)}
                      </h2>
                      <div className="yellow-line"></div>
                    </div>
                  </div>
                  <div className="my-4 pb-1 text-center">
                    <p className="text-white bd-text-resp paragraph font-saira subtite">
                      {loanHeading[0]?.subtitle}
                    </p>
                  </div>
                  <div className="row justify-content-center ">
                    <div className="col-md-12 col-xxl-10">
                      <div className="row justify-content-center contact-container px-md-0 px-4">
                        <div className="col-md-7 position-relative">
                          <div className="rounded-6 mb-40 bg-white w-100 h-100 ps-md-2 ">
                            <CalculateLoan
                              handleCalculateSubmit={handleCalculateSubmit}
                              setTotalLoanAmt={setTotalLoanAmt}
                              setTotalYears={setTotalYears}
                              setSchedule={setSchedule}
                              setEmi={setEmi}
                            />
                          </div>
                        </div>
                        <div className="col-md-5 position-relative ">
                          <div className="bd-contact-details ">
                            <div className="bg-contact-detail-img w-100">
                              <div className="bd-detail-head-home pb-1">
                                <div>
                                  <h4 className="fw-500 mb-3">
                                    Monthly Installment
                                  </h4>
                                </div>
                                <div>
                                  <p className="display-5 font-saira fw-600 ps-2 mb-4">
                                    ₹ {Math.round(emi).toLocaleString()} /-
                                  </p>
                                </div>
                              </div>
                              <div className="card px-4 py-3 total-loan-card border-0 mb-4 w-83">
                                <div>
                                  <h6 className="mb-2 font-saira">Total Loan Amount</h6>
                                </div>
                                <div>
                                  <p className="fs-3 fw-600 mb-0 font-saira">
                                    ₹ {totalLoanAmt.toLocaleString()} /-
                                  </p>
                                </div>
                              </div>
                              <div className="Total-Interest-Payment ps-4">
                                <div className="pt-2">
                                  <h6 className="font-saira fw-600 fs-6">Total Interest Payment</h6>
                                  <p className="font-saira">
                                    (Principal Amount + Total Interest)
                                  </p>
                                  <div>
                                    <p className="fs-2 fw-600 mb-4 font-saira">
                                      ₹ {Math.round(((emi * 12) * totalYears)).toLocaleString()} /-
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* payments table */}
        <section className="auto-container-lg">
          <div>
            <div>
              <div>
                <h2 className="bd-service-main-heading font-saira mb-0">{loanHeading[1]?.title}</h2>
                <div className="yellow-line">&nbsp;</div>
                <p className="text-dark-blue py-3">{loanHeading[1]?.subtitle}</p>
              </div>
            </div>
          </div>
        </section>
        <section className="table-section auto-container-lg pb-55">
          <div>
            <div className="table-responsive">
              <table className="table table-striped payment-schedule-table w-100 border-0">
                <tbody>
                  <tr>
                    <td className="th font-saira text-color">
                      Year
                      <div className="font-saira fw-normal">(yyyy)</div>
                    </td>
                    <td className="th font-saira">
                      Principal{" "}
                      <div>
                        (<span>₹</span>)
                      </div>
                    </td>
                    <td className="th font-saira">
                      Interest
                      <div>
                        (<span>₹</span>)
                      </div>
                    </td>
                    <td className="th font-saira">
                      Balance
                      <div>
                        (<span>₹</span>)
                      </div>
                    </td>
                    <td className="th font-saira">
                      Paid
                      <div className="font-saira fw-normal">(%)</div>
                    </td>
                  </tr>
                  {schedule?.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td className={'font-saira text-color'}>{item.year} </td>
                        <td className={'font-saira'}>₹ {Math.round(item.principalPaid).toLocaleString()} </td>
                        <td className={'font-saira'}>₹ {Math.round(item.interestPaid).toLocaleString()} </td>
                        <td className={'font-saira'}>₹ {Math.round(item.outstandingBalance).toLocaleString()} </td>
                        <td className={'font-saira'}>{item.paidPercentage.toFixed(2)} % </td>
                      </tr>
                    );
                  })}
                  {
                    schedule.length === 0 &&
                    <tr>
                      <td colSpan={5} className="text-center">Yearly Payments will appear here..</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </section>
        {/* Bank Offers section */}
        <section className="pb-55 bank-section auto-container-lg">
          <div>
            <div className="d-md-flex justify-content-between">
              <div className="col-md-6">
                <h2 className="bd-service-main-heading font-saira mb-0">{loanHeading[2]?.title}</h2>
                <div className="yellow-line">&nbsp;</div>
                <p className="text-dark-blue py-3">{loanHeading[2]?.subtitle}</p>
              </div>
              <div className="mt-md-0 mt-4 position-relative">
                <button className="btn sort font-saira position-relative" onClick={handleClick}>Sort  <i htmlFor='purpose1' className='fa fa-chevron-down text-yellow pl-50 position-absolute' style={{ right: "5%", transform: "translateY(30%)" }}></i></button>
                <div>
                  {isShown && (
                    <div className="dropdown-content2 dropdown-box bd-content-box1">
                      <div className="bd-box-filter-section">
                        <div>
                          <p className={`bd-dropdown-list font-saira ${activeFilter == 'A-Z' && 'active'}`}
                            onClick={() => sortAlphabetically('A-Z')}
                          >
                            Sort By Bank Name A–Z</p>
                          <p
                            className={`mb-0 bd-dropdown-list font-saira ${activeFilter == 'Z-A' && 'active'}`}
                            onClick={() => sortAlphabetically('Z-A')}
                          >Sort By Bank Name Z-A</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* Banks */}
            <div className="table-responsive">
              <table className="table payment-schedule-table w-100 border-0" style={{ verticalAlign: 'middle' }}>
                <tbody>
                  <tr>
                    <td className="th text-color font-saira">Bank Name</td>
                    <td className="th font-saira">
                      Interest
                      <div></div>
                    </td>
                    <td className="th font-saira">Tenure</td>
                    <td className="th font-saira">Processing Fees</td>
                    <td className="th font-saira"></td>
                  </tr>
                  {(isExpanded ? visibleBanks.slice(0, 5) : bankData)?.map(
                    (item) => {
                      return (
                        <tr key={item.id}>
                          <td className="text-color col-4 py-0">
                            <div className="center-content">
                              <img
                                src={item.img}
                                alt=""
                                className="img-bank"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="center-content font-saira">
                              {item.interest}
                            </div>
                          </td>
                          <td>
                            <div className="center-content font-saira">{item.tenure}</div>
                          </td>
                          <td>
                            <div className="center-content font-saira">
                              ₹{item.processingFees}
                            </div>
                          </td>
                          <td>
                            <div className="w-100">
                              <a
                                target="_blank"
                                rel="noreferrer"
                                // href={item.apply_link ?? "#"}
                                className="btn btn-apply font-saira"
                                onClick={() => handleApply(item.apply_link)}
                              >
                                Apply
                              </a>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>

            </div>
            <div className="d-flex justify-content-center">
              <div className="mt-4">
                <button
                  className="btn sort font-saira"
                  onClick={handleSeeMoreClick}
                >
                  {isExpanded ? "See More Offers" : "See Less Offers"}
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default HomeLoanIndex;
