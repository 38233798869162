import React, { useEffect, useState } from "react";

import "../../assets/Css/Makeover.css";
import featureicon1 from "../../assets/Key Features Icons/key_icon1.png";
import featureicon2 from "../../assets/Key Features Icons/key_icon2.png";
import featureicon4 from "../../assets/Key Features Icons/key_icon4.png";

import rectDots from "../../assets/PagesUnderHome/small-dots.png";
import Slider from "react-slick";
import { axiosbaseurl } from "../../config";
import CustomLoader from "../CustomLoader";
import StarterForm from "../Footer pages/Propreneur Experience/StarterForm";

const MakeoverDetail = () => {
  const [activeAccordian, setActiveAccordian] = useState(0);
  const [makeoverData, setMakeoverData] = useState({});
  const [isMorePopup, setIsMorePopup] = useState(false);
  const [isMorePopupData, setIsMorePopupData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showAllServices, setShowAllServices] = useState(false)

  useEffect(() => {

    const controller = new AbortController();
    async function getMakeoverData() {
      try {
        setLoading(true);
        const { data } = await axiosbaseurl.get(`/makeoverData`);

        if (data.success == true) {
          setMakeoverData(data.data);
          // console.log(data.data);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }

    getMakeoverData();

    return () => {
      controller.abort();
    };
  }, []);

  const sliderSettings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    swipeToSlide: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 8000,
    mobileFirst:true,
    dots: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  // features Icons
  const featuresIcons = [featureicon1, featureicon2, featureicon2, featureicon4]

  const readMorePopup = async (index, featureData) => {
    setIsMorePopupData({ ...featureData, img: featuresIcons[index] });
    setIsMorePopup(true);
  };


  return (
    <div>

      {loading && <CustomLoader />}

      <section className="pt-90 ">
        <div className="  auto-container-lg">
          <div className="row makeover-header">
            <div className="col-xl-7 col-lg-8 col-md-9 py-md-4">
              <h2 className="py-5 display-6">
                <div>{makeoverData?.first?.title}</div>
                <span className="fw-bold text-dark-blue">
                  {makeoverData?.first?.subtitle}
                </span>
              </h2>
              <div className=" pt-5 row">
                <div className="col-1 px-0">
                  <h3 className="yellow-line-heading verticle-heading text-dark-light ms-md-4 ms-0">
                    Key Feature
                  </h3>
                </div>
                <div className="col-md-9 col-11">
                  <div className="row ms-3 me-md-5 pt-lg-3 pt-md-4 position-relative bg-white">
                    <div className="col-sm-6 mb-md-5 mb-4 ">
                      <div className="d-flex align-items-center text-start">
                        <div className="key-icon">
                          <img src={featuresIcons[0]} alt="" />
                        </div>
                        <div className="ms-3">
                          <h5
                            style={{ fontSize: "18px" }}
                            className="text-dark-blue"
                          >
                            {makeoverData?.first?.feautures[0].title}
                          </h5>
                          <a
                            // href="#"
                            onClick={() => {
                              readMorePopup(0, makeoverData?.first?.feautures[0]);
                            }}
                            className="text-decoration-none makeover-read-more"
                          >
                            Read more
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6 mb-md-5 mb-4">
                      <div className="d-flex align-items-center text-start">
                        <div className="key-icon">
                          <img src={featuresIcons[1]} alt="" />
                        </div>
                        <div className="ms-3">
                          <h5
                            style={{ fontSize: "18px" }}
                            className="text-dark-blue"
                          >
                            {makeoverData?.first?.feautures[1].title}
                          </h5>
                          <a
                            // href="#"
                            onClick={() => readMorePopup(1, makeoverData?.first?.feautures[1])}
                            className="text-decoration-none makeover-read-more"
                          >
                            Read more
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6 mb-md-5 mb-4">
                      <div className="d-flex align-items-center text-start">
                        <div className="key-icon">
                          <img src={featuresIcons[2]} alt="" />
                        </div>
                        <div className="ms-3">
                          <h5
                            style={{ fontSize: "18px" }}
                            className="text-dark-blue"
                          >
                            {makeoverData?.first?.feautures[2].title}
                          </h5>
                          <a
                            // href="#"
                            onClick={() => readMorePopup(2, makeoverData?.first?.feautures[2])}
                            className="text-decoration-none makeover-read-more"
                          >
                            Read more
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6 mb-md-5 mb-4">
                      <div className="d-flex align-items-center text-start">
                        <div className="key-icon">
                          <img src={featuresIcons[3]} alt="" />
                        </div>
                        <div className="ms-3">
                          <h5
                            style={{ fontSize: "18px" }}
                            className="text-dark-blue"
                          >
                            {makeoverData?.first?.feautures[3].title}
                          </h5>
                          <a
                            // href="#"
                            onClick={() => readMorePopup(3, makeoverData?.first?.feautures[3])}
                            className="text-decoration-none makeover-read-more"
                          >
                            Read more
                          </a>
                        </div>
                      </div>
                    </div>
                    {isMorePopup && (
                      <div className="readmore-popup px-4 py-3">
                        <div className="d-flex "></div>
                        <div className="d-flex mb-3 justify-content-between">
                          <div className="d-flex align-item-center">
                            <div className="key-icon">
                              <img src={isMorePopupData?.img} alt="" />
                            </div>

                            <div>
                              <h4 className="m-0 ps-3">
                                {isMorePopupData?.title}
                              </h4>
                            </div>
                          </div>
                          <div
                            className="curser-pointer"
                            onClick={() => {
                              setIsMorePopup(false);
                              setIsMorePopupData({});
                            }}
                          >
                            <i className="bi bi-x text-white fs-4"></i>
                          </div>
                        </div>
                        <div>
                          <p className="justified-text" dangerouslySetInnerHTML={{ __html: isMorePopupData?.description }}>

                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* connect form */}
      <section className="py-5">
        <div className="pt-md-5">

          <div className="prop-top-heading px-4 pb-5">
            <h3 className="mb-4">Sell your property</h3>
            <p className="mb-0">Let us help you sell your property immediately, hassle-free and a premium price</p>
          </div>
          <StarterForm pageName={"propreneur makeover"} />
        </div>
      </section>

      <section className="py-5">
        <div className=" pt-md-4 pb-md-5 auto-container-lg">
          <div className="row makeover-second align-items-center mx-0 pb-md-5">
            <div className="col-md-6 d-flex">
              <div className="bg-yellow  yellow-div"> </div>
              <div className=" makeover-center-logo">
              </div>
            </div>

            <div className="col-md-6">
              <div className="right-white-section">
                <h3 className="yellow-line-heading mb-4">
                  {makeoverData?.second?.title}
                </h3>

                <div>
                  <p className="paragraph mb-4 justified-text"
                    dangerouslySetInnerHTML={{ __html: makeoverData?.second?.subtitle }}
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="py-md-5">
          <div className="row mx-0">
            <div className="col-md-3 col-2 text-center d-flex justify-content-end align-items-center pe-md-5">
              <h3 className="yellow-line-heading verticle-heading text-dark-light me-md-4 me-0">
                {makeoverData?.third?.title}
              </h3>
            </div>

            <div className="col-md-9 col-10 pe-0">
              <Slider {...sliderSettings} className="pb-4">
                {makeoverData?.third?.benefits?.map((benefit) => {
                  return <BenefitCard key={benefit.id} benefit={benefit} />;
                })}
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <section className="py-md-5">
        <div className="position-relative auto-container-lg">
          <div className="row justify-content-end mb-md-5 mx-0">
            <div className="col-md-7 ps-0">
              <h3 className="yellow-line-heading text-dark mb-md-4 mb-5 ms-md-0 ms-4">
                {makeoverData?.fourth?.title}
              </h3>
            </div>
          </div>
          {makeoverData?.fourth?.services?.slice(0, showAllServices ? makeoverData?.fourth?.length : 2)?.map((zigzag, index) => {
            return index % 2 === 0 ? (
              <LeftZigZagCard key={zigzag.id} zigzag={zigzag} />
            ) : (
              <RightZigZagCard key={zigzag.id} zigzag={zigzag} />
            );
          })}

          <div >
            <button onClick={() => setShowAllServices((prev) => !prev)} className={`read-less-more-btn text-dark-blue`}  >
              <span > {showAllServices ? "See Less" : "See All"}  </span> <br />
              <i className={`bi bi-chevron-double-${showAllServices ? "up" : "down"} text-yellow all-services-arrow`}></i>
            </button>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="py-md-4">
          <div className="row mx-0">
            <div className="col-md-3 text-center d-md-flex justify-content-end align-items-center pe-md-5">
              <h3 className="yellow-line-heading d-md-block d-none verticle-heading text-dark-light ms-md-4 ms-0">
                {makeoverData?.fifth?.title}
              </h3>

              <h3 className="yellow-line-heading d-md-none d-block mb-5 text-dark-light ms-md-4 ms-0">
                {makeoverData?.fifth?.title}
              </h3>
            </div>

            <div className="col-md-9 ">
              <div className="row justify-content-md-start justify-content-center">
                {makeoverData?.fifth?.process?.sort((a, b) => (a.seq - b.seq))?.map((process, index) => {
                  return (
                    <ProcessCard
                      key={process.id}
                      processData={process}
                      count={index + 1}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="pb-md-5">
          <div className="row makeover-second frequently-question align-items-md-center justify-content-center mx-0 pb-md-5">
            <div className="col-md-7 d-flex">
              <div className="bg-yellow p-md-5 p-4 yellow-div"> </div>
              <div
                className="makeover-center-logo"
                style={{
                  backgroundImage: `url('${makeoverData?.faq?.length && makeoverData?.faq[0]?.features}')`,
                  backgroundSize: "cover",
                }}
              ></div>
            </div>

            <div className="col-md-5 col-11 pe-md-0">
              <div className="right-white-section px-0">
                <div className="px-md-5 px-md-4 pb-2 mt-md-0 mt-3">
                  <h3 className="yellow-line-heading mb-4 text-dark-light">
                    {makeoverData?.faq?.length && makeoverData?.faq[0]?.title}
                  </h3>
                </div>
                {makeoverData?.faqs?.map((accordian, index) => {
                  return (
                    <CusromAccordian
                      key={index}
                      index={index}
                      acc_data={accordian}
                      activeAccordian={activeAccordian}
                      setActiveAccordian={setActiveAccordian}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const BenefitCard = ({ benefit }) => {
  return (
    <div
      className="makeover-benefit-card d-flex align-items-end ms-4"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${benefit.icon})`,
      }}
    >
      <div className="benefit-card-content">
        <h3 className="yellow-line-heading mb-4 text-white">{benefit.title}</h3>
        <div>
          <p className="paragraph text-white mb-4 justified-text" dangerouslySetInnerHTML={{ __html: benefit?.description }} />
        </div>
      </div>
    </div>
  );
};

const LeftZigZagCard = ({ zigzag }) => {
  return (
    <div className="ps-md-5 ps-4  mb-5">
      {/* left image */}
      <div className="row mx-0 py-md-4">
        <div className="col-md-5 mb-md-0 mb-5">
          <div className="two-round-image ">
            <div></div>
            <img src={zigzag.icon} alt="" className="img-fluid" />
          </div>
        </div>

        <div className="col-md-7 ps-md-5 pe-md-0">
          <div className="d-flex justify-content-between ps-md-4">
            <div className="zigzag-content">
              <h4 className="fs-3 text-dark-blue d-flex align-items-center">
                <i className="bi bi-plugin text-yellow display-6"></i>
                <span className="ps-3">{zigzag.title}</span>
              </h4>
              <p className="mb-4 justified-text" dangerouslySetInnerHTML={{ __html: zigzag?.description }} />
            </div>
            <div className="d-md-block d-none">
              <img src={rectDots} alt="" className="img-fluid" style={{ height: '250px', width: '65px', objectFit: 'cover' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RightZigZagCard = ({ zigzag }) => {
  return (
    <div className="pe-md-5 pe-4  mb-5">
      {/* right image */}
      <div className="row flex-md-row flex-column-reverse mx-0 py-md-4">
        <div className="col-md-7 pe-md-5 ps-md-0">
          <div className="d-flex justify-content-between pe-md-4">
            <div className="d-md-block d-none">
              <img src={rectDots} alt="" className="img-fluid" style={{ height: '250px', width: '65px', objectFit: 'cover' }} />
            </div>
            <div className="zigzag-content">
              <h4 className="fs-3 text-dark-blue d-flex align-items-center justify-content-end">
                <i className="bi bi-plugin text-yellow display-6"></i>
                <span className="ps-3">{zigzag.title}</span>
              </h4>
              <p className="mb-4 justified-text" dangerouslySetInnerHTML={{ __html: zigzag?.description }} />
            </div>
          </div>
        </div>

        <div className="col-md-5 mb-md-0 mb-5">
          <div className="two-round-image two-round-image-right">
            <div></div>
            <img src={zigzag.icon} alt="" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};

const ProcessCard = ({ processData, count }) => {
  return (
    <div className="col-md-4 col-11 mb-4">
      <div
        className="makeover-benefit-card process-card d-flex align-items-start"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('${processData.icon}')`,
        }}
      >
        <div className="display-3 text-white fw-bold  font-saira counting">
          {count}
        </div>
        <div className="process-card-content">
          <h3 className="yellow-line-heading align-items-start mb-4 text-white">
            {processData.title.split(" ")[0]} <br />{" "}
            {processData.title.split(" ").slice(1).join(" ")}
          </h3>
          <div>
            <div className="process-icon">
              <i className={`bi bi-house-check text-yellow fs-3`}></i>
            </div>
            <p className="paragraph text-white mb-4 justified-text" dangerouslySetInnerHTML={{ __html: processData?.description }} />
            {/* {processData.description}
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const CusromAccordian = ({
  acc_data,
  index,
  activeAccordian,
  setActiveAccordian,
}) => {
  // console.log(index)
  const isActve = activeAccordian === index;
  return (
    <div className={`custom-accordian ${isActve && "active-accordian"}`}>
      <div className="d-flex justify-content-between">
        <div
          className="text-dark-blue fw-500 paragraph pe-3"
          style={{ flex: 1 }}
        >
          {acc_data.title}
        </div>
        <div
          className="custom-accordian-btn fs-5 fw-500"
          onClick={() => setActiveAccordian(isActve ? "" : index)}
        >
          {" "}
          <span>{isActve ? "-" : "+"}</span>{" "}
        </div>

      </div>
      <div className={`${isActve ? "d-block" : "d-none"}`}>
        <p className="mt-2 pe-md-5 mb-0 justified-text" style={{fontSize:"13.5px"}}
          dangerouslySetInnerHTML={{ __html: acc_data.description }}
        />
      </div>
    </div>
  );
};

export default MakeoverDetail;
