import React, { createContext, useState, useContext } from 'react';

// Create Context
const FilterContext = createContext();

// Filter Provider component
export const FilterProvider = ({ children }) => {
  const [bedCounter, setBedCounter] = useState(0);
  const [bathCounter, setBathCounter] = useState(0);
  const [sqftCounter, setSqftCounter] = useState(0);
  const [yearCounter, setYearCounter] = useState(2000);
  const [floorCounter, setFloorCounter] = useState(0);
  const [balconyCounter, setBalconyCounter] = useState(0);
  const [parkingCounter, setParkingCounter] = useState(0);

  const [amenities, setAmenities] = useState([]);
  const [property_types, setPropertyTypes] = useState([]);

  const [all_amenities, setAllAmenities] = useState([]);
  const [all_property_type, setAllProperty_types] = useState([]);

  const [show_more, setShowMore] = useState(false);

  const [sale_type, setSaleType] = useState("");
  const [ownership, setOwnership] = useState('');
  const [facing, setFacing] = useState('');
  const [statustype, setstatustype] = useState('');

  return (
    <FilterContext.Provider
      value={{
        bedCounter,
        setBedCounter,
        bathCounter,
        setBathCounter,
        sqftCounter,
        setSqftCounter,
        yearCounter,
        setYearCounter,
        floorCounter,
        setFloorCounter,
        balconyCounter,
        setBalconyCounter,
        parkingCounter,
        setParkingCounter,
        amenities,
        setAmenities,
        property_types,
        setPropertyTypes,
        all_amenities,
        setAllAmenities,
        all_property_type,
        setAllProperty_types,
        show_more,
        setShowMore,
        sale_type,
        setSaleType,
        ownership,
        setOwnership,
        facing,
        setFacing,
        statustype,
        setstatustype,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

// Custom hook to use the FilterContext
export const useFilters = () => {
  return useContext(FilterContext);
};
