import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBath, faBattery, faBed, faCalendarAlt, faCar, faDumbbell, faElevator, faHouseChimneyWindow, faLayerGroup, faMap, faPersonBiking, faPersonSwimming, faRulerVertical, faTableTennisPaddleBall, faWifi } from '@fortawesome/free-solid-svg-icons'
import appartments from "../../assets/BuyDetailPage/building.png"
import { axiosbaseurl } from '../../config'
import { useFilters } from './FilterContext'

const PropertyFilter = ({ allPropertyData, setPropertyData, setIsFilterOpen }) => {

    const{
        bedCounter,
        setBedCounter,
        bathCounter,
        setBathCounter,
        sqftCounter,
        setSqftCounter,
        yearCounter,
        setYearCounter,
        floorCounter,
        setFloorCounter,
        balconyCounter,
        setBalconyCounter,
        parkingCounter,
        setParkingCounter,
        amenities,
        setAmenities,
        property_types,
        setPropertyTypes,
        all_amenities,
        setAllAmenities,
        all_property_type,
        setAllProperty_types,
        show_more,
        setShowMore,
        sale_type,
        setSaleType,
        ownership,
        setOwnership,
        facing,
        setFacing,
        statustype,
        setstatustype,
    }=useFilters();

    useEffect(() => {
        async function getPropertyAndAmenities() {
            const response = await axiosbaseurl.get('/category_ammenity')

            // console.log(response.data)
            setAllProperty_types(response.data)
            setPropertyTypes(response.data[0])
            setAllAmenities(response.data[0]?.category?.ammenities)
        }
        getPropertyAndAmenities()
    }, [])

    const handleFilterProperties = () => {

        if (bedCounter || bathCounter || sqftCounter || yearCounter || floorCounter ||
            balconyCounter || parkingCounter || ownership || facing) {

            const checkAllAmenities = (arr1, arr2) => {
                return arr1.some(value => arr2.includes(Number(value)));
            };

            const filteredProperties = allPropertyData?.filter((property) => {

                const bed_search = bedCounter == 0 || property.bhk?.split(' ')[0] == bedCounter
                const bath_search = bathCounter == 0 || property.bathroom == bathCounter
                const sqft_search = sqftCounter == 0 || property.built_area == sqftCounter
                const year_search = yearCounter == 2000 || property.year == yearCounter
                const floor_search = floorCounter == 0 || property.floor == floorCounter
                const balcony_search = balconyCounter == 0 || property.balcony == balconyCounter
                const parking_search = parkingCounter == 0 || property.parking == parkingCounter

                const ownership_search = ownership == '' ||
                    property.property_ownership?.toLowerCase() == ownership?.toLowerCase()

                const facing_search = facing == '' || property.facing?.toLowerCase() === facing?.toLowerCase()

                const amenities_search = amenities == '' ||
                    checkAllAmenities(property.amenities_ids?.replaceAll(' ', '').split(','), amenities)

                const status_search = statustype == '' || property?.property_availablity?.toLowerCase() === statustype?.toLowerCase()

                const saletype_search = sale_type == '' || property.property_availablity?.toLowerCase() === sale_type.toLowerCase() || property.sales_type?.toLowerCase() === sale_type.toLowerCase()

                return bed_search && bath_search && sqft_search && year_search && floor_search &&
                    balcony_search && parking_search && ownership_search && facing_search && amenities_search && saletype_search && status_search
            });

            // console.log('filteredProperties : ', filteredProperties)
            setPropertyData(filteredProperties)
            setIsFilterOpen(false)
            window.scrollTo({
                top: 0,
                behavior: 'instant'
            })

        } else {
            // toast.error("Atleast 1 field required to filter !")
        }
    }

    const handleResetProperties = () => {
        setPropertyData(allPropertyData)
        setIsFilterOpen(false)
        setBedCounter(0);
        setBathCounter(0);
        setSqftCounter(0);
        setYearCounter(2000);
        setFloorCounter(0);
        setBalconyCounter(0);
        setParkingCounter(0);
        setAmenities([]);
        setPropertyTypes([])
        setAllAmenities([]);
        setAllProperty_types([])
        setShowMore(false);
        setSaleType("");
        setOwnership('');
        setFacing('');
        setstatustype('');
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }

    const addRemoveAmenity = (amenity_name) => {
        if (amenities.includes(amenity_name)) {
            const newData = amenities.filter(item => item !== amenity_name)
            setAmenities(newData)
        } else {
            setAmenities(prev => [...prev, amenity_name])
        }
    }

    const addRemovePTypes = (property_type) => {
        if (property_types?.category?.category_title !== property_type?.category?.category_title) {
            setPropertyTypes(property_type)
            setAllAmenities(property_type?.category?.ammenities)
        } else {
            setPropertyTypes([])
            setAllAmenities([])
        }
    }

    return (
        <div>

            <div className=" px-4 mx-2 ">
                <div className="my-4">
                    <div className='row'>
                        <div className="col-md-6">
                            <Counter
                                counterValue={bedCounter}
                                setCounterValue={setBedCounter}
                                icon={faBed}
                                labelName={'Bedrooms'}
                            />
                        </div>

                        <div className="col-md-6">
                            <Counter
                                counterValue={bathCounter}
                                setCounterValue={setBathCounter}
                                icon={faBath}
                                labelName={'Bathrooms'}
                            />
                        </div>
                    </div>

                </div>
                {/* Property Type */}
                <div className='mb-4'>
                    <div>
                        <div className="mb-30">
                            <div className="  ">
                                <h2 className="bd-about-header font-saira mb-0">Property Type</h2>
                                <div className="yellow-line"></div>
                            </div>
                        </div>

                        <div>
                            <div className='row '>

                                {
                                    all_property_type?.map(ptype => {
                                        return (
                                            <PropertyTypeCard
                                                key={ptype?.category?.id}
                                                iconImage={appartments}
                                                propertyType={ptype}
                                                property_types={property_types}
                                                addRemovePTypes={addRemovePTypes}
                                            />
                                        )
                                    })
                                }

                            </div>

                        </div>
                    </div>
                </div>

                {/* Property Information */}
                <div className='mb-4'>
                    <div className="mb-30">
                        <div className="  ">
                            <h2 className="bd-about-header font-saira mb-0">Property Information</h2>
                            <div className="yellow-line"></div>
                        </div>
                    </div>

                    <div>

                        <div className='row'>
                            <div className="col-md-6">
                                <Counter
                                    counterValue={sqftCounter}
                                    setCounterValue={setSqftCounter}
                                    icon={faRulerVertical}
                                    labelName={'Sq. Ft.'}
                                />
                            </div>

                            <div className="col-md-6">
                                <Counter
                                    counterValue={yearCounter}
                                    setCounterValue={setYearCounter}
                                    icon={faCalendarAlt}
                                    labelName={'Year Built'}
                                />
                            </div>

                            <div className="col-md-6">
                                <Counter
                                    counterValue={floorCounter}
                                    setCounterValue={setFloorCounter}
                                    icon={faLayerGroup}
                                    labelName={'Floor'}
                                />
                            </div>

                            <div className="col-md-6">
                                <Counter
                                    counterValue={balconyCounter}
                                    setCounterValue={setBalconyCounter}
                                    icon={faHouseChimneyWindow}
                                    labelName={'Balcony'}
                                />
                            </div>

                            <div className="col-md-6">
                                <Counter
                                    counterValue={parkingCounter}
                                    setCounterValue={setParkingCounter}
                                    icon={faCar}
                                    labelName={'car Parking'}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Property Facts */}
                <div >
                    <div >
                        <div className="mb-30">
                            <div className="  ">
                                <h2 className="bd-about-header font-saira mb-0">Property Facts</h2>
                                <div className="yellow-line"></div>
                            </div>
                        </div>
                        <div className="mt-30">
                            <div>
                                <div >
                                    <div >
                                        <div>
                                            <p className='fw-bold font-saira'>Status</p>
                                        </div>
                                        <div className=" row">
                                            <div className="col-md-6">
                                                <CustomCheckBox labelName={'Coming Soon'} type={'checkbox'} 
                                                    setOption={setstatustype} value={statustype} name={'statustype'}/>
                                                <CustomCheckBox labelName={'Ready to Move'} type={'checkbox'} 
                                                    setOption={setstatustype} value={statustype} name={"statustype"}/>
                                            </div>
                                            <div className='col-md-6'>
                                                <CustomCheckBox labelName={'Under Construction'} type={'checkbox'} 
                                                    setOption={setstatustype} value={statustype} name={"statustype"}/>
                                                <CustomCheckBox labelName={'SOLD'} type={'checkbox'} 
                                                    setOption={setstatustype} value={statustype} name={"statustype"}/>
                                            </div>

                                            {/* <div className="col-md-6">
                                                <div className='d-flex flex-column h-100' style={{gap:"15px"}}>
                                                    <div className="mb-md-0 mb-3">
                                                        <select className="bd-days-dropdown form-select font-saira">
                                                            <option >Max</option>
                                                            <option >New Listing</option>
                                                        </select>
                                                    </div>

                                                    <div>
                                                        <CustomCheckBox labelName={'Visiting Hour'} type={'checkbox'} />
                                                    </div>
                                                    <div className="d-flex justify-content-end pe-4 my-md-0 my-5">
                                                        <div>
                                                            <input type="checkbox" id="toggle" className="toggleCheckbox" />
                                                            <label htmlFor="toggle" className="toggleContainer">
                                                                <div>This Weekend</div>
                                                                <div>Any Time</div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>

                                        <div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Listing Type */}
                {/* <div className="mb-4">
                    <div className="mb-30">
                        <div className="  ">
                            <h2 className="bd-about-header font-saira mb-0">Listing Type</h2>
                            <div className="yellow-line"></div>
                        </div>
                    </div>
                    <div className="mt-30">
                        <div className="row">
                            <div className="col-md-6">
                                <CustomCheckBox labelName={'Propusers Listings'} type={'radio'} name={'listing_type'} />
                                <CustomCheckBox labelName={'Coming Soon'} type={'radio'} name={'listing_type'} />
                                <CustomCheckBox labelName={'Prop Makeover'} type={'radio'} name={'listing_type'} />
                                <CustomCheckBox labelName={'Discounted Property'} type={'radio'} name={'listing_type'} />
                            </div>

                            <div className="col-md-6">
                                <CustomCheckBox labelName={'New Construction-By Age'} type={'radio'} name={'listing_type'} />
                                <CustomCheckBox labelName={'For Sale By Owner'} type={'radio'} name={'listing_type'} />
                                <CustomCheckBox labelName={'For Sale By Agent'} type={'radio'} name={'listing_type'} />
                                <CustomCheckBox labelName={'For Sale By Builder'} type={'radio'} name={'listing_type'} />
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* Amenities */}
                <div className="mb-40">
                    <div className="mb-30">
                        <div className="  ">
                            <h2 className="bd-about-header font-saira mb-0">Amenities</h2>
                            <div className="yellow-line"></div>
                        </div>
                    </div>
                </div>
                <div className="mb-40">
                    <div className="mt-30">
                        <div className='row'>
                            {
                                all_amenities?.slice(0, show_more ? all_amenities?.length : 8).map(amenity => {
                                    return (
                                        <AmenitiesCard
                                            key={amenity.id}
                                            icon={amenity.image}
                                            amenity={amenity}
                                            amenities={amenities}
                                            addRemoveAmenity={addRemoveAmenity}
                                        />
                                    )
                                })
                            }
                            {
                                all_amenities?.length === 0 && <h5 className='paragraph'>No Amenity Available!</h5>
                            }
                        </div>
                    </div>
                    {
                        all_amenities?.length > 8 &&
                        <div className='mt-3' onClick={() => setShowMore(prev => !prev)}>
                            <button className="bd-btn-filter-more"> {show_more ? '... Less' : 'More ...'} </button>
                        </div>
                    }
                </div>

                <div >
                    <div className="mb-30">
                        <div>
                            <h2 className="bd-about-header font-saira mb-0">Sale Type</h2>
                            <div className="yellow-line"></div>
                        </div>
                    </div>

                    <div className="mt-30 mb-40">
                        <div className="row">
                            <div className="col-md-6">
                                <CustomCheckBox labelName={'Ready to move'} type={'radio'}
                                    setOption={setSaleType} value={sale_type} name={'sale_type'} />
                                <CustomCheckBox labelName={'New'} type={'radio'}
                                    setOption={setSaleType} value={sale_type} name={'sale_type'} />
                            </div>
                            <div className="col-md-6">
                                <CustomCheckBox labelName={'Under Construction'} type={'radio'}
                                    setOption={setSaleType} value={sale_type} name={'sale_type'} />
                                <CustomCheckBox labelName={'Resale'} type={'radio'}
                                    setOption={setSaleType} value={sale_type} name={'sale_type'} />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className=" row">
                        <div className="col-md-6 mb-md-0 mb-4">
                            <CustomDropdown label={'Ownership'}
                                setOption={setOwnership}
                                value={ownership}
                                items={['Freehold', 'Leasehold', 'Power Of Attorney']}
                            />
                        </div>
                        <div className="col-md-6">
                            <CustomDropdown label={'Facing'}
                                setOption={setFacing}
                                value={facing}
                                items={['North Facing', 'South Facing', 'East Facing', 'West Facing', 'North - East', 'North - West', 'South - East', 'South - West']}
                            />
                        </div>
                    </div>
                </div>

                <div className="bd-main-last-btns">
                    <div className="main-row bd-main-btns-filter">
                        <div>
                            <button className="bd-filter-reset" onClick={handleResetProperties}>Reset</button>
                            <button className="bd-filter-close ms-3"
                                onClick={() => {
                                    setIsFilterOpen(false);
                                    window.scrollTo({ top: 0, behavior: 'instant' })
                                }}>Closed</button>
                        </div>
                        <div className=" text-md-end mt-md-0 mt-3" style={{ flex: 1 }}>
                            <button onClick={handleFilterProperties} className="bd-filter-homes">See Homes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

const Counter = ({
    counterValue,
    setCounterValue,
    icon,
    labelName
}) => {

    const handleChangeCounter = (e) => {
        if (e.target.name === 'increment') {
            setCounterValue(Number(counterValue) + 1)
        } else {
            if (counterValue > 0)
                setCounterValue(counterValue - 1)
        }
    }

    return (
        <div className='main-row align-items-center bd-filter-first-section1 mb-3'>
            <div className="bd-home-icons pr-10">
                <FontAwesomeIcon icon={icon} />
            </div>
            <div className="pr-10 bd-filter-type">
                <label className="mb-1 font-saira text-capitalize">{labelName}</label>
            </div>

            <div className='d-flex justify-content-end' style={{ flex: 1 }}>
                <div className="bd-filter-type">
                    <button onClick={handleChangeCounter} name='decrement' className="bd-decremental-buy-btn counter_btn">&#x2212;</button>
                </div>
                <div className="bd-filter-type">
                    <input type="text" className='bd-input-incremental' value={counterValue} onChange={(e) => setCounterValue(e.target.value)} />
                    {/* <div className="bd-input-incremental">{counterValue}</div> */}
                </div>
                <div className="bd-filter-type">
                    <button onClick={handleChangeCounter} name='increment' className="bd-incremental-buy-btn counter_btn">&#43;</button>
                </div>
            </div>
        </div>
    )
}

const CustomCheckBox = ({ labelName, type, name, setOption ,value }) => {

    return (
        <div className="bd-facts-section1  mb-15">
            <label htmlFor={labelName} className="pl-10 bd-sec-text font-saira">{labelName}</label>
            <label className="container12">
                <input type={type} name={name} id={labelName} 
                    checked={value === labelName}
                    onChange={(e) => {
                        setOption(e.target.checked ? labelName : "")
                    }} />
                <span className="checkmark"></span>
            </label>
        </div>
    )
}

const PropertyTypeCard = ({ iconImage, propertyType, property_types, addRemovePTypes }) => {

    const isAdded = property_types.category?.category_title === propertyType?.category?.category_title

    return (
        <div className="col-md-4 col-lg-3 mb-3 px-2">
            <div className={`bd-type-property-section ${isAdded && 'active_amenity'}`}
                style={{ cursor: 'pointer' }}
                onClick={() => addRemovePTypes(propertyType)}
            >
                <div className="textCenter">
                    <img className="bd-filter-icons" alt="" src={iconImage} />
                    <p className="mb-0 bd-type-property-filter font-saira" >{propertyType?.category?.category_title}</p>
                </div>
            </div>
        </div>
    )
}

const AmenitiesCard = ({ icon, amenity, amenities, addRemoveAmenity }) => {

    const isAdded = amenities.includes(amenity?.id)

    return (
        <div className="col-md-4 col-lg-3 mb-3 px-2">
            <div className={`bd-type-property-section amenity-card ${isAdded && 'active_amenity'}`}
                onClick={() => addRemoveAmenity(amenity?.id)}
                style={{ borderRadius: '20px', cursor: 'pointer' }}>
                <div className="textCenter">
                    <img className="bd-filter-icons" alt="" src={icon} />
                    {/* <FontAwesomeIcon icon={icon} className="bd-amenities-icons" /> */}
                    <p className="mb-0 bd-type-property-filter font-saira" >{amenity?.title?.slice(0, 15)}</p>
                </div>
            </div>
        </div>
    )
}

const CustomDropdown = ({ label, items, setOption , value }) => {

    const [isShown, setIsShown] = useState(true);

    const handleClick = () => {
        setIsShown(current => !current);
    };

    return (
        <div className="dropdown">
            <a className="nav-link bd-days-dropdown font-saira 
                        d-flex justify-content-between align-items-center"
                onClick={handleClick} style={{ color: "#122844", fontWeight: "600", cursor: "pointer" }} >
                {label}
                <i className="fa fa-chevron-down fs-6"></i>
            </a>
            {isShown && (
                <div className="dropdown-content2 New dropdown-box bd-content-box1 New mt-0">
                    <div className=" px-0 py-0">
                        <div>
                            <div className="bd-box-filter-section pt-0 pb-1">
                                {
                                    items?.map((item, index) => {
                                        return (
                                            <CustomCheckBox key={index}
                                                labelName={item} type={'radio'}
                                                name={label} setOption={setOption} value={value} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PropertyFilter