import React, { useContext, useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../../assets/Css/News.css";
import NewsCard from "./NewsCard";
import config, { axiosbaseurl } from "../../config";
import CustomLoader from "../CustomLoader";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";
import toast, { Toaster } from "react-hot-toast";
import { customToastOptions } from "../../Styles/popup_style";
import CommentSection from "./Components/CommentSection";
import { AppGlobalContext } from "../../GlobalContext";
import Select from "react-select";

const News = () => {

    const [currentSlide, setCurrentSlide] = useState(0);

    const [news, setNews] = useState([]);
    const [allNews, setAllNews] = useState([]);
    const [currNews, setCurrNews] = useState({});
    const [loading, setLoading] = useState(false);
    const [newsHeading, setNewsHeading] = useState({});

    const [searchLocation, setSearchLocation] = useState('');
    const [searchTag, setSearchTag] = useState('');
    // const [searchType, setSearchType] = useState('');

    const [allTags, setAllTags] = useState([])
    const [allTypes, setAllTypes] = useState([])

    const { cities_options } = useContext(AppGlobalContext)


    const navigate = useNavigate()

    const { news_slug } = useParams()

    const credentials = secureLocalStorage.getItem("credentials")

    function getNewsSaveStatus(isSave) {
        return ((isSave === 0) ? false : true)
    }

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true)
        const uid = credentials ? credentials?.user_id : 0;

        axiosbaseurl.get(`/news/${uid}`)
            .then(res => {
                setNewsHeading(res.data.titles[0])
                let updatedData;
                if (credentials) {
                    updatedData = res.data.data.map(post => {
                        return { ...post, isSaved: getNewsSaveStatus(post.savednews) }
                    })
                } else {
                    updatedData = res.data.data.map(post => {
                        return { ...post, isSaved: false }
                    })
                }

                setNews(updatedData)
                setAllNews(updatedData)

                const tempTypes = [...new Set(updatedData.filter(item => item.type).map(item => item.type))]
                setAllTypes(tempTypes)
                const temptags = [...new Set(updatedData.map(item => item.category))].filter(item => item)
                // console.log("first", temptags)
                setAllTags(temptags)

                if (!news_slug)
                    navigate(`${config.appUrl}news/${updatedData[0].slug}`)
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false)
            })

        return () => {
            setNews([])
            controller.abort();
        };
    }, [])

    // useEffect(() => {
    //     // if (!searchLocation && !searchTag && !searchType) {
    //     if (news_slug) {
    //         setLoading(true)
    //         const activeBlog = news.find((item) => item.slug?.replaceAll('%', '') ===
    //             news_slug)
                // console.log('activeBlog', activeBlog?.url)
    //         setCurrNews(activeBlog)
    //         setTimeout(() => {
    //             setLoading(false)
    //             window.scrollTo(0, 850)
    //         }, 1000)
    //     }
    //     // }
    // }, [news_slug, news])


    // getting news by slug
    useEffect(() => {
        async function fetchNewsData() {
            try {
                setLoading(true)
                const slug = news_slug?.replaceAll('%20', ' ')
                const uid = credentials ? credentials?.user_id : 0;
                // alert(slug)
                const response = await axiosbaseurl.get(`/news_data/${slug}/${uid}`)
                const current_news = response.data;

                if (credentials) {
                    current_news.isSaved = getNewsSaveStatus(current_news.savednews)
                }
                setCurrNews(current_news)
                window.scrollTo(0, 850);
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }
        if (news_slug)
            fetchNewsData()
    }, [news_slug]);

    // updating news views based on current news
    useEffect(() => {
        if (currNews) {
            const update_views = news.map((news_item) => {
                if (news_item.id === currNews.id) {
                    news_item.views = currNews.views
                    news_item.comments = currNews.comments
                }
                return news_item;
            })

            setNews(update_views)
        }
    }, [currNews])

    const settings = {
        dots: true,
        infinite: news.length>4,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        mobileFirst: true,
        arrows: false,
        afterChange: current => setCurrentSlide(current),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ],
        appendDots: dots => (
            <div style={{ padding: "10px" }}>
                <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
        ),
        customPaging: i => (
            <div
                style={{
                    width: "6px",
                    height: "6px",
                    backgroundColor: "gray",
                    borderRadius: "50%",
                    display: "inline-block",
                    margin: "0 4px",
                }}
            ></div>
        ),
    };

    const handleSavedPost = async (post_id) => {
        if (credentials) {
            setLoading(true)
            try {
                const postData = {
                    user_id: credentials?.user_id,
                    blog_id: post_id,
                    type: 2
                }
                const res = await axiosbaseurl.post('/saveBlog', postData)

                toast.success('Post saved successfully!')

                const updatedData = news.map(post => {
                    if (post.id == post_id) {
                        post.isSaved = true
                    }
                    return post;
                })
                setNews(updatedData)
                setCurrNews((prev) => updatedData.find(item => item.id === prev.id))

                setLoading(false)

            } catch (error) {
                console.log(error)
                toast.error('Something Went Wrong');
                setLoading(false)

            }
        } else {
            toast.error('Please Login to save post.')
        }
    }

    const handleSearchNews = (event) => {
        event.preventDefault()
        if (searchLocation?.value || searchTag?.value) {
            const filteredNews = allNews?.filter((newss) => {
                const locationMatch =
                    searchLocation == "" || newss?.location == searchLocation?.value;

                const tagMatch =
                    searchTag == "" || newss?.category == searchTag?.value;

                // const typeMatch =
                //     searchType === '' || newss?.type?.includes(searchType);

                return locationMatch && tagMatch
            });

            // console.log(filteredNews, searchLocation, searchTag)
            setNews(filteredNews)

        } else {
            toast.error("Atleast 1 field required to filter !")
        }
    }

    const handleResetFilter = () => {
        // window.location.reload()
        setNews(allNews)
        setSearchLocation('')
        setSearchTag('')
    }

    const trending_posts = allNews?.filter(item => item.id != currNews?.id).slice(0, 4);

    // filter options
    const locationOptions = cities_options?.map((city) => ({ value: city.id, label: city.city_name }))
    const tagsOptions = allTags?.map((tag) => ({ value: tag, label: tag }))

    return (
        <>
            {loading && <CustomLoader />}
            <Toaster position="top-right" reverseOrder={false} toastOptions={customToastOptions} />

            <div className='pt-90'>

                <section className="auto-container-lg mt-5 px-md-5">
                    <form onSubmit={handleSearchNews}>
                        <div className="row align-items-center p-3 shadow-sm" style={{ backgroundColor: '#faf4e4' }}>
                            <div className="col-md-8">
                                <div className="d-md-flex py-3">

                                    <div className="news-filter-select">
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={locationOptions}
                                            placeholder="Search by Location"
                                            value={searchLocation}
                                            name="location"
                                            onChange={(e) => setSearchLocation(e)}
                                        />
                                    </div>

                                    <div className="news-filter-select mt-md-0 mt-3 ms-md-4">
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={tagsOptions}
                                            placeholder="Search by Category"
                                            value={searchTag}
                                            name="location"
                                            onChange={(e) => setSearchTag(e)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-4 '>
                                <div className='d-flex justify-content-end'>
                                    <button type="submit" className='blue-btn rounded-3 py-2 px-3 me-2'>
                                        <i className='bi bi-search text-yellow me-2'></i>
                                        Search
                                    </button>
                                    <button type="button" onClick={handleResetFilter} className='blue-btn rounded-3 py-2 px-3 '>
                                        <i className='bi bi-arrow-clockwise text-yellow me-2'></i>
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>

                <div className="news auto-container-lg ">
                    <div>
                        <div>
                            {news?.length === 0 && <h3>News not Available !</h3>}
                        </div>
                        <div className="section-1">
                            <div className='mb-2'>
                                <h3 className="bd-header-overview"><span className='color-yellow'>
                                    {newsHeading?.title?.split(' ')[0]} </span>
                                    {newsHeading?.title?.split(' ')?.slice(1)}</h3>
                                <div className="yellow-line ms-md-auto  mx-md-0 mx-auto"></div>
                            </div>

                            <div className="span3">
                                {newsHeading?.subtitle}
                            </div>
                        </div>

                        <div className="news-list">
                            <Slider {...settings} >
                                {news.map((item, index) => {
                                    const visibleIndex = (index - currentSlide + news.length) % news.length;
                                    let translateY;
                                    switch (visibleIndex) {
                                        case 0:
                                            translateY = -170;
                                            break;
                                        case 1:
                                            translateY = -120;
                                            break;
                                        case 2:
                                            translateY = -65;
                                            break;
                                        case 3:
                                            translateY = 0;
                                            break;
                                        default:
                                            translateY = -120;
                                    }
                                    return (
                                        <NewsCard
                                            key={index}
                                            news={item}
                                            translateY={news.length < 4 ? -40 : translateY}
                                            handleSavedPost={handleSavedPost}
                                        />
                                    );
                                })}
                            </Slider>
                        </div>

                    </div>


                    <section className="row mt-5">
                        <div className="col-md-8">
                            <div
                                className="news-bg-img mt-md-0 mt-5"
                                style={{
                                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("${currNews?.post_img}")`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "10% 30%",
                                }}
                            >
                                {currNews?.isSaved ? (
                                    <div className="p-3 text-end">
                                        <span className="badge bg-yellow text-dark ">Saved</span>
                                    </div>
                                ) : (
                                    <div className="save-text d-flex justify-content-end align-items-center">
                                        <div
                                            style={{ cursor: "pointer" }}
                                            onClick={() => handleSavedPost(currNews?.id)}
                                        >
                                            Save and Read Later{" "}
                                        </div>
                                        <span
                                            style={{ cursor: "pointer" }}
                                            onClick={() => handleSavedPost(currNews?.id)}
                                            className="save-span ms-3"
                                        >
                                            <i className="bi bi-arrow-down"></i>
                                        </span>
                                    </div>
                                )}

                                <div className="property">
                                    {currNews?.category && (
                                        <button className="property-btn">
                                            <span>{currNews?.category}</span>
                                        </button>
                                    )}
                                    <div className="hotprop-content">{currNews?.post_title}</div>
                                    <div className="date">
                                        <span>
                                            {moment(currNews?.created_at).format("MMMM D, YYYY")}
                                            <span className="text-yellow fs-6 px-3">|</span>
                                            <i className="bi bi-eye me-1"></i> {currNews?.views}
                                            <span className="text-yellow fs-6 px-3">|</span>
                                            <i className="bi bi-wechat me-1"></i> {currNews?.comments}
                                        </span>
                                    </div>
                                    <div>
                                        {/* <div className="read1 d-flex align-items-center">
                      READ MORE &nbsp; &nbsp; &nbsp;
                      <span className="save-span">
                        <i className="bi bi-arrow-right"></i>
                      </span>
                    </div> */}
                                    </div>
                                </div>
                            </div>

                            <div className="section-3">
                                {/* dynamic content */}
                                <div>
                                    <div className="title font-saira mb-4">
                                        {currNews?.post_title}
                                        <div className="yellow-line"></div>
                                    </div>
                                    {
                                        <div
                                            dangerouslySetInnerHTML={{ __html: currNews?.post_content }}
                                            className="editor_main_content justified-text"
                                        />
                                    }

                                </div>

                                {/* comments section */}
                                <CommentSection post_id={currNews?.id} type={"news"} />
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="trending-news">
                                <div>
                                    <span className="span1">Trending News Post</span>
                                    <div className="yellow-line"></div>
                                </div>
                                <div className="news-div mt-3">
                                    {trending_posts?.map((news) => {
                                        return (
                                            <Link
                                                to={`${config.appUrl}news/${news?.slug}`}
                                                key={news?.id}
                                                className="text-decoration-none"
                                            >
                                                <div className="news-div1">
                                                    <div>
                                                        <img src={news?.url} alt=""></img>
                                                    </div>
                                                    <div className="content">
                                                        <span className="span1 lh-sm mb-1">
                                                            {news?.post_title?.slice(0, 20)}..
                                                        </span>
                                                        <span>
                                                            {news?.category}
                                                        </span>
                                                        <p className="font-saira justified-text">
                                                            {news?.post_content
                                                                ?.replace(/<[^>]+>/g, "")
                                                                .slice(0, 100)}
                                                            ..
                                                        </p>
                                                        <div className="fw-normal d-flex mt-2">
                                                            <span className="pe-2">
                                                                {moment(news?.created_at).format(
                                                                    "MMMM D, YYYY"
                                                                )}
                                                            </span>
                                                            <span className="px-2 border-left-yellow">
                                                                <i className="bi bi-eye text-yellow me-1"></i>  {news?.views}
                                                            </span>
                                                            <span className="px-2 border-left-yellow">
                                                                <i className="bi bi-wechat text-yellow me-1"></i> {news?.comments}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* ==================================================================================================================== */}


                </div>

            </div>


        </>
    )

}
export default News