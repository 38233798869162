import React, { useEffect, useState } from 'react'
import PropertyCard from './PropertyCard'
import { Link } from 'react-router-dom'
import config from '../../config'
import { useFilters } from './FilterContext'

const PropertiesData = ({
    propertyData, final_data,setPropertyData, allPropertyData, changePage,
    isListingView, total_cards, page, isNeighbourSearch = false, property_type, handleAddFavourite, nearHomeSale }) => {

    const{
        bedCounter,
        setBedCounter,
        bathCounter,
        setBathCounter,
        sqftCounter,
        setSqftCounter,
        yearCounter,
        setYearCounter,
        floorCounter,
        setFloorCounter,
        balconyCounter,
        setBalconyCounter,
        parkingCounter,
        setParkingCounter,
        amenities,
        setAmenities,
        property_types,
        setPropertyTypes,
        all_amenities,
        setAllAmenities,
        all_property_type,
        setAllProperty_types,
        show_more,
        setShowMore,
        sale_type,
        setSaleType,
        ownership,
        setOwnership,
        facing,
        setFacing,
        statustype,
        setstatustype,
    }=useFilters();

    const handleResetProperties = () => {
        setPropertyData(allPropertyData)
        setBedCounter(0);
        setBathCounter(0);
        setSqftCounter(0);
        setYearCounter(2000);
        setFloorCounter(0);
        setBalconyCounter(0);
        setParkingCounter(0);
        setAmenities([]);
        setPropertyTypes([])
        setAllAmenities([]);
        setAllProperty_types([])
        setShowMore(false);
        setSaleType("");
        setOwnership('');
        setFacing('');
        setstatustype('');
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }

    return (
        <div>
            <div className="container1">
                {/* property Listing */}
                <div className="row justify-content-between px-md-4 px-3 mx-0">
                    {
                        final_data
                            .map((property_data) => {
                                return (
                                    <PropertyCard
                                        key={property_data.id}
                                        property_data={property_data}
                                        isListingView={isListingView}
                                        property_type={property_type}
                                        handleAddFavourite={handleAddFavourite}
                                    />
                                )
                            })
                    }
                    {
                        final_data?.length === 0 &&
                        <h4 className='text-center mt-5 '>No Property Found!</h4>
                    }
                    {
                        allPropertyData?.length > propertyData?.length &&
                        <div className='text-center mt-3'>
                            <button onClick={handleResetProperties} className='yellow-btn'>Reset filter</button>
                        </div>
                    }
                </div>


                {/* pagination */}
                <div className="my-5 ">
                    <div className="container2">
                        <div className="pagination-row">
                            <div className='d-flex justify-content-md-start justify-content-center'>
                                {
                                    [...Array(Math.ceil(propertyData.length / total_cards))].map((_, pagecount) => {
                                        pagecount += 1
                                        return (
                                            <div key={pagecount} className={`fw-bold bd-number-pagination  d-flex align-items-center font-saira me-2 pe-1 ${page === pagecount && 'fw-bold text-dark'}`} onClick={() => changePage(pagecount)}>

                                                {pagecount < 10 ? ('0' + pagecount) : pagecount}

                                                {
                                                    page === pagecount &&
                                                    <div className="bd-number-pagination ms-1" >
                                                        <div className="bd-number-dashed "></div>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    (isNeighbourSearch && final_data?.length) ?
                        <>
                            <div className="main-section-rent ">
                                <div className="cites-row">
                                    <div>
                                        <p className="mb-0 main-name font-saira pr-15">Propusers</p>
                                    </div>
                                    <div className="triangle-right pr-15"></div>
                                    <div className="mb-0 cityname-section pr-15">{final_data[0]?.state_name}</div>
                                    <div className="triangle-right pr-15 "></div>
                                    <div className="mb-0 cityname-section pr-15">{final_data[0]?.city_name}</div>
                                    <div className="triangle-right pr-15"></div>
                                    <div className="mb-0 cityname-section">{final_data[0]?.locality_name}</div>
                                </div>
                            </div>

                            {/*home sale near locations */}
                            <div className="pt-40">
                                <div className="home-container">
                                    <div className=" ms-5 pb-20">
                                        <h2 className="bd-homes-header font-saira mb-0">Homes for sale near {final_data[0]?.city_name}</h2>
                                        <div className="yellow-line"></div>
                                    </div>

                                    <div>
                                        <div className="cities-row">
                                            <div className="bd-table-section-first">
                                                <div className="mb-4">
                                                    <p className="bd-neighbor-section mb-1 font-saira">Neighbourhoods</p>
                                                    <div className="yellow-line"></div>
                                                </div>
                                                <div className="bd-main-home-content d-flex flex-column">
                                                    <NearHomeData nearHomeSale={nearHomeSale} type={'locality_name'} neighbourhood={'type=neighbourhood&'} />
                                                </div>
                                            </div>
                                            <div className="bd-table-section">
                                                <div className="mb-4">
                                                    <p className="bd-neighbor-section mb-1 font-saira">Cities</p>
                                                    <div className="yellow-line"></div>

                                                </div>
                                                <div className="bd-main-home-content d-flex flex-column">
                                                    <NearHomeData nearHomeSale={nearHomeSale} type={'city_name'} />
                                                </div>
                                            </div>
                                            <div className="bd-table-section">
                                                <div className="mb-4">
                                                    <p className="bd-neighbor-section mb-1 font-saira" >Pincode</p>
                                                    <div className="yellow-line"></div>
                                                </div>
                                                <div className="bd-main-home-content options d-flex flex-column">
                                                    <NearHomeData nearHomeSale={nearHomeSale} type={'zipcode'} />
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            nearHomeSale.length === 0 &&
                                            <h6 className='text-center paragraph'>Not Found!</h6>
                                        }
                                    </div>
                                </div>

                            </div>
                        </>

                        : null
                }

            </div>
        </div>
    )
}

const NearHomeData = ({ nearHomeSale, type, neighbourhood = '' }) => {

    const [showAll, setShowAll] = useState(false)
    const [datas, setDatas] = useState([])

    useEffect(() => {
        if (!showAll && nearHomeSale.length > 5)
            setDatas(nearHomeSale?.slice(0, 5))
        else
            setDatas(nearHomeSale)

    }, [nearHomeSale, showAll])

    return (
        <>
            {
                datas.map((item, index) => {
                    return (
                        <Link to={`${config.appUrl}property/home-for-sale?${neighbourhood}search_query=${item[type]}`} key={index} >{item[type]}</Link>
                    )
                })
            }
            {
                nearHomeSale.length > 5 &&
                <a className="fw-600" onClick={() => setShowAll(prev => !prev)}>
                    Show {showAll ? 'Less' : 'All'}
                    <i className={`bi bi-chevron-${showAll ? 'up' : 'down'} text-yellow ms-2`}> </i>
                </a>
            }
        </>
    )
}

export default PropertiesData