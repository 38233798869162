import React, { useEffect, useRef, useState } from 'react'
import "../../Styles/BuyPageStyle/BuyVisiting.css"
import Calendar from 'react-calendar';
import "../../Styles/Calender.css"
import googleDuoImg from "../../assets/BuyDetailPage/google-duo.png"
import googleMeetImg from "../../assets/BuyDetailPage/meet.png"
import skypeImg from "../../assets/BuyDetailPage/skype.png"
import whatsapp from "../../assets/BuyDetailPage/whatsapp.png"
import zommImg from "../../assets/BuyDetailPage/zoom.png"
import 'react-calendar/dist/Calendar.css';
import { Modal } from 'antd';
import { axiosbaseurl } from '../../config';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import toast, { Toaster } from 'react-hot-toast';
import { customToastOptions } from '../../Styles/popup_style';
import CustomLoader from '../CustomLoader';
import secureLocalStorage from 'react-secure-storage';

const BuyVisitingTime = () => {

    const [Data,setData] = useState([]);
    const [date, setDate] = useState(new Date());
    const [videoModal, setVideoModal] = useState(false);
    const [userModal, setUserModal] = useState(false);
    const [otpModal, setOtpModal] = useState(false);
    const [finishModal, setFinishModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [schedule_type, setSchedule_type] = useState('person');
    const [virtual_tour, setVirtual_tour] = useState(null);
    const [propertyDetails, setPropertyDetails] = useState({})
    const [timeSlot, setTimeSlot] = useState(null)

    const [morningSlots, setMorningSlots] = useState([])
    const [afternoonSlots, setAfternoonSlots] = useState([])
    const [eveningSlots, setEveningSlots] = useState([])

    // user
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const [errors, setErrors] = useState({})

    const { property_id } = useParams()

    const navigate = useNavigate()

    // otp fields
    const otp_length = 6
    const initialOtp = Array(otp_length).fill('')
    const [otpValues, setOtpValues] = useState(initialOtp);
    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

    const handleInputChange = (index, event) => {
        const { value } = event.target;
        const newValues = [...otpValues];
        newValues[index] = value;
        setOtpValues(newValues);

        // Move to the next input when a digit is entered
        if (value !== '' && index < otp_length - 1) {
            inputRefs[index + 1].current.focus();
        }
    };

    const handleBackspace = (index, event) => {
        if (event.keyCode === 8 && index > 0 && otpValues[index] === '') {
            inputRefs[index - 1].current.focus();
        }
    };
    // ===========================

    useEffect(() => {
        async function getProperty() {
            setLoading(true)
            try {
                const sendData = {
                    property_id,
                    user_id: 0
                }
                const { data } = await axiosbaseurl.post(`/propertiesData`, sendData);
                
                setPropertyDetails(
                    {
                        ...data.data[0],
                        price: (data.data[0].property_on === 'Rent' ? data.data[0]?.price_per_month : data.data[0]?.price)
                    }
                )

                const visit_hours = data.data[0]?.visiting_hrs

                function getSlots(schedule) {
                    return generateTimeSlots(schedule?.visiting_from, schedule?.visiting_to, schedule?.intervals)
                }

                if (visit_hours?.length) {
                    setMorningSlots(getSlots(visit_hours[0]))
                    setAfternoonSlots(getSlots(visit_hours[1]))
                    setEveningSlots(getSlots(visit_hours[2]))
                }
                setData(data);
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }
        getProperty()
    }, [])

    // // validations for first form
    const handleValidation = () => {
        const newErrors = {};

        if (!name) {
            newErrors.name = 'Name is required';
        } else if ((/\d/).test(name)) {
            newErrors.name = 'Digits not allowed in name';
        }

        if (!email) {
            newErrors.email = 'Email is required';
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            newErrors.email = 'Invalid email format';
        }
        if (!phone) {
            newErrors.phone = "Phone number is required";
        }
        else if (phone.length < 10) {
            newErrors.phone = 'Invalid phone number';
        }

        setErrors(newErrors)
        return (Object.keys(newErrors).length === 0);
    }
    // ===========================

    // hhandling modal steps
    const handleSubmitVideo = (e) => {
        e.preventDefault()
        if (virtual_tour) {
            setVideoModal(false)
            setUserModal(true)
        } else {
            toast.error("Please Select Video Type!")
        }
    }

    const handleSubmitForm = async (e) => {
        e.preventDefault()
        if (handleValidation()) {
            setLoading(true)
            try {
                const formData = new FormData()
                formData.append("date", moment(date).format("DD-MM-YYYY"))
                formData.append("time", timeSlot?.slot)
                formData.append("type", virtual_tour ? virtual_tour : null)
                formData.append("name", name)
                formData.append("email", email)
                formData.append("phone", phone)
                formData.append("location", propertyDetails?.city_name)
                formData.append("property_id", propertyDetails?.id)
                formData.append("propreneur_id", propertyDetails?.propreneur_id)

                const response = await axiosbaseurl.post('/visitEnquiry', formData);

                // console.log(response.data)
                if (response.data?.otp) {
                    secureLocalStorage.setItem('schedule_data', response.data)
                    setUserModal(false)

                    setOtpModal(true)
                }

            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }
    }

    const handleVerifyOtp = async(e) => {
        e.preventDefault()
        
        const otp_data = secureLocalStorage.getItem("schedule_data")
        
        if (otp_data?.otp == otpValues.join("")) {
            setLoading(true)
            try {
                await axiosbaseurl.post("/verifyOTP", { id: otp_data?.id })
                    .then((res) => {
                        if (res.data.success) {
                            setOtpModal(false)
                            setOtpValues(initialOtp)
                            setFinishModal(true)
                            secureLocalStorage.removeItem('schedule_data')
                            setTimeSlot(null)
                            setName('')
                            setEmail('')
                            setPhone('')
                            setVirtual_tour(null)

                        }
                    })
                    .catch((error) => console.log(error))
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        } else {
            toast.error("Otp does not matched! ")
            setLoading(false)
        }
    }

    // ======================================================

    // converting slots as per requirements

    const generateTimeSlots = (start, end, interval) => {
        const slots = [];
        const startTime = new Date(`1970-01-01T${convertTo24Hour(start)}`);
        const endTime = new Date(`1970-01-01T${convertTo24Hour(end)}`);

        while (startTime <= endTime) {
            slots.push(formatTime(startTime));
            startTime.setMinutes(startTime.getMinutes() + Number(interval)); // Increment by 30 minutes
        }

        return slots;
    };

    const convertTo24Hour = (time) => {
        const [timePart, modifier] = time.split(/(am|pm)/);
        let [hours, minutes] = timePart.split(':').map(Number);

        if (modifier === 'pm' && hours < 12) hours += 12;
        if (modifier === 'am' && hours === 12) hours = 0;

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    };

    const formatTime = (date) => {
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const modifier = hours >= 12 ? 'pm' : 'am';

        hours = hours % 12 || 12; // Convert 0 to 12
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}${modifier}`;
    };

    // ======================================================

    const videoOptions = [
        {
            img: googleDuoImg,
            name: "Google Duo"
        },
        {
            img: skypeImg,
            name: "Skype"
        },
        {
            img: zommImg,
            name: "Zoom"
        },
        {
            img: whatsapp,
            name: "WhatsApp"
        },
        {
            img: googleMeetImg,
            name: "Google Meet"
        }
    ]

    const blockedDates = Data?.data?.[0]?.blocking_dates?.split(',').map(dateStr => {
        const [day, month, year] = dateStr.split('-');
        return new Date(year, month - 1, day); // Month is 0-indexed
    });
    
    // Function to check if the date is in the blocked list
    const isBlockedDate = (dateToCheck) => {
        return blockedDates?.some((blockedDate) => 
            blockedDate.toDateString() === dateToCheck.toDateString()
        );
    };

    return (
        <div>
            {loading && <CustomLoader />}
            <Toaster
                position="top-right"
                reverseOrder={false}
                toastOptions={customToastOptions}
            />
            <section className="pt-120 pb-60" style={{ backgroundColor: '#f4f4f4' }}>
                <div>
                    <div className='px-4 auto-container-lg'>

                        <div className="row">
                            <div className="col-lg-8">
                                <div className='schedule-header d-md-flex justify-content-between mb-5'>
                                    <div>
                                        <h1 className='fw-500 font-saira mb-1'>Schedule Visit</h1>
                                        <div className="yellow-line"></div>
                                    </div>
                                    <div>
                                        <div className='bg-white d-md-flex justify-content-between p-1 
                                        header-switcher rounded custom-box-shadow mt-md-0 mt-4'>
                                            <div className='font-saira'>Book Your Slots</div>
                                            <div className='font-saira active'>Common Visiting Hour</div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <h3 className='font-saira text-dark-blue mb-1'>Select a Date and Time</h3>
                                        <div className="yellow-line"></div>
                                    </div>

                                    <div className='schedule-date-box custom-box-shadow bg-white p-sm-4 p-3 mt-4'>
                                        <div className='row'>
                                            <div className="col-lg-5 custom-calendar pe-md-0">
                                                <div className='mb-4'>
                                                    <h4 className='font-saira text-dark-blue mb-1'>Date</h4>
                                                    <div className="yellow-line"></div>
                                                </div>
                                                <Calendar 
                                                    minDate={new Date()} onChange={(e) => setDate(e)} value={date} 
                                                    tileDisabled={({ date }) => isBlockedDate(date)}
                                                />
                                            </div>
                                            <div className='col-lg-7 ps-md-4 mt-md-0 mt-5'>
                                                <div className='mb-4'>
                                                    <h4 className='font-saira text-dark-blue mb-1'>Time</h4>
                                                    <div className="yellow-line"></div>
                                                </div>

                                                <div className='schedule-time-box'>
                                                    <div className='row'>
                                                        <div className="col-4 text-center">
                                                            <h5 className='mb-0 font-saira text-444'>Morning</h5>
                                                            {
                                                                morningSlots?.map((slot) => (
                                                                    <div key={slot} className={`time-box 
                                                                        ${timeSlot?.slot === slot && 'active-slot'}`}
                                                                        onClick={() => setTimeSlot({ type: 'Morning', slot })}
                                                                    >{slot}</div>
                                                                ))
                                                            }
                                                        </div>
                                                        <div className="col-4 text-center">
                                                            <h5 className='mb-0 font-saira text-444'>Afternoon</h5>
                                                            {
                                                                afternoonSlots?.map((slot) => (
                                                                    <div key={slot} className={`time-box 
                                                                        ${timeSlot?.slot === slot && 'active-slot'}`}
                                                                        onClick={() => setTimeSlot({ type: 'Afternoon', slot })}
                                                                    >{slot}</div>
                                                                ))
                                                            }
                                                        </div>
                                                        <div className="col-4 text-center">
                                                            <h5 className='mb-0 font-saira text-444'>Evening</h5>
                                                            {
                                                                eveningSlots?.map((slot) => (
                                                                    <div key={slot} className={`time-box 
                                                                        ${timeSlot?.slot === slot && 'active-slot'}`}
                                                                        onClick={() => setTimeSlot({ type: 'Evening', slot })}
                                                                    >{slot}</div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='text-center'>
                                                    <button value={'person'}
                                                        onClick={(e) => setSchedule_type(e.target.value)}
                                                        className={`schedule-btn ${schedule_type === 'person' && 'blue-button'} mt-4 mx-2`}>
                                                        <i className='bi bi-person-fill-down fs-5 me-2'></i>
                                                        Tour in Person
                                                    </button>
                                                    <button value={'video'}
                                                        onClick={(e) => setSchedule_type(e.target.value)}
                                                        className={`schedule-btn ${schedule_type === 'video' && 'blue-button'} mt-4 mx-2`}>
                                                        <i className='bi bi-wechat fs-5 me-2'></i>
                                                        Tour via Video
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className='text-center mt-5'>
                                            <button className='schedule-btn blue-btn fw-600 py-2 mx-2'
                                                onClick={() => {
                                                    if (timeSlot)
                                                        (schedule_type === 'video') ? setVideoModal(true) : setUserModal(true)
                                                    else
                                                        toast.error('Please select time slot')
                                                }}>
                                                Save and Next
                                            </button>
                                            <button onClick={() => navigate(-1)} className='schedule-btn fw-600 text-dark-blue py-2 mx-2'>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 ps-md-4 mt-md-0 mt-5">
                                <div>
                                    <div className='ms-3 mb-4'>
                                        <h3 className='font-saira text-dark-blue mb-1'>Details</h3>
                                        <div className="yellow-line"></div>
                                    </div>

                                    <div className='schedule-date-box custom-box-shadow mb-4 bg-white p-3'>
                                        {
                                            propertyDetails?.propreneurDetails &&
                                            <div className='d-flex align-items-center p-3  schedule-prop-detail mb-3'>
                                                <div>
                                                    <img src={propertyDetails?.propreneurDetails?.prop_avatar} alt="prop_avatar" />
                                                </div>
                                                <div className='ms-4'>
                                                    <h5 className='fw-500 text-dark-blue font-saira'>{propertyDetails?.propreneurDetails?.name}</h5>
                                                    <p className='mb-0 fw-500 font-saira paragraph2'>{propertyDetails?.property_for}</p>
                                                </div>
                                            </div>
                                        }

                                        {
                                            propertyDetails?.image &&
                                            <div className='d-md-flex align-items-center p-3  schedule-prop-detail schedule-prop-detail2'>
                                                <div>
                                                    <img src={propertyDetails?.image[0]?.upload_name} alt="" />
                                                </div>
                                                <div className='ms-md-3 mt-md-0 mt-4'>
                                                    <h4 className='paragraph2 font-saira fw-600 mb-1'>₹ {propertyDetails?.price?.toLocaleString()}</h4>
                                                    <h5 className='fw-500 text-dark-blue font-saira mb-1'>
                                                        {propertyDetails?.property_name}</h5>
                                                    <p className='mb-0 font-saira paragraph2 fw-500 fs-6'>{propertyDetails?.address}</p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className='schedule-date-box custom-box-shadow bg-white p-3'>
                                        <div className='mb-4'>
                                            <h4 className='font-saira text-dark-blue mb-1'>Summary</h4>
                                            <div className="yellow-line"></div>
                                        </div>
                                        <div className='p-3 schedule-prop-detail'>
                                            <div >
                                                <div className='d-flex align-items-center mb-2'>
                                                    <h6 className='fw-500 text-dark-blue font-saira mb-0'>Date:</h6>
                                                    <p className='mb-0 fw-500 font-saira paragraph ms-2'>
                                                        {moment(date).format("ddd, DD MMM YYYY")}
                                                    </p>
                                                </div>
                                                <div className='d-flex align-items-center my-2'>
                                                    <h6 className='fw-500 text-dark-blue font-saira mb-0'>Time to visit:</h6>
                                                    <p className='mb-0 fw-500 font-saira paragraph ms-2'><span className='paragraph2 font-saira' > {timeSlot?.slot} </span> {timeSlot?.type}</p>
                                                </div>
                                                <div className='d-sm-flex align-items-center mb-0'>
                                                    <h6 className='fw-500 text-dark-blue font-saira mb-sm-1'>Visit Option:</h6>
                                                    <div>
                                                        {
                                                            schedule_type === 'person' ?
                                                                <button className='schedule-btn ms-md-2 text-blue'>
                                                                    <i className='bi bi-person-fill-down fs-5 me-2'></i>
                                                                    Tour in Person
                                                                </button> :
                                                                <button className='schedule-btn ms-md-2 text-blue'>
                                                                    <i className='bi bi-wechat fs-5 me-2'></i>
                                                                    Tour via Video
                                                                </button>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            {/* Video form modal */}
            <Modal
                open={videoModal}
                title={""}
                width={500}
                centered
                maskClosable={false}
                className='schedule-visit-modal'
                onCancel={() => {
                    if (virtual_tour)
                        setVirtual_tour(null)
                    setVideoModal(false)
                }}
                footer={<div className="footer-none"></div>}
            >
                <div className="p-4">
                    <form onSubmit={handleSubmitVideo}>
                        <h2 className='fs-3 fw-500 font-saira text-dark-blue'>Choose Your Virtual Tour Options</h2>
                        <div className='py-4'>
                            <div className="row">
                                {
                                    videoOptions.map((video, index) => {
                                        return (
                                            <div key={index} className="col-sm-6 mb-3">
                                                <div className={`d-flex align-items-center video-option p-3 
                                                ${virtual_tour === video.name && 'blue-button'}`}
                                                    style={{ borderRadius: '8px', cursor: 'pointer', border: '1px solid #B2B2B2' }}
                                                    onClick={() => setVirtual_tour(video.name)}
                                                >
                                                    <div className='bg-white rounded-pill' >
                                                        <img src={video.img} alt="" />
                                                    </div>
                                                    <div className='ms-3'>
                                                        <h5 className={`mb-0 ${virtual_tour === video.name ? 'text-white' : 'text-dark-blue'}  font-saira fw-600`}>{video.name}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className='mb-4'>
                            <button type='submit' className='blue-btn w-100 font-saira fs-6 fw-600' style={{ borderRadius: '8px' }}>Save and Next</button>
                        </div>
                    </form>
                </div>
            </Modal>

            {/* user details form modal */}
            <Modal
                open={userModal}
                title={<div className="px-4 py-3" style={{ borderRadius: '10px', backgroundColor: '#F0F0F0' }}>
                    {
                        propertyDetails?.propreneurDetails &&
                        <div className='d-flex align-items-center schedule-prop-detail'>
                            <div>
                                <img src={propertyDetails?.propreneurDetails?.prop_avatar} alt="prop_avatar" />
                            </div>
                            <div className='ms-4'>
                                <h5 className='fw-500 text-dark-blue font-saira mb-0'>{propertyDetails?.propreneurDetails?.name}</h5>
                                <p className='mb-0 fw-500 font-saira paragraph2'>{propertyDetails?.property_for}</p>
                            </div>
                        </div>
                    }
                </div>}
                width={500}
                maskClosable={false}
                centered
                className='schedule-visit-modal'
                onCancel={() => {
                    if (errors)
                        setErrors({})
                    if (virtual_tour)
                        setVirtual_tour(null)
                    setUserModal(false)
                }}
                footer={<div className="footer-none"></div>}
            >
                <div className="p-4">
                    <form onSubmit={handleSubmitForm}>
                        <div className='mb-3'>
                            <label htmlFor="" className='font-saira fs-6 mb-1'>Name</label>
                            <input type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className={`form-control font-saira py-2 ${errors.name && 'border-danger'}`} placeholder='Enter Name' />
                            {
                                errors.name &&
                                <span className='badge text-danger '>
                                    <i className='bi bi-exclamation-triangle me-1'></i> {errors?.name}</span>
                            }
                        </div>
                        <div className='mb-3'>
                            <label htmlFor="" className='font-saira fs-6 mb-1'>Email</label>
                            <input type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className={`form-control font-saira py-2 ${errors?.email && 'border-danger'}`} placeholder='Enter Email' />
                            {
                                errors?.email &&
                                <span className='badge text-danger '>
                                    <i className='bi bi-exclamation-triangle me-1'></i> {errors?.email}</span>
                            }
                        </div>
                        <div >
                            <label htmlFor="" className='font-saira fs-6 mb-1'>Mobile</label>
                            <div className='form-control font-saira py-0 d-flex align-items-center'>
                                <span> +91 </span>
                                <input type="text"
                                    // onKeyPress={(event) => {
                                    //     if (!/[0-9]/.test(event.key))
                                    //         event.preventDefault();
                                    // }}
                                    // value={phone}
                                    // onChange={(e) => {
                                    //     let phonevalue = e.target.value;
                                    //     setPhone(phonevalue)
                                    //     // if (phonevalue.startsWith('+91 ')) {
                                    //     // } else {
                                    //     //     setPhone('+91 ' + phonevalue.replace(/^(\+91\s*)/, ''));
                                    //     // }
                                    // }}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) event.preventDefault();
                                    }}
                                    value={
                                         phone &&  phone.length > 5
                                        ? `${ phone.slice(0, 5)}-${ phone.slice(5)}`
                                        :  phone
                                    }
                                    onChange={(e) => {
                                        const value = e.target.value.replace(/[^0-9]/g, ''); // Remove any non-digit character
                                        setPhone(value); // Pass the cleaned phone number (without hyphen)
                                    }}
                                    maxLength={11}
                                    className={`form-control font-saira border-0 shadow-none py-2 ${errors?.phone && 'border-danger'}`}
                                    placeholder='Enter Mobile' />
                            </div>

                            {
                                errors?.phone &&
                                <span className='badge text-danger '>
                                    <i className='bi bi-exclamation-triangle me-1'></i> {errors?.phone}</span>
                            }
                        </div>
                        <div className='mt-5 mb-4'>
                            <button type='submit' className='blue-btn w-100 font-saira fs-6 fw-600' style={{ borderRadius: '8px' }}>
                                {loading && <i className='bi bi-hourglass-split rotate-icon me-3'></i>}
                                Save and Next</button>
                        </div>
                    </form>
                </div>
            </Modal>

            {/* otp modal */}
            <Modal
                open={otpModal}
                title={
                    <div className='pt-2 ps-3'>
                        <div className='mt-2 bg-yellow py-1' 
                            onClick={() => {
                                setOtpModal(false)
                                setUserModal(true)}}
                            style={{cursor:'pointer', borderRadius:"50%" , width:"fit-content"}}>
                            <i className='bi bi-arrow-left mt-4 px-2 fs-5' ></i>
                        </div>
                    </div>
                }
                width={500}
                centered
                className='schedule-visit-modal '
                maskClosable={false}
                footer={<div className="footer-none"></div>}
                onCancel={() => {
                    if (errors)
                        setErrors({})
                    if (virtual_tour)
                        setVirtual_tour(null)
                    setOtpModal(false)
                }}
            >
                <div className="p-4">
                    <form onSubmit={handleVerifyOtp}>
                        <div className='text-center my-4'>
                            <h3 className='text-dark-blue font-saira mb-0 fw-500'>Verify your Email Address</h3>
                            <h5 className='paragraph2 font-saira py-4'>Code is sent to {email}</h5>
                        </div>
                        <div className='row px-4'>
                            {otpValues.map((value, index) => (
                                <div className="col px-2" key={index}>
                                    <input
                                        key={index}
                                        ref={inputRefs[index]}
                                        type="text"
                                        className='fs-6 form-field bg-white px-1 text-center text-dark py-2'
                                        style={{ boxShadow: '0px 3px 6px #00000029' }}
                                        maxLength={1}
                                        value={value}
                                        placeholder='0'
                                        onChange={(e) => handleInputChange(index, e)}
                                        onKeyDown={(e) => handleBackspace(index, e)}
                                        required
                                    />
                                </div>
                            ))}
                        </div>

                        <div className='py-5 text-center'>
                            <p className='paragraph2 fw-600 font-saira mb-0'>Don’t receive code?</p>
                            <a href="#" onClick={handleSubmitForm} className='font-saira text-decoration-none fw-500' style={{ color: '#16A9EA' }}> Request to Get via Email Address</a>
                        </div>

                        <div className='mb-4'>
                            <button type='submit' className='blue-btn w-100 font-saira fs-6 fw-600' style={{ borderRadius: '8px' }}>
                                {loading && <i className='bi bi-hourglass-split rotate-icon me-3'></i>}
                                Finish</button>
                        </div>
                    </form>
                </div>
            </Modal>

            {/* finished modal */}
            <Modal
                open={finishModal}
                title={""}
                width={500}
                maskClosable={false}
                centered
                className='schedule-visit-modal'
                onCancel={() => setFinishModal(false)}
                footer={<div className="footer-none"></div>}
            >
                <div className="p-4">
                    <div className='mt-5'>
                        <div className='text-center my-5'>
                            <i className='bi bi-patch-check-fill display-3' style={{ color: '#39B54A' }}></i>
                            <h3 className='text-dark-blue font-saira mb-0 mt-2 fw-500'>Thank You!</h3>
                            <p className='text-dark-blue font-saira mb-0 py-3'>Your have been Submitted all information successfully</p>
                            <h5 className='text-dark-blue font-saira '>Soon we will contact you.</h5>
                        </div>

                        <div className='mb-4 pt-4'>
                            <button onClick={() => setFinishModal(false)} className='blue-btn w-100 font-saira fs-6 fw-600' style={{ borderRadius: '8px' }}>Okay Done</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default BuyVisitingTime