import { Link, useLocation, useNavigate } from "react-router-dom";
import config from "../../config";
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css'
import { useState , useEffect } from "react";

const SearchBar = ({homeData , search_type , setSearchType , height}) => {
    const [searchString, setSearchString] = useState("");
    const [listFocusIndex, setListFocusIndex] = useState(0);
    const [selectedSearchTerms, setSelectedSearchTerms] = useState([]);

    const [allCities, setAllCities] = useState([]);
    const [allAddress, setALLAddress] = useState([]);
    const [neighbourhoods, setNeighbourhoods] = useState([]);
    const [searchSuggestions, setSearchSuggestions] = useState([]);

    const location = useLocation()
    
    const queryParams = new URLSearchParams(location.search);
    const search_text = queryParams.get('search_query') || '';
    const type = queryParams.get('type') || '';

    useEffect(() => {
    if (type == 'city') {
        // Split the search_text by space to create an array
        const searchTermsArray = search_text.split(',').filter(term => term.trim() !== '');
        
        setSelectedSearchTerms(searchTermsArray);
    }
    else{
        setSearchString(search_text);
    }
    },[search_text,type])

   

    useEffect(() => {
        if(search_type === 'buy'){
        setALLAddress(homeData?.page_data?.address?.filter(add => add?.property_on === 'Sale'))
        }
        else{
        setALLAddress(homeData?.page_data?.address?.filter(add => add?.property_on === 'Rent'))
        }
    },[search_type])

    const navigate = useNavigate();

    useEffect(() => {
        if (homeData) {
        setAllCities(homeData?.cities);
        setNeighbourhoods(homeData?.localitities);

        const addresses = []
        homeData?.page_data?.address?.forEach(add => {
            if (!addresses.some(item => item?.address === add?.address))
            addresses.push(add)
        })

        setALLAddress(addresses)
        }

        return () => {
        setAllCities([])
        setNeighbourhoods([])
        };
    }, [homeData]);

    // start showing suggestion based on user input
    const handleStartElasticSearch = (event, search_item = false) => {
        const search_string = event.toLowerCase()

        if (search_string.length) {
        const city_suggest = allCities.filter(city => city.city_name?.toLowerCase().includes(search_string))
        const neighbour_suggest = neighbourhoods.filter(neigh => neigh.locality_name?.toLowerCase().includes(search_string))
        const address_suggest = allAddress.filter(address => address.address?.toLowerCase().includes(search_string))

        const all_suggestions = [
            ...getSuggestions(address_suggest, 'address', search_string, 'property'),
            ...getSuggestions(neighbour_suggest, 'locality_name', search_string, 'neighbourhood'),
            ...getSuggestions(city_suggest, 'city_name', search_string, 'city')
        ]
        setSearchSuggestions(all_suggestions)
        } else {
        setSearchSuggestions([])
        }

        setSearchString(event.trim())

        if (search_item) {
        const ptype = (search_type === 'buy') ? 'sale' : 'rent';

        if (search_item === 'neighbourhood') {
            navigate(`${config.appUrl}property/home-for-${ptype}?type=neighbourhood&search_query=${event}`)
        } else
            navigate(`${config.appUrl}property/home-for-${ptype}?search_query=${event}`)
        }

        if (search_string.length === 0)
        setListFocusIndex(0)
    }

    // create suggestion data
    function getSuggestions(options, field_name, search_string, type) {
        return options.map(option => {
        const option_name = option[field_name].toLowerCase();
        const index = option_name.indexOf(search_string);

        const beforeHighlight = option_name.substring(0, index);
        const afterHighlight = option_name.substring(index + (search_string.length));
        return ({
            before: beforeHighlight,
            middle: search_string,
            after: afterHighlight,
            type: type
        })
        })
    }


    const handleTagsChange = (newTags) => {
        if (newTags.length > selectedSearchTerms.length) {
        const latestTag = newTags[newTags.length - 1];
        handleStartElasticSearch(latestTag);
        }
        setSelectedSearchTerms(newTags);
    };

    const handleInputChange = (inputValue) => {
        setSearchString(inputValue); // Update the current search string
        handleStartElasticSearch(inputValue); // Fetch suggestions dynamically
    };

    
    const handleAddSearchTerm = (term , type) => {
        if(type == "property"){
        const ptype = (search_type === 'buy') ? 'sale' : 'rent';
        navigate(`${config.appUrl}property/home-for-${ptype}?search_query=${term}`)
        }
        if (term && !selectedSearchTerms.includes(term)) {
        setSelectedSearchTerms([...selectedSearchTerms, term]);
        setSearchString(""); // Clear the search bar
        setSearchSuggestions([]); // Reset suggestions
        }
    };

    const PlaceHolader = selectedSearchTerms.length > 0 ? "" :"City, Neighbourhood, Address.."

    const handelclearInput = () => {
        setSearchString('')
        setSelectedSearchTerms([])
    }


    return(
        <div className="position-relative w-100">
            <div>
                <TagsInput 
                    value={selectedSearchTerms}
                    onChange={handleTagsChange}
                    classNames={{
                        tags: "custom-main-span", // This targets the main <span> wrapper
                        tagInput: "custom-tag-input",
                        selected: "custom-selected-tags",
                        tag: "custom-single-tag"
                    }}
                    inputProps={{
                        value: searchString,
                        placeholder : PlaceHolader,
                        onChange: (e) => handleInputChange(e.target.value),
                        className: selectedSearchTerms.length > 0 ? "small-input" : "large-input"
                    }} 
                    addKeys={[13]}
                    onlyUnique={true} 
                />
                <div className="Search-Button-Home">
                    <button className="bd-search-button-bar"
                    onClick={() => {
                        const ptype = (search_type === 'buy') ? 'sale' : 'rent';
                        navigate(`${config.appUrl}property/home-for-${ptype}?search_query=${selectedSearchTerms}&type=city`)
                    }}
                    >
                    <i className="fa fa-search search-icons"></i>
                    </button>
                </div>
                {
                    (searchString != "" || selectedSearchTerms.length > 0 ) && (
                        <div className="Clear-Button">
                            <i class="bi bi-x-circle-fill" onClick={handelclearInput} style={{cursor:"pointer"}}></i>
                        </div>
                    )
                }
                
            </div>
            <div >
                {
                searchString.length > 1 && searchString != search_text &&
                <div className="suggestion-box">
                    {
                    searchSuggestions.map((suggestion, index) => {
                        return (
                        <div
                            key={index}
                            onClick={() =>
                            handleAddSearchTerm(suggestion.before + suggestion.middle + suggestion.after , suggestion.type)
                            }
                            className={index === listFocusIndex ? "active_list" : ""}
                        >
                            {suggestion.before}
                            <strong style={{ color: "#f0b14a" }}>{suggestion.middle}</strong>
                            {suggestion.after}
                            {suggestion.type && (
                            <strong className="badge bg-yellow text-dark-blue ms-4">
                                {suggestion.type}
                            </strong>
                            )}
                        </div>

                        )
                    })
                    }
                    {
                    searchSuggestions.length === 0 &&
                    <div className="text-danger">No Result Found !</div>
                    }
                </div>
                }
            </div>
        </div>
    )
}

export default SearchBar;