import React, { useRef, useState } from 'react'
import "../../assets/Css/HomeLoanCalculator.css"

const HomeLoanCalculator = () => {

    const [amount, setAmount] = useState(100000)
    const [years, setYears] = useState(10)
    const [interest, setInterest] = useState(2)

    const [monthly_emi, setMonthlyEmi] = useState(0)

    const calculateButtonRef = useRef(null);

    const calculator_fields = [
        {
            id: 1,
            label: "Amount:",
            minValue: 100000,
            maxValue: 2000000,
            step: 10000,
            symbol: "₹",
            value: amount,
            setValue: setAmount
        },
        {
            id: 2,
            label: "Number of years:",
            minValue: 10,
            maxValue: 30,
            step: 1,
            symbol: "Years",
            value: years,
            setValue: setYears
        },
        {
            id: 3,
            label: "Interest rate:",
            minValue: 2,
            maxValue: 24,
            step: 1,
            symbol: "%",
            value: interest,
            setValue: setInterest
        },
    ]

    // main calculation
    const calculateEMI = (P, r, y) => {
        const interest_rate = r / (12 * 100);
        const durationInMonth = y * 12;

        const monthlyEmiAmt = P * interest_rate *
            (Math.pow(1 + interest_rate, durationInMonth) / (Math.pow(1 + interest_rate, durationInMonth) - 1))

        return monthlyEmiAmt;
    };

    const handleCalculate = () => {
        setMonthlyEmi(calculateEMI(amount, interest, years))
        // Scroll to the Calculate button and adjust the scroll position by 50px down
        const button = calculateButtonRef.current;
        if (button) {
            // After the scroll animation, adjust by 50px downward
            setTimeout(() => {
                window.scrollBy(0, 300); // Scroll 50px down from the current position
            }, 300); // Wait for the scroll animation to complete
        }
    }

    return (
        <div>
            <div className='loan-calculator-box'>
                <div className='table-responsive'>
                    <table className='w-100' style={{ verticalAlign: 'end' }} >
                        <tbody>
                            {
                                calculator_fields.map((field) => {
                                    return (
                                        <CalculatorField key={field.id} field={field} />
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>

                <div>
                    <button onClick={handleCalculate} ref={calculateButtonRef} className='calculate-btn font-saira mt-4 mb-5'>
                        Calculate
                    </button>
                </div>

                <div className='loan-result-box shadow-sm'>
                    <div>
                        <div>
                            <h5 className='text-dark-blue font-saira fw-600'>Your Monthly Payments:</h5>
                            <hr style={{ padding: '1px' }} />

                            <h6 className='mt-3 mb-4 font-saira' style={{ color: '#444444' }}>Base Calculation</h6>
                        </div>
                        <div className='row '>
                            <div className="col-md-3 pe-2">
                                <input type="text" className='output-input-field font-saira' value={`₹ ${amount}`} readOnly />
                                <h6 className='font-saira mt-3' style={{ color: '#444444' }}>Loan Amount</h6>
                            </div>
                            <div className="col-md-3 px-2">
                                <input type="text" className='output-input-field font-saira' value={years} readOnly />
                                <h6 className='font-saira mt-3' style={{ color: '#444444' }}>Numbers of Years</h6>
                            </div>
                            <div className="col-md-3 px-2">
                                <input type="text" className='output-input-field font-saira' value={`${interest}%`} readOnly />
                                <h6 className='font-saira mt-3' style={{ color: '#444444' }}>Interest Rate</h6>
                            </div>
                            <div className="col-md-3 ps-2">
                                <input type="text" className='output-input-field font-saira fs-3 pt-0 pb-1'
                                    value={`₹ ${Math.round(monthly_emi).toLocaleString()}`} readOnly />
                                <h6 className='font-saira mt-3' style={{ color: '#444444' }}>Monthly Payments</h6>
                            </div>
                        </div>

                        {/* <hr style={{ padding: '1px' }} />
                        <div>
                            <button className='calculate-btn font-saira mt-5   w-100'>
                                Get Started
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

const CalculatorField = ({ field }) => {
    return (
        <tr className='bg-white mortgage-calculator'>
            <td className='py-3'>
                <h5 className='mb-0 font-saira text-dark-blue '>{field.label}</h5>
            </td>
            <td className='py-3'>
                <div className='mx-auto' style={{ width: '350px' }}>
                    <div className='d-flex justify-content-between pb-2' style={{ color: '#555' }}>
                        <span className='font-saira'>
                            {field.id === 1 && field.symbol}
                            {` ${field.minValue} `}
                            {[2, 3].includes(field.id) && field.symbol}
                        </span>
                        <span className='font-saira'>
                            {field.id === 1 && field.symbol}
                            {` ${field.maxValue} `}
                            {[2, 3].includes(field.id) && field.symbol}
                        </span>
                    </div>
                    <input type="range"
                        min={field.minValue}
                        max={field.maxValue}
                        step={field.step}
                        value={field.value}
                        onChange={(e) => field.setValue(e.target.value)}
                        className='w-100' />
                </div>
            </td>
            <td className='py-3'>
                <div className='loan-value-box shadow-sm ms-auto'>
                    <div className='font-saira'> {field.symbol} </div>
                    <div>
                        <input type="text"
                            className='font-saira'
                            value={field.value}
                            min={field.minValue}
                            max={field.maxValue}
                            onChange={(e) => field.setValue(e.target.value)}
                        />
                    </div>
                </div>
            </td>
        </tr>
    )
}

export default HomeLoanCalculator