import React, { useRef, useState } from 'react'

const Videos = ({ currentShowCase }) => {
    return (
        <div className="row">

            {
                currentShowCase?.map(video => {
                    return (
                        <div className="col-md-4 mb-md-0 mb-4">
                            <div className="photo-card video-card">
                                <Video video_url={video?.upload_name} />
                                <section className='pb-4'>
                                    <h6 className='mb-1'>{video?.tag}</h6>
                                    <span className="yellow-line d-block"></span>
                                </section>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

const Video = ({ video_url }) => {

    const [videoPlaying, setVideoPlaying] = useState(false);
    const videoRef = useRef(null);

    const handlePlayVideo = () => {
        if (videoPlaying) {
            setVideoPlaying(false);
            videoRef?.current.pause();
        } else {
            setVideoPlaying(true);
            videoRef?.current.play();
        }
    };

    return (
        <div className='position-relative p-0'>
            <video
                controls
                ref={videoRef}  
                // src="https://videos.pexels.com/video-files/7347891/7347891-uhd_2560_1440_25fps.mp4"
                src={video_url}
            ></video>

            {/* <button className="play-btn" onClick={handlePlayVideo}>
                <i
                    className={`bi bi-${videoPlaying ? "pause" : "play"}-fill`}
                ></i>
            </button> */}
        </div>
    )
}

export default Videos