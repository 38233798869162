import React from 'react'

const VirtualTour = ({ currentShowCase }) => {
    return (
        <section >
            <div style={{ borderRadius: '14px', overflow: 'hidden' }}>
                {
                    currentShowCase[0]?.upload_name ?
                    <video controls
                        src={currentShowCase ? currentShowCase[0]?.upload_name : ''}
                        className='w-100'
                    ></video> :
                    <div className='text-center'><h4>No Virtual Tour Found!</h4></div>
                }
            </div>
        </section>
    )
}

export default VirtualTour