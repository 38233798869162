import React, { useContext, useEffect } from 'react'

import propuserlogo from '../../assets/Propusers_logo-White.png';
import '../../Styles/Comman.css';
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useState } from 'react';
import config, { axiosbaseurl } from '../../config';
import toast, { Toaster } from 'react-hot-toast';
import { customToastOptions } from '../../Styles/popup_style';
import secureLocalStorage from 'react-secure-storage';

// facebook login
import FacebookLogin from 'react-facebook-login';

// google login
import { useGoogleLogin } from '@react-oauth/google';
import { AppGlobalContext } from '../../GlobalContext';
import CustomLoader from '../CustomLoader';

const SignInFirst = () => {

    const [login_data, setLoginData] = useState('')
    const [loading, setLoading] = useState(false);

    const location = useLocation();

    useEffect(() => {
        const LoginData = secureLocalStorage.getItem("Login_Creds");
        setLoginData(LoginData?.email);
    },[])

    useEffect(() => {
        if (location?.state?.data) {
            setLoginData(location?.state?.data)
        }
    },[])

    const { boardingData } = useContext(AppGlobalContext);
    // for facebook login

    const handleSentOtp = async (e) => {
        e.preventDefault()
        // const login_type = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(login_data) ? 1 : 2;

        // if (login_type === 1) {
        //     navigate(`${config.appUrl}sign-in`, { state: { data: login_data } })
        // } else if (login_type === 2) {

        //     const formData = {
        //         email: login_data,
        //         type: login_type
        //     }

        //     try {
        //         setLoading(true)
        //         const response = await axiosbaseurl.post('/sendOtp', formData, {
        //             headers: {
        //                 Accept: "application/json",
        //                 "Content-Type": "multipart/form-data",
        //             }
        //         })

        //         if (response.data.success == true) {
        //             const temp = {
        //                 otp: response.data.otp,
        //                 user_id: response.data.user
        //             }
        //             secureLocalStorage.setItem('temp_data', temp)
        //             toast.success('Otp Sent Successfully!')
        //             setTimeout(() => {
        //                 navigate(`${config.appUrl}login-otp`, { state: { data: login_data } })
        //             }, 2000)
        //         } else {
        //             toast.error('User Not Found!');
        //         }
        //         setLoading(false)

        //     } catch (error) {
        //         console.log(error)
        //         toast.error('Something went wrong!');
        //         setLoading(false)
        //     }
        // }

        navigate(`${config.appUrl}sign-in`, { state: { data: login_data } })
    }

    const navigate = useNavigate();

    // Google Login
    const loginByGoogle = useGoogleLogin({
        onSuccess: (codeResponse) => {
            // setUser(codeResponse)

            // console.log('Google Login Success:', codeResponse);

            async function sendUserData() {
                try {
                    setLoading(true)
                    const response = await axiosbaseurl.post('/auth/google', { access_token: codeResponse.access_token })

                    // console.log('Demo Server Response:', response.data);

                    if (response.data.success == true) {
                        toast.success('Login Successfull')
                        secureLocalStorage.setItem("credentials", {
                            user_id: response.data.data.id,
                            name: response.data.data.name,
                            email: response.data.data.email,
                            image: response.data.data.avatar,
                            blogs: response.data.data.blogs?.map((post) => post.id) || [],
                            news: response.data.data.news?.map((post) => post.id) || []
                        })
                        setTimeout(() => {
                            setLoading(false)
                            navigate(config.appUrl)
                        }, 500)
                    }

                    // Handle success or error based on server response
                } catch (error) {
                    console.error('Error logging in:', error);
                }
            }

            sendUserData()

        },
        onError: (error) => console.log('Login Failed:', error)
    });

    // Facebook Login
    const responseFacebook = async (facebook_response) => {
        try {
            setLoading(true)

            // console.log('facebook_response', facebook_response);
            if (facebook_response.error) {
                console.error('Error from Facebook:', facebook_response.error);
                return;
            }

            const response = await axiosbaseurl.post('/auth/facebook', { access_token: facebook_response.accessToken })

            // console.log('api : ', response)

            if (response.data.success == true) {
                toast.success('Login Successfull')
                secureLocalStorage.setItem("credentials", {
                    user_id: response.data.data[0]?.id,
                    name: response.data.data[0]?.name,
                    email: response.data.data[0]?.email || '',
                    image: response.data.data[0]?.avatar,
                    blogs: response.data.data[0]?.blogs?.map((post) => post.id) || [],
                    news: response.data.data[0]?.news?.map((post) => post.id) || []
                })

                setTimeout(() => navigate(config.appUrl), 400)
            }

        } catch (error) {
            console.log('Error : ', error.message)
        } finally {
            setLoading(false)
        }
    };

    const handleFacebookLoginClick = () => {
        document.querySelector('button.hidden.metro').click();
    };
    // =================================

    const social_icons = boardingData?.login?.social_media?.split(',') || [0, 0, 0, 0]

    return (
        <div>
            <Toaster position="top-right" reverseOrder={false} toastOptions={customToastOptions} />

            {loading && <CustomLoader/>}
            <div
                className='bg-image py-4 row align-items-center mx-0'
                style={{
                    marginTop: "90px",
                    minHeight: '110vh',
                    backgroundImage: `linear-gradient(to left, transparent 20%, #374053), url(${boardingData?.login?.image})`
                }}
            >
                <div className="col-xl-5 col-lg-6 col-md-8 ms-md-5 px-md-5">

                    <div className='form-container py-5'>
                        <div className='top-form-section mb-2 mt-5'>
                            <img src={propuserlogo} alt=""></img>

                            <h4 className='my-4 py-3 text-white'
                                dangerouslySetInnerHTML={{ __html: boardingData?.login?.title }}
                            />

                        </div>

                        <form onSubmit={handleSentOtp}>
                            <div className="row">

                                <div className="col-12 mb-3">

                                    <div className="form-floating mb-3">
                                        <input type='text' name='email'
                                            onChange={(e) => setLoginData(e.target.value)}
                                            value={login_data}
                                            className={'form-control form-field'}
                                            required
                                            placeholder="Email or Phone"></input>

                                        <label htmlFor="name">Enter Phone No/Email Id</label>
                                    </div>
                                </div>

                            </div>

                            <div >
                                <button className='btn-blue w-100' type='submit' disabled={loading} >
                                    {
                                        loading ? "Verifying.." : "Login"
                                    }
                                </button>
                            </div>

                            <div className='d-flex justify-content-evenly my-5 fs-1'>
                                <div style={{ cursor: 'pointer' }} onClick={() => loginByGoogle()}><i className="fa fa-google color-google"></i></div>
                                {/* <div style={{ cursor: 'pointer' }} onClick={handleFacebookLoginClick}><i className="fa fa-facebook color-facebook"></i></div> */}
                                {/* {
                                    social_icons[0] == 1 &&
                                    <div style={{ cursor: 'pointer' }} onClick={() => loginByGoogle()}><i className="fa fa-google color-google"></i></div>
                                }
                                {
                                    social_icons[1] == 1 &&
                                    <div style={{ cursor: 'pointer' }} onClick={handleFacebookLoginClick}><i className="fa fa-facebook color-facebook"></i></div>
                                } */}
                                {/* {
                                    social_icons[2] == 1 &&
                                    <div style={{ cursor: 'pointer' }}><i className="fa fa-linkedin color-linkedin"></i></div>
                                }
                                {
                                    social_icons[3] == 1 &&
                                    <div style={{ cursor: 'pointer' }}><i className="fa fa-apple color-apple"></i></div>
                                } */}

                            </div>

                            <div className='d-none'>
                                <FacebookLogin
                                    appId={process.env.REACT_APP_FACEBOOK_APP_ID} // Replace with your Facebook App ID
                                    fields="name,email,picture"
                                    callback={responseFacebook}
                                    scope="email"
                                    cssClass="hidden" // Hide the Facebook button by default
                                    id="facebookLoginBtn"
                                />
                            </div>

                        </form>

                        <div className='mt-5 text-center'>
                            <p style={{ color: "white" }}>New to Propusers ?
                                <Link to={`${config.appUrl}create-account`} className='text-decoration-none text-yellow'> Sign Up</Link></p>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default SignInFirst