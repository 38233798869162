import React, { useContext, useEffect, useRef, useState } from 'react'

import Union from "../../assets/footer/Union-img.png"

import WorldMap from "../../assets/footer/World-Map.png"
import IndiaDotedMap from "../../assets/footer/IndiaDotted Map.png"
import Skyline from "../../assets/footer/skyline.png"
import Location from "../../assets/footer/location.png"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons'
import { axiosbaseurl } from '../../config'
import CustomLoader from '../CustomLoader'
import IndianMap from './IndianMap'
import { AppGlobalContext } from '../../GlobalContext'

const OfficeLocation = () => {
    const [headquarters, setHeadquarters] = useState([]);
    const [regionalOffice, setRegionalOffice] = useState([]);
    const [multiLocation, setMultiLocation] = useState([]);
    const [allMultiLocation, setAllMultiLocation] = useState([]);
    const [loading, setLoading] = useState(false);
    const [headingData, setHeadingData] = useState({})

    const { cities_options } = useContext(AppGlobalContext)

    const tab1Ref = useRef(null)
    const tab2Ref = useRef(null)
    const tab3Ref = useRef(null)
    

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true)
        axiosbaseurl.get(`/officeLocations`)
            .then(res => {
                // setOfficeContent(res.data.data)
                setHeadingData(res.data.titles[0])
                setHeadquarters(res.data.headquaters)
                setRegionalOffice(res.data.regional)
                setMultiLocation(res.data.multi)
                setAllMultiLocation(res.data.multi)
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false)
            })

        return () => {
            setHeadquarters([])
            setRegionalOffice([])
            setMultiLocation([])
            setAllMultiLocation([])
            controller.abort();
        };
    }, [])

    const filterByCity = (e) => {
        if (e.target.value)
            setMultiLocation(
                allMultiLocation?.filter((location) => location?.city_id == e.target.value)
            )
        else
            setMultiLocation(allMultiLocation)
    }

    function scrollToTabElement(divRef) {
        const { offsetTop } = divRef.current
        window.scrollTo({ top: offsetTop - 90, behavior: 'smooth' })
    }

    return (
        <div>
            {loading && <CustomLoader />}
            <div className="pt-90">
                <div className='pt-30 pb-20'>
                    <div className='d-flex justify-content-center '>
                        <div className='d-flex flex-column align-items-center '>
                            <div>
                                <h3 className="bd-header-overview mb-0">
                                    <span className='color-yellow'>{headingData?.title?.split(' ')[0]}  </span> {headingData?.title?.split(' ')?.slice(1)}</h3>
                                <div className="yellow-line"></div>
                            </div>
                            <p className='mt-3 mb-0 subtitle fs-6 text-center pb-2 px-md-5 px-4'>
                                {/* Our Office
                                <strong className='font-saira'> Location </strong>  Pan
                                <strong className='font-saira'> India</strong> */}
                                {headingData?.subtitle}</p>
                        </div>
                    </div>
                    {/* <div className='text-center'>
                        <div className='bd-heading-team'>
                            <span>Office </span>
                            <span> Location </span>
                        </div>
                        <div className="pt-10">
                            <p>Our Office <strong>Location</strong> Pan <strong>India</strong></p>
                        </div>
                    </div> */}

                    {/* <img src={Union} alt="" className='bd-union-img d-md-block d-none' /> */}
                    <div className="bd-india-logo mt-4">
                        <div className='bd-india-map'>
                            <IndianMap
                                headquarters={headquarters?.filter(item => (item.latitude && item.longitude))}
                                regionalOffice={regionalOffice?.filter(item => (item.latitude && item.longitude))}
                                multiLocation={multiLocation?.filter(item => (item.latitude && item.longitude))}
                            />
                        </div>
                    
                        <div className="loctaion-right py-3 py-xl-0">
                            <div className="w-100">
                                <div onClick={() => scrollToTabElement(tab1Ref)} className='text-decoration-none'>
                                    <div className="display-flex bd-office-buttons align-item-center mb-15  ">
                                        <div className="bd-icons-office">
                                            <img src={Skyline} />
                                        </div>
                                        <div className="pl-10">
                                            <p className='mb-0 text-white font-saira fw-600'>Headquarters</p>
                                        </div>
                                    </div>
                                </div>

                                <div onClick={() => scrollToTabElement(tab2Ref)} className='text-decoration-none'>
                                    <div className="display-flex bd-office-buttons align-item-center mb-15 bg-office">
                                        <div className="bd-icons-office">
                                            <img src={Location} alt="" />
                                        </div>
                                        <div className="pl-10">
                                            <p className='mb-0 text-white font-saira fw-600'>Regional Office</p>
                                        </div>
                                    </div>
                                </div>

                                <div onClick={() => scrollToTabElement(tab3Ref)} className='text-decoration-none'>
                                    <div className="display-flex bd-office-buttons align-item-center bg-location">
                                        <div className="bd-icons-office">
                                            <img src={Skyline} alt="" />
                                        </div>
                                        <div className="pl-10">
                                            <p className='mb-0 text-white font-saira fw-600'>Multi Location</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <div className='container-2 bd-responsive-cities'>
                        <div>
                            <div className="display-flex">
                                <div className="bd-location-union">
                                    <div className='bd-union-relative'>
                                        <img src={Union} alt="" className='bd-union-img d-md-block d-none' />
                                        <div className="bd-india-logo">
                                            <div className='bd-india-map'>
                                                <IndianMap
                                                    headquarters={headquarters?.filter(item => (item.latitude && item.longitude))}
                                                    regionalOffice={regionalOffice?.filter(item => (item.latitude && item.longitude))}
                                                    multiLocation={multiLocation?.filter(item => (item.latitude && item.longitude))}
                                                />
                                            </div>
                                        </div>
                                        <div >
                                            <img src={WorldMap} alt="" className="bd-world-map d-md-block d-none" />
                                        </div>
                                        <div>
                                            <img src={IndiaDotedMap} alt="" className='bd-dotted-map d-md-block d-none' />
                                        </div>
                                    </div>

                                </div>
                                <div className="loctaion-right">
                                    <div className="pt-40">
                                        <div onClick={() => scrollToTabElement(tab1Ref)} className='text-decoration-none'>
                                            <div className="display-flex bd-office-buttons align-item-center mb-15  ">
                                                <div className="bd-icons-office">
                                                    <img src={Skyline} />
                                                </div>
                                                <div className="pl-10">
                                                    <p className='mb-0 text-white font-saira fw-600'>Headquarters</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div onClick={() => scrollToTabElement(tab2Ref)} className='text-decoration-none'>
                                            <div className="display-flex bd-office-buttons align-item-center mb-15 bg-office">
                                                <div className="bd-icons-office">
                                                    <img src={Location} alt="" />
                                                </div>
                                                <div className="pl-10">
                                                    <p className='mb-0 text-white font-saira fw-600'>Regional Office</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div onClick={() => scrollToTabElement(tab3Ref)} className='text-decoration-none'>
                                            <div className="display-flex bd-office-buttons align-item-center bg-location">
                                                <div className="bd-icons-office">
                                                    <img src={Skyline} alt="" />
                                                </div>
                                                <div className="pl-10">
                                                    <p className='mb-0 text-white font-saira fw-600'>Multi Location</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="py-5 container-3 bd-second-office" ref={tab1Ref}>
                        <div className="" id='headquarters'>
                            <div className="">
                                <h3 className="bd-header-overview text-dark-blue mb-0">Headquarters</h3>
                                <div className="yellow-line"></div>

                            </div>
                            <div >
                                <div className="row">
                                    {
                                        headquarters.map(item => {
                                            return <LocationCard key={item.id} officeData={item} />
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="pt-4" id='regionoffice' ref={tab2Ref}>
                            <div className="">
                                <h3 className="bd-header-overview text-dark-blue mb-0">Regional Office</h3>
                                <div className="yellow-line"></div>
                            </div>
                            <div>
                                <div className="row">
                                    {
                                        regionalOffice.map(item => {
                                            return <LocationCard key={item.id} officeData={item} />
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="pt-4 mt-5" id='multilocation' ref={tab3Ref}>
                            <div className='d-md-flex align-items-center'>
                                <div className="">
                                    <h3 className="bd-header-overview text-dark-blue mb-0">Multi Locations</h3>
                                    <div className="yellow-line"></div>
                                </div>
                                <div className='position-relative ms-md-5 mt-md-0 mt-4'>
                                    <select className="bd-select-option bd-select-option2 bg-dark-blue ps-3"
                                        name='location'
                                        onChange={filterByCity}
                                    >
                                        <option value="">All Cities</option>
                                        {
                                            cities_options?.map((city) => {
                                                return (
                                                    <option key={city.id} value={city.id}>
                                                        {city.city_name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <i className='fa fa-chevron-down text-yellow pass-eye' style={{ pointerEvents: 'none' }}></i>
                                </div>
                            </div>
                            <div>
                                <div className="row">
                                    {
                                        multiLocation?.map(item => {
                                            return <LocationCard key={item.id} officeData={item} />
                                        })
                                    }

                                    {
                                        multiLocation?.length === 0 &&
                                        <h4 className='mt-5 font-saira text-danger text-md-start text-center'>No Multi-location Found !</h4>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}

const LocationCard = ({ officeData }) => {
    return (
        <div className="col-lg-3 col-md-4 col-sm-6 mt-5">
            <div className="office-location-card">
                <div className="bd-all-location">
                    <h5 className='font-saira text-dark-blue'>{officeData.company}</h5>
                    <p className="my-1 font-saira office_location_text" dangerouslySetInnerHTML={{__html: officeData.address}} />
                </div>
                <div>
                    <span className="pr-10 text-dark-blue"><FontAwesomeIcon icon={faEnvelope} /></span>
                    <a href={`mailto:${officeData.email}`} className='bd-info-loca font-saira fw-500 text-decoration-none'>{officeData.email}</a>
                </div>
                <div>
                    <span className="pr-10 text-dark-blue"><FontAwesomeIcon icon={faPhoneVolume} /></span>
                    <a href={`tel:${officeData.phone}`} className='bd-info-loca font-saira fw-500 text-decoration-none'>{officeData.phone}</a>
                </div>
            </div>
        </div>
    )
}

export default OfficeLocation