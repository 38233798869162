import React, { useContext, useEffect, useRef, useState } from "react";
import lifeIcon from "../../assets/Key Features Icons/fixed-propuser.png";

// Assets
import "../../assets/Css/Career.css";
import blockImg from "../../assets/Career/blocks-design.png";
import blueIcon from "../../assets/Career/blueIcon.png";
import yellowIcon from "../../assets/Career/yellowIcon.png";

import Slider from "react-slick";
import config, { axiosbaseurl } from "../../config";
import CustomLoader from "../CustomLoader";
import CareerForm from "./CareerForm";
import { Link, useNavigate } from "react-router-dom";
import megaPhoneIcon from "../../assets/Career/megaphone_icon.png";
import homeSaleIcon from "../../assets/Career/home-sale-icon.png";
import { Modal, Tooltip } from "antd";
import { AppGlobalContext } from "../../GlobalContext";

const Career = () => {
  const [careerContent, setCareerContent] = useState({});
  const [filteredCatyegories, setFilteredCatyegories] = useState([]);
  const [counter, setCounter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [awardModal, setAwardModal] = useState(false);
  const [awardImg, setAwardImg] = useState('');

  const [videoPlaying, setVideoPlaying] = useState(true);

  const { cities_options } = useContext(AppGlobalContext)

  const sliderRef = useRef();
  const videoRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    axiosbaseurl
      .get(`/careerPage`)
      .then((res) => {
        setCareerContent(res.data);
        setFilteredCatyegories(res.data.trending?.divs);
        const titlesArr = res.data.counts.title?.split(",");
        const countsArr = res.data.counts?.counts?.split(",");

        const modifyArr = titlesArr.map((title, index) => ({
          id: index + 1,
          title: title,
          count: countsArr[index],
        }));
        setCounter(modifyArr);
        // console.log(modifyArr);

      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });

    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'instant'
      })
    }, 500)

    return () => {
      setCareerContent({})
      setFilteredCatyegories([])
      controller.abort();
    };
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: (filteredCatyegories.length > 5) ? true : false,
    speed: 1000,
    slidesToShow: 5,
    swipeToSlide: true,
    autoplay: false,
    arrows: false,
    mobileFirst: true,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 450,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 1,
          infinite: true,
        },
      },
    ],
  };
  const sliderSettings2 = {
    dots: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 4,
    swipeToSlide: true,
    autoplay: true,
    mobileFirst: true,
    arrows: false,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          // arrows: false,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          // arrows: false,
          // dots: false,
          slidesToShow: 1,
          infinite: true,
        },
      },
    ],
  };
  const sliderSettings3 = {
    dots: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 3,
    swipeToSlide: true,
    autoplay: true,
    arrows: false,
    mobileFirst: true,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          // arrows: false,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          // arrows: false,
          // dots: false,
          slidesToShow: 1,
          infinite: true,
        },
      },
    ],
  };

  const {
    intro,
    value,
    our_value,
    empowerment,
    award,
    learn_more,
    trending,
    jobs,
    career_title,
    formheading
  } = careerContent;

  const getHeadingValues = (heading) => {
    const t1 = heading?.split(" ").slice(0, 1);
    const t2 = heading?.split(" ").slice(1);
    return [t1, t2?.join(" ")];
  };

  const [handleView, sethandleview] = useState(false);

  const handleListView = (value) => {
    if (value === "blue") {
      sethandleview(true);
    } else {
      sethandleview(false);
    }
  };

  const handlePlayVideo = () => {
    if (videoPlaying) {
      setVideoPlaying(false);
      videoRef?.current.pause();
    } else {
      setVideoPlaying(true);
      videoRef?.current.play();
    }
  };

  const getByLocation = (e) => {
    if (e.target.value === "") {
      setFilteredCatyegories(trending?.divs);
    }
    else {
      const temp = trending?.divs.filter((item) =>
        item.location == e.target.value
      );
      setFilteredCatyegories([...temp]);
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [intro?.video_url]);

  return (
    <>
      {loading && <CustomLoader />}

      <div className="pt-90">
        {/* First Section */}
        <section className="py-5">
          <div className="container-box">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-5">
                <div className="career-text-content  ">
                  <h2>{intro?.title}</h2>
                  <p className="my-4 pe-md-5 justified-text"
                    dangerouslySetInnerHTML={{ __html: intro?.description }}
                  />

                  <Link
                    to={`${config.appUrl}about-us`}
                    className="yellow-btn text-decoration-none fs-6"
                  >
                    View more
                  </Link>
                </div>
              </div>

              <div className="col-md-6 position-relative mt-md-0 mt-5 career-video">
                <video
                  ref={videoRef}
                  // poster={HeroBanner}
                  loop
                  autoPlay
                  className="w-100"
                >
                  <source src={intro?.video_url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

                <button className="play-btn" onClick={handlePlayVideo}>
                  <i
                    className={`bi bi-${videoPlaying ? "pause" : "play"}-fill`}
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* Counts */}
        <section className="py-5">
          <div className="container-box">
            <div className="row">
              {counter?.map((count, i) => {
                // Check if count.count has a valid value (non-null and non-undefined)
                if (count.count) {
                  return (
                    <div key={count.id} className="col-xl-2 col-md-3 col-6 mb-md-0 mb-4 px-1">
                      <div className="career-counts career-text-content px-0">
                        <h2>{count.count}</h2>
                        <p className="mb-0">{count.title}</p>
                      </div>
                    </div>
                  );
                }
                return null; // Return nothing if count.count is invalid (null or undefined)
              })}
            </div>
          </div>
        </section>

        {/* Valuable words  */}
        <section className="py-5">
          <div className="bg-yellow pt-5 overflow-hidden">
            <div className="container-box ps-0">
              <div className="row justify-content-between align-items-end">
                <div className="col-md-5 position-relative value-all-box">
                  <div>
                    <div className="d-md-grid d-none rounded-circle border border-secondary design-circle design-circle3">
                      <div className="rounded-circle border border-dark design-circle design-circle2">
                        <div className="rounded-circle design-circle design-circle1"></div>
                      </div>
                    </div>
                    <img
                      src={value?.url}
                      alt=""
                      className="design-image value-image"
                    />
                    <img src={blockImg} alt="" className="blocks-img" />
                    <div className="design-btn text-yellow"> {value?.subtitle} </div>
                  </div>
                </div>

                <div className="col-md-7 mt-md-0 mt-5">
                  <div className="career-text-content pe-md-5 pb-5">
                    <h2>
                      <span style={{ color: "#000" }}>
                        {" "}
                        {getHeadingValues(value?.title)[0]}{" "}
                      </span>
                      {getHeadingValues(value?.title)[1]}
                    </h2>
                    <p className="my-3 justified-text"
                      dangerouslySetInnerHTML={{ __html: value?.description }}
                    />

                    <div>
                      <h6 className="text-dark-blue fw-bold mb-1">
                        {value?.name}
                      </h6>
                      <p >{value?.designation}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Value Card */}
        <section className="py-4">
          <div className="container-box">
            <div className="career-text-content  text-center mb-5 d-flex flex-column align-items-center">
              <div className="text-center m-auto">
                <h2 className="mb-0">
                  <span className="text-yellow fw-normal">
                    {getHeadingValues(our_value?.title)[0]}{" "}
                  </span>
                  {getHeadingValues(our_value?.title)[1]}
                </h2>
                <div className="yellow-line"></div>
              </div>
              <p style={{ fontWeight: 500, }} className="mt-3">
                {our_value?.subtitle}
              </p>
            </div>
            <div className="row ">
              {our_value?.divs?.map((value) => {
                return <ValueCard value={value} key={value?.id} />;
              })}
            </div>
          </div>
        </section>

        {/* Empowerment Thought Mutual Success */}

        <section className="py-5">
          <div className="bg-dark-blue overflow-hidden pt-5">
            <div className="container-box">
              <div className="row align-items-end">
                <div className="col-md-6 pb-5 pe-0">
                  <div className="career-text-content pe-md-5 ">
                    <h2 className="text-yellow"> {empowerment?.title} </h2>
                    <p className="my-3 text-yellow empower_content justified-text"
                      dangerouslySetInnerHTML={{ __html: empowerment?.description }}
                    />

                    <div>
                      <h6 className="text-yellow fw-bold mb-1">
                        {empowerment?.name}
                      </h6>
                      <p className="text-yellow">
                        {empowerment?.designation}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 text-end position-relative empower-all-box">
                  <div id="Empowerment-Thought">
                    <div
                      className="d-md-grid d-none rounded-circle border border-dark 
                                    design-circle design-circle2 design-circle2-2"
                    >
                      <div className="rounded-circle design-circle design-circle1"></div>
                    </div>
                  </div>
                  <div>
                    <img
                      src={empowerment?.url}
                      alt=""
                      className="design-image"
                    />

                    <img
                      src={blockImg}
                      alt=""
                      className="blocks-img blocks-img2"
                    />

                    <div className="design-btn design-btn2 text-dark-blue">
                      {" "}
                      {empowerment?.subtitle}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* award */}
        <section className="py-5">
          <div className="container-box pb-md-5">
            <div className="career-text-content text-center mb-5 d-flex flex-column align-items-center">
              <div>
              <h2 className="mb-0">
                <span className="text-yellow fw-normal">
                  {" "}
                  {getHeadingValues(award?.title)[0]}{" "}
                </span>{" "}
                {getHeadingValues(award?.title)[1]}{" "}
              </h2>
              <div className="yellow-line"></div>
              </div>
              <p className="mt-3 subtitle">
                {award?.subtitle}
              </p>
            </div>
            <div className="row">
              <Slider ref={sliderRef} {...sliderSettings2} className="pb-4">
                {award?.divs?.map((value) => {
                  return (
                    <div key={value?.id} className="col-md-3 mb-5 px-4">
                      <AwardCard value={value} image={value?.image} setAwardModal={setAwardModal} setAwardImg={setAwardImg} />
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </section>

        {/* Life Card */}
        <section className="py-5 bg-light-gray">
          <div className="container-box pt-md-4 mb-5">
            <div className="career-text-content text-center mb-5 d-flex flex-column align-items-center">
              <div>
                <h2 className="mb-0">
                  <span className="text-yellow fw-normal">
                    {" "}
                    {getHeadingValues(learn_more?.title)[0]}{" "}
                  </span>{" "}
                  {getHeadingValues(learn_more?.title)[1]}{" "}
                </h2>
                <div className="yellow-line"></div>
              </div>
             
              <p  className="mt-3"> 
                {learn_more?.subtitle}
              </p>
            </div>
            <div className="row">
              <Slider ref={sliderRef} {...sliderSettings3} className="pb-4">
                {learn_more?.divs?.map((value, i) => {
                  return (
                    <LifeCard
                      value={value}
                      color={i % 2 == 0 ? "purple" : "blue"}
                      key={value?.id}
                    />
                  );
                })}
              </Slider>
            </div>
          </div>
        </section>

        {/* trending categories */}
        <section className="py-5">
          <div className="container-box py-md-5">
            <div className="career-text-content text-center d-flex flex-column align-items-center">
              <div>
                <h2 className="mb-0">
                  <span className="text-yellow fw-normal">
                    {" "}
                    {getHeadingValues(trending?.title)[0]}{" "}
                  </span>{" "}
                  {getHeadingValues(trending?.title)[1]}{" "}
                </h2>
                <div className="yellow-line"></div>
              </div>
              <p className="subtitle mt-3">
                {trending?.subtitle}
              </p>
            </div>
            <div className="text-md-end text-center pe-md-5 mb-5 me-md-4 pb-md-3">
              <select
                className="form-select custom-input-select d-inline-block rounded-pill bg-yellow fw-bold text-dark-blue"
                onChange={getByLocation}
              >
                <option value=""> All Locations</option>
                {cities_options?.map((city) => {
                  return (
                    <option
                      key={city.id}
                      value={city?.id}
                    >
                      {city.city_name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="">
              <Slider ref={sliderRef} {...sliderSettings} className="pb-4">
                {filteredCatyegories?.map((value) => {
                  return (
                    <div key={value?.id} className="px-2 trending-card pb-md-2 pb-4">
                      <AwardCard value={value} image={homeSaleIcon} navigate={navigate} />
                    </div>
                  );
                })}
              </Slider>
              {
                filteredCatyegories.length === 0 &&
                <h4 className="w-100 text-center">No Category Found</h4>
                // <AwardCard value={{ title: 'No Category Available!' }} />
              }
            </div>
          </div>
        </section>

        {/* job card */}
        <section className="py-5">
          <div className="container-box pb-md-5">
            <div className="career-text-content  text-center mb-5 d-flex flex-column align-items-center">
              <div>
                <h2 className="mbn-0">
                  <span className="text-yellow fw-normal">
                    {" "}
                    {getHeadingValues(jobs?.title)[0]}{" "}
                  </span>{" "}
                  {getHeadingValues(jobs?.title)[1]}{" "}
                </h2>
                <div className="yellow-line"></div>
              </div>
              <p className="mt-3 subtitle">
                {jobs?.subtitle}
              </p>
            </div>
            <div className="text-end pb-3 ">
              <img
                src={blueIcon}
                data-value="blue"
                onClick={(e) =>
                  handleListView(e.target.getAttribute("data-value"))
                }
                alt="layouticon"
                className="me-3 p-2"
                style={{
                  borderRadius: "10px",
                  backgroundColor: "#f5f5f5",
                  cursor: "pointer",
                }}
              />

              <img
                src={yellowIcon}
                data-value="yellow"
                onClick={(e) =>
                  handleListView(e.target.getAttribute("data-value"))
                }
                alt="layouticon"
                className="p-2"
                style={{
                  borderRadius: "10px",
                  backgroundColor: "#f5f5f5",
                  cursor: "pointer",
                }}
              />
            </div>

            {handleView ? (
              <div>
                <div className="col-12 pb-5">
                  {jobs?.job_postings?.filter(job => job.popular == 1).map((job) => {
                    return <JobListCard jobData={job} key={job.id} />;
                  })}
                </div>
              </div>
            ) : (
              <>
                <div className="row mt-4">
                  {/* <JobListCard /> */}
                  {jobs?.job_postings?.filter(job => job.popular == 1).map((job) => {
                    return <JobCard jobData={job} />;
                  })}
                </div>
              </>
            )}

            <div className="text-center">
              <Link
                to={`${config.appUrl}job-posts`}
                className="job-btn py-3 text-decoration-none px-4 bg-yellow"
              >
                Find All Jobs
              </Link>
            </div>
          </div>
        </section>

        {/* cta type */}
        <section className="py-5 bg-light-gray ">
          <div className="container-box ">
            {
              career_title &&
              <div className="row ">
                <div className="col-md-6 cta-section">
                  <div className="mx-auto">
                    <h2> {career_title[0]?.title} </h2>

                    <div className="mt-4 pt-3">
                      <a
                        href="#apply_now"
                        // to={`${config.appUrl}join-as-a-propreneur`}
                        className="blue-btn text-decoration-none"
                      >
                        {career_title[0]?.subtitle}
                        <i className="bi bi-arrow-right text-yellow ms-2"></i>{" "}
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 cta-section">
                  <div className="mx-auto">
                    <h2>{career_title[1]?.title}</h2>

                    <div className="mt-4 pt-3">
                      <Link
                        to={`${config.appUrl}agent-registration`}
                        className="blue-btn text-decoration-none"
                      >
                        {career_title[1]?.subtitle}
                        <i className="bi bi-arrow-right text-yellow ms-2"></i>{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </section>

        {/* Form */}

        <CareerForm
          formheading={formheading}
          postLists={jobs?.job_postings?.map((job) => ({
            id: job.id,
            title: job.title,
          }))}
        />
      </div>

      {/* certificates */}
      <Modal
        open={awardModal}
        title=""
        width={650}
        className="career_page"
        centered
        onCancel={() => setAwardModal(false)}
        footer={<div className="footer-none"></div>}
      >
        <div className="mt-md-5">
          <img src={awardImg} alt="" className="w-100" />
        </div>
      </Modal>
    </>
  );
};

// Reusable Components

const ValueCard = ({ value }) => {
  return (
    <div className="col-md-4 mb-4" key={value.id}>
      <div className="value-card value-card-hover">
        <div>
          <h5 className="mb-0 fw-500">{value?.title}</h5>
          <p className="mb-0 justified-text"
            dangerouslySetInnerHTML={{ __html: value?.content }}
          />
        </div>

        <div className="hover_image" style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, .6)), url('${value?.url}')`
        }}>

        </div>
      </div>
    </div>
  );
};

const AwardCard = ({ value, image, setAwardModal, setAwardImg, navigate }) => {

  return (
    <>
      <div className="value-card award-card text-center"
        key={value.id}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if (navigate)
            navigate(`/job-posts/${value.id}`, {
              state: {
                category_name: value?.title
              }
            })
        }}
      >
        <div className="bg-yellow yellow-icon text-center  w-100 p-3 ">
          <img
            src={value?.url ? value?.url : value?.img}
            alt=""
            className=" object-fit-contain rounded "
            style={{ maxWidth: "100%", height: `100${value?.url ? 'px' : '%'}` }}
            onClick={() => {
              if (setAwardModal) {
                setAwardModal((prev) => !prev)
                setAwardImg(value?.url)
              }
            }}
          />
        </div>

        <h5 className="mb-0 mt-3" dangerouslySetInnerHTML={{ __html: value?.title }} />
        {/* <h5 className="mb-0 mt-3">{value?.title}</h5> */}
        <div className="subtitle" dangerouslySetInnerHTML={{ __html: value?.subtitle }} />
        {/* <div>{value?.subtitle}</div> */}
      </div>
    </>
  );
};

const LifeCard = ({ value, color }) => {
  return (
    <div className="col-md-4 mb-5 px-md-4 px-1 w-100" key={value.id}>
      <div className="value-card life-card text-center">
        <div className="bg-dark-blue blue-box">
          <div className={`overlay-box bg-overlay-${color}`}></div>

          <div className="main-life-content d-flex align-items-center text-start justify-content-between">
            <div className="align-self-end">
              <div style={{ minHeight: "3rem" }}>


                <Tooltip title={value?.title}>
                  {/* <Button type="primary">Scroll The Window</Button> */}
                  <h5 className="text-white" >{
                    value?.title.length > 28 ?
                      `${value?.title.slice(0, 28)}..` :
                      value?.title.slice(0, 28)
                  }</h5>
                </Tooltip>
              </div>
              <div className="align-self-end">
                <div>
                  <h6 className="text-yellow fw-bold mb-0 mt-3 ">
                    {value?.name}
                  </h6>
                  <p
                    className="mb-2"
                    style={{ fontWeight: 400, color: "#a1a9b5" }}
                  >
                    {value?.designation}
                  </p>
                </div>

                <img src={lifeIcon} alt="" className="life-icon" />
              </div>
            </div>

            {/* user */}
            <div>
              <img src={value.url} className="key-icon life-user" alt="" />
            </div>
          </div>
        </div>

        <div className="p-3 bg-white ">
          <div className="pt-2">
            <h5 className="justified-text"
              dangerouslySetInnerHTML={{ __html: value?.content }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const JobCard = ({ jobData }) => {

  const navigate = useNavigate();

  return (
    <div className="col-md-6 mb-5" key={jobData.id}>
      <div className="value-card job-card">
        <div className="bg-white post-desc-card border d-md-flex">
          <div className="bg-yellow yellow-icon ">
            <img
              src={megaPhoneIcon}
              alt="megaPhoneIcon"
              className="img-fluid"
            />

          </div>

          <div className="ms-md-4 mt-md-0 mt-3" style={{ flex: 1 }}>
            <h5 className="mb-0 lh-sm">{jobData.title}</h5>
            <div className="d-md-flex justify-content-between py-2  ">
              <span className="text-dark-blue fw-500">Propusers</span>
              <div className="mt-md-0 mt-2">
                <a href={jobData?.map_link} target='_blank' rel="noreferrer" className='text-dark-blue text-decoration-none fw-500'> <i className='bi bi-geo-alt text-yellow'></i> {jobData.job_location}</a>
              </div>
            </div>

            <div className="mt-4">
              {jobData?.job_type?.split(",")?.map((item, i) => {
                return (
                  <button
                    key={i}
                    className={`job-btn me-md-3 text-capitalize me-2 ${i !== 0 && "mt-md-0 mt-2"
                      }`}
                  >
                    {item}
                  </button>
                );
              })}
            </div>
          </div>
        </div>

        <div className=" pt-3 d-flex justify-content-between align-items-center ">
          <h6 className="lh-sm text-dark-blue fw-bold mb-0">
            {jobData.salary.split("/")[0]}
            <span className="fw-normal">
              /{jobData.salary.split("/")[1]}
            </span>{" "}
          </h6>

          <div>
            <button
              onClick={() =>
                navigate(`${config.appUrl}job-details/${jobData.slug}`)
              }
              className="job-btn me-2"
              style={{ backgroundColor: "#F0E4C0" }}
            >
              View Job
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const JobListCard = ({ jobData }) => {
  const navigate = useNavigate();

  return (
    <div
      className="p-3 mt-4 joblist"
      key={jobData.id}
      style={{ borderRadius: "15px", backgroundColor: "#f5f5f5" }}
    >
      <div className="row align-items-center">
        <div className="col-md-3 col-12">
          <div className="d-flex align-items-center justify-content-md-start justify-content-between">
            <p
              className="mb-0 fw-600 text-dark-blue"
              style={{ fontSize: "14px" }}
            >
              {jobData?.title}
            </p>
            <button className="posts-btn text-capitalize ms-md-3 mt-2 mt-md-0 mb-2 mb-md-0">
              {jobData?.openings} Openings
            </button>
          </div>
        </div>

        <div className="col-md-3 col-12">
          <div className="d-flex justify-content-start justify-content-md-center mt-2 mt-md-0">
            <button className="rounded-3 job-btn1 text-capitalize ms-md-3 mb-2 mb-md-0">
              {jobData?.job_type}
            </button>
          </div>
        </div>

        <div className="col-md-3 col-6">
          <div className="d-flex justify-content-start justify-content-md-center mt-2 mt-md-0">
            <a href={jobData?.map_link} target='_blank' rel="noreferrer"
              className="mb-0 fw-500 text-decoration-none text-dark-blue"
              style={{ fontSize: "14px" }}
            >
              {jobData?.job_location}
            </a>
          </div>
        </div>

        <div className="col-md-3 col-6">
          <div className="d-flex justify-content-end justify-content-md-end mt-1 mt-md-0">
            <button
              onClick={() =>
                navigate(`${config.appUrl}job-details/${jobData?.slug}`)
              }
              className="button text-dark-blue applynow text-capitalize ms-md-3"
            >
              Apply now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;
