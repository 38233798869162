import React, { useEffect, useRef, useState } from "react";
import useStyles from "../../Styles/LandingPageStyles/startingPageStyles";
import SearchBar from "./SearchBar";

import MorePopup from "./MorePopup";

function StartingPage({ homeData }) {

  const [show, setShow] = useState(false);
  const [search_type, setSearchType] = useState('buy')

  return (
    <>

      <div>
        <div
          className="bd-banner-home"
          style={{
            backgroundImage: `url(${homeData?.page_data?.first?.image})`,
          }}
        >
          <div className="bd-banner-section">
            <div>
              <div>
                <p className="main-head-banner">
                  <span>
                    {homeData?.page_data?.first?.title?.split(" ").slice(0, 2).join(" ")}
                  </span>{" "}
                  <strong>
                    {homeData?.page_data?.first?.title.split(" ").slice(2).join(" ")}
                  </strong>
                </p>
              </div>
              <div className="display-flex bd-buttons-sell my-4">
                <div>
                  <button className={`bd-buy-capsule border-0 ${search_type === 'buy' && 'bd-active-capsule fw-bold'}`}
                    value={'buy'} onClick={(e) => setSearchType(e.target.value)}>
                    Buy
                  </button>
                </div>
                <div className="pl-10">
                  <button className={`bd-buy-capsule border-0 ${search_type === 'rent' && 'bd-active-capsule fw-bold'}`}
                    value={'rent'} onClick={(e) => setSearchType(e.target.value)}>
                    Rent
                  </button>
                </div>
              </div>
              
              <SearchBar homeData={homeData} search_type={search_type} setSearchType={setSearchType} height={50}/>
              
              <div className="pt-10 px-md-0 px-2">
                <div className="display-flex align-item-center justify-content-center">
                  <div className="bd-category">Ware house</div>
                  <div className="bd-line-side">|</div>
                  <div className="bd-category">Cold Storage</div>
                  <div className="bd-line-side">|</div>
                  <div className="bd-category">Hospital Land</div>
                  <div className="bd-line-side">|</div>
                  <div className="bd-category">Factory</div>
                  <div className="bd-line-side">|</div>
                  <div className="bd-category">School Land</div>
                  <div className="bd-line-side">|</div>
                  <div className="bd-category" onClick={() => setShow(true)}>More ...</div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MorePopup show={show} setShow={setShow} />
    </>
  );
}

export default StartingPage;
