import React, { useEffect, useState } from "react";
import "../../assets/Css/Neighbourhood.css"
import { Link} from "react-router-dom";
import config, { axiosbaseurl } from "../../config";
import CustomLoader from "../CustomLoader";
import secureLocalStorage from "react-secure-storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const AllCities = ({homePageData}) => {

    const credentials = secureLocalStorage.getItem('credentials')
    const [AllCities, setAllCities] = useState([])
    const [active_cities, setActiveCities] = useState(2)
    const [page, setPage] = useState(1)
    const [items_perpage, setItemsPerPage] = useState(20)
    const [loading,setloading] = useState(false);

    const [properties, setProperties] = useState([]);
    const [ViewData , setViewData] = useState([])
    const [highestPropertyCityState, setHighestPropertyCityState] = useState([]);
    const [highestSaleCityState, setHighestSaleCityState] = useState([]);
    const [highestRentCityState, setHighestRentCityState] = useState([]);

    const total_pages = Math.ceil(ViewData?.length / items_perpage);

    const final_data = ViewData?.slice((page - 1) * items_perpage, page * items_perpage);

    const changePage = (pagecount) => {
        if (pagecount > 0 && pagecount <= total_pages) {
            setPage(pagecount)
            window.scrollTo({top: 0, behavior: 'instant'})
        }
    }

    useEffect(() => {
        async function getPropertyData() {
        setloading(true);
        try {
            const { data } = await axiosbaseurl.post('/properties', { user_id: credentials ? credentials?.user_id : 0 });
            
            if (data.success) {
            setProperties(data.data);
            }

        } catch (error) {
            console.error(error);
        } finally{
            setloading(false);
        }
        }

        getPropertyData();
    }, []);

    useEffect(() => {
        if (properties.length > 0) {
            // Helper function to count properties per city (ignoring state)
            const countPropertiesByCity = (properties) => {
                return properties.reduce((acc, property) => {
                const key = property.city;  // Use only city for grouping
                acc[key] = (acc[key] || 0) + 1;
                return acc;
                }, {});
            };
        
            // Step 1: Group properties by city (highest property city)
            const totalCityCount = countPropertiesByCity(properties);
        
            // Step 2: Filter properties by Sale and Rent, then count by city
            const saleProperties = properties.filter(property => property.property_on === 'Sale');
            const saleCityCount = countPropertiesByCity(saleProperties);
        
            const rentProperties = properties.filter(property => property.property_on === 'Rent');
            const rentCityCount = countPropertiesByCity(rentProperties);
        
            const sortCityByCount = (cityCount) => {
                const sorted = Object.entries(cityCount)
                .sort((a, b) => b[1] - a[1]) // Sort in descending order based on the property count
                .map(entry => entry[0]); // Extract the city key
                return sorted;
            };
        
            // Find the highest property cities (by total count)
            const highestPropertyCity = sortCityByCount(totalCityCount);
        
            // Find the highest sale property cities
            const highestSaleCity = sortCityByCount(saleCityCount);
        
            // Find the highest rent property cities
            const highestRentCity = sortCityByCount(rentCityCount);
        
            // Step 4: Set the states with the results
            setHighestPropertyCityState(highestPropertyCity);
            setHighestSaleCityState(highestSaleCity);
            setHighestRentCityState(highestRentCity);

            const filteredCities = highestPropertyCity.filter(cityStateKey => {
                const [cityId, stateId] = cityStateKey.split('-');
                return AllCities.some(city => city.id.toString() === cityId);
            });
        
            // Set the ViewData with filtered data
            setViewData(filteredCities);
        }
    }, [properties]);
    

    useEffect(() => {
        if(homePageData?.cities){
        setAllCities(homePageData?.cities)
        }

    }, [homePageData])

    const handlechangeView = (active) => {
        setActiveCities(active);
    
        let filteredData = [];
    
        switch (active) {
            case 1:
                // Set highest rent city data
                filteredData = highestRentCityState;
                break;
            case 2:
                // Set highest property city data
                filteredData = highestPropertyCityState;
                break;
            case 3:
                // Set highest sale city data
                filteredData = highestSaleCityState;
                break;
            default:
                // Handle any case where 'active' isn't 1, 2, or 3, if necessary
                break;
        }
    
        // Filter the selected data based on the cities present in AllCities
        const filteredCities = filteredData.filter(cityStateKey => {
            const [cityId, stateId] = cityStateKey.split('-');
            return AllCities.some(city => city.id.toString() === cityId);
        });
    
        // Set the ViewData with filtered data
        setViewData(filteredCities);
    }

    const filterTitle = (type) => {
        const data = homePageData?.titles?.filter(item => item.section?.toLowerCase()?.includes(type?.toLowerCase()))
        return data ? data : [""]
    }

    const heading = filterTitle("Cities")[0];


    return (
        <div className="pd-cities-back2 ">
        {loading && <CustomLoader/>}
        <div className="pt-40 pb-40 bd-cities-back ">
            <div className=" py-md-5 auto-container mt-5">
            <div>
                <div className="bd-main-service-section ">
                <div className="bd-dashed-line mr-10">
                </div>
                <div>
                    <p className="bd-service-first-heading">{heading?.title}</p>
                </div>
                <div>
                    <div className="bd-dashed-line ml-10"></div>
                </div>
                </div>
            </div>
            <div className="textCenter">
                <h2 className="bd-service-main-heading">{heading?.subtitle}</h2>
            </div>
            <div className="my-4 py-2">
                <div className="d-flex w-100 flex-md-row flex-column justify-content-center align-items-center home-cities">

                <div className="px-4 text-md-center top-city-tab">
                    <h5 className={`position-relative my-md-0 my-3 ${active_cities == 1 && 'active-city-tab'}`}
                    onClick={() => handlechangeView(1)}>Rentals House</h5>
                </div>

                <div className="px-4 text-md-center top-city-tab">
                    <h5 className={`position-relative my-md-0 my-3 ${active_cities == 2 && 'active-city-tab'}`}
                    onClick={() => handlechangeView(2)}>Real Estate In Cities</h5>
                </div>

                <div className="px-4 text-md-center top-city-tab">
                    <h5 className={`position-relative my-md-0 my-3 ${active_cities == 3 && 'active-city-tab'}`}
                    onClick={() => handlechangeView(3)}>Affordable House</h5>
                </div>
                </div>
            </div>
            <div className=" bd-responsive-cities">
                <div className="row justify-content-md-start justify-content-center mx-0">
                    {
                        final_data?.map((cityStateKey, i) => {
                            const [cityId, stateId] = cityStateKey.split('-');
                            // Find city by matching cityId and stateId in AllCities
                            const cityData = AllCities.find(city => city.id.toString() === cityId);

                            if (cityData) {
                            return (
                                <div key={cityData?.id} className="col-xl-3 col-md-4 col-sm-6 col-11 mt-4">
                                <div className="city-image-box top-city-box">
                                    <img loading="lazy" src={cityData.image} alt={cityData?.city_name} className="w100" />
                                    <Link to={`${config.appUrl}propuser-locality/${cityData?.id}`}>
                                    <div className="city_name">{cityData?.city_name}</div>
                                    </Link>
                                </div>
                                </div>
                            );
                            }

                            return null;
                        })
                    }
                </div>
                <div>
                    {/* Pagination buttons */}
                    {
                        page > 1 && (
                            <div className='d-flex justify-content-center flex-wrap my-5'>

                                <div className={`pagination-btn rounded-2 me-3 mt-2 
                                ${page === 1 && 'opacity-50'}`}
                                    onClick={() => changePage(page - 1)}>
                                    <i className='bi bi-arrow-left'></i>
                                </div>

                                {
                                    [...Array(total_pages)].map((_, pagecount) => {
                                        pagecount += 1
                                        return (
                                            <div key={pagecount} className={`pagination-btn text-center rounded-2 mx-1 mt-2 ${page === pagecount && 'activepage'}`} onClick={() => changePage(pagecount)}>
                                                {pagecount}
                                            </div>
                                        )
                                    })
                                }

                                <div className={`pagination-btn rounded-2 ms-3 mt-2 ${(page === total_pages) && 'opacity-50'}`}
                                    onClick={() => changePage(page + 1)}>
                                    <i className='bi bi-arrow-right'></i>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            </div>
        </div>
        </div >
    );

}
export default AllCities