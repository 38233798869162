import React, { useEffect, useRef, useState } from "react";
import { faArrowRight, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";
import config, { axiosbaseurl } from "../../config";
import Slider from "react-slick";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";

function SearchingCities({ topCityData, ReraData, heading }) {

  const Location = secureLocalStorage.getItem("ip_location");

  useEffect(() => {
    if(Location){
      fetchCities();
    }
  },[Location,topCityData])

  const [NearCities,setNearCities] = useState([]);

  // calculate nearby data
  const haversineDistance = (lat1, lon1, lat2, lon2) => {
    const toRad = (value) => (value * Math.PI) / 180;

    const R = 6371; // Radius of the Earth in km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distance in km
  };

  function fetchCities() {
      const temp_data =
        topCityData?.filter(item => (item.lat && item.lng))
          ?.map(prop => (
            {
              id : prop?.id,
              image:prop?.image,
              locality_name: prop?.locality_name,
              city_name: prop?.city_name,
              zipcode: 201301,
              distance: Math.round(haversineDistance(prop?.lat, prop?.lng, Location?.latitude, Location?.longitude))
            }
          ))

      const unique_near_data = temp_data?.filter(item => item.distance <= 150)
      setNearCities(unique_near_data)
  }

  const credentials = secureLocalStorage.getItem('credentials')
  const [AllCities, setAllCities] = useState([])
  const [active_cities, setActiveCities] = useState(2)

  const [properties, setProperties] = useState([]);
  const [highestPropertyCityState, setHighestPropertyCityState] = useState([]);
  const [highestSaleCityState, setHighestSaleCityState] = useState([]);
  const [highestRentCityState, setHighestRentCityState] = useState([]);

  useEffect(() => {
    async function getPropertyData() {
      try {
        const { data } = await axiosbaseurl.post('/properties', { user_id: credentials ? credentials?.user_id : 0 });
        
        if (data.success) {
          setProperties(data.data);
        }

      } catch (error) {
        console.error(error);
      }
    }

    getPropertyData();
  }, []);

  useEffect(() => {
    if (properties.length > 0) {
      // Helper function to count properties per city (ignoring state)
      const countPropertiesByCity = (properties) => {
        return properties.reduce((acc, property) => {
          const key = property.city;  // Use only city for grouping
          acc[key] = (acc[key] || 0) + 1;
          return acc;
        }, {});
      };
  
      // Step 1: Group properties by city (highest property city)
      const totalCityCount = countPropertiesByCity(properties);
  
      // Step 2: Filter properties by Sale and Rent, then count by city
      const saleProperties = properties.filter(property => property.property_on === 'Sale');
      const saleCityCount = countPropertiesByCity(saleProperties);
  
      const rentProperties = properties.filter(property => property.property_on === 'Rent');
      const rentCityCount = countPropertiesByCity(rentProperties);
  
      const sortCityByCount = (cityCount) => {
        const sorted = Object.entries(cityCount)
          .sort((a, b) => b[1] - a[1]) // Sort in descending order based on the property count
          .map(entry => entry[0]); // Extract the city key
        return sorted;
      };
  
      // Find the highest property cities (by total count)
      const highestPropertyCity = sortCityByCount(totalCityCount);
  
      // Find the highest sale property cities
      const highestSaleCity = sortCityByCount(saleCityCount);
  
      // Find the highest rent property cities
      const highestRentCity = sortCityByCount(rentCityCount);
  
      // Step 4: Set the states with the results
      setHighestPropertyCityState(highestPropertyCity);
      setHighestSaleCityState(highestSaleCity);
      setHighestRentCityState(highestRentCity);
    }
  }, [properties]);
  

  useEffect(() => {
    if(topCityData){
      setAllCities(topCityData)
    }

  }, [topCityData])


  const sliderRef = useRef();

  const reraSettings = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 3,
    swipeToSlide: true,
    autoplay: true,
    arrows: false,
    mobileFirst:true,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="pd-cities-back2 ">
      <div className="pt-40 pb-40 bd-cities-back ">
        <div className=" py-md-5 auto-container">
          <div>
            <div className="bd-main-service-section ">
              <div className="bd-dashed-line mr-10">
              </div>
              <div>
                <p className="bd-service-first-heading">{heading?.title}</p>
              </div>
              <div>
                <div className="bd-dashed-line ml-10"></div>
              </div>
            </div>
          </div>
          <div className="textCenter">
            <h2 className="bd-service-main-heading">{heading?.subtitle}</h2>
          </div>
          <div className="my-4 py-2">
            <div className="d-flex w-100 flex-md-row flex-column justify-content-center align-items-center home-cities">

              <div className="px-4 text-md-center top-city-tab">
                <h5 className={`position-relative my-md-0 my-3 ${active_cities == 1 && 'active-city-tab'}`}
                  onClick={() => setActiveCities(1)}>Rentals House</h5>
              </div>

              <div className="px-4 text-md-center top-city-tab">
                <h5 className={`position-relative my-md-0 my-3 ${active_cities == 2 && 'active-city-tab'}`}
                  onClick={() => setActiveCities(2)}>Real Estate In Cities</h5>
              </div>

              <div className="px-4 text-md-center top-city-tab">
                <h5 className={`position-relative my-md-0 my-3 ${active_cities == 3 && 'active-city-tab'}`}
                  onClick={() => setActiveCities(3)}>Affordable House</h5>
              </div>
            </div>
          </div>
          <div className=" bd-responsive-cities">
            <div className="row justify-content-md-start justify-content-center mx-0">
              {/* {
                // ?.filter(item => item.filter_type == active_cities)
                AllCities?.map((city, i) => {
                  return (
                    <div key={city?.id} className="col-xl-3 col-md-4 col-sm-6 col-11 mt-4">
                      <div className="city-image-box top-city-box">
                        <img loading="lazy" src={city.image} alt={city?.city_name} className="w100"  />
                        <Link to={`${config.appUrl}propuser-locality/${city?.id}`}>
                          <div className="city_name">{city?.city_name}</div>
                        </Link>
                      </div>
                    </div>
                  )
                })
              } */}
              {
                active_cities === 1 && (
                  <div className="row justify-content-md-start justify-content-center mx-0">
                    {
                      highestRentCityState
                      ?.map((cityStateKey) => {
                          const [cityId, stateId] = cityStateKey.split('-');
                          // Find city by matching cityId in AllCities
                          return AllCities.find(city => city.id.toString() === cityId);
                      })
                      .filter(city => city !== undefined) // Remove undefined values (cities not found)
                      .slice(0, 4) // Limit to 4 cities
                      .map((cityData, i) => {
                          return (
                              <div key={cityData?.id} className="col-xl-3 col-md-4 col-sm-6 col-11 mt-4">
                                  <div className="city-image-box top-city-box">
                                      <img loading="lazy" src={cityData.image} alt={cityData?.city_name} className="w100" />
                                      <Link to={`${config.appUrl}property/home-for-sale?search_query=${cityData?.city_name}&type=city`}>
                                          <div className="city_name">{cityData?.city_name}</div>
                                      </Link>
                                  </div>
                              </div>
                          );
                      })
                    }{
                      highestRentCityState.length === 0 && (
                        Location === "" ? (
                          AllCities
                            .slice(4, 8) // Limit to 4 cities
                            .map((cityData, i) => {
                              return (
                                <div key={cityData?.id} className="col-xl-3 col-md-4 col-sm-6 col-11 mt-4">
                                  <div className="city-image-box top-city-box">
                                    <img loading="lazy" src={cityData.image} alt={cityData?.city_name} className="w100" />
                                    <Link to={`${config.appUrl}property/home-for-sale?search_query=${cityData?.city_name}&type=city`}>
                                      <div className="city_name">{cityData?.city_name}</div>
                                    </Link>
                                  </div>
                                </div>
                              );
                            })
                        ) : (
                          NearCities
                            ?.slice(0, 4) // Limit to 4 cities
                            .map((cityData, i) => {
                              return (
                                <div key={cityData?.id} className="col-xl-3 col-md-4 col-sm-6 col-11 mt-4">
                                  <div className="city-image-box top-city-box">
                                    <img loading="lazy" src={cityData.image} alt={cityData?.city_name} className="w100" />
                                    <Link to={`${config.appUrl}property/home-for-sale?search_query=${cityData?.city_name}&type=city`}>
                                      <div className="city_name">{cityData?.city_name}</div>
                                    </Link>
                                  </div>
                                </div>
                              );
                            })
                        )
                      )
                    }                      
                  </div>
                )
              }
              {
                active_cities === 2 && (
                  <div className="row justify-content-md-start justify-content-center mx-0">
                    {
                      highestPropertyCityState
                      ?.map((cityStateKey) => {
                          const [cityId, stateId] = cityStateKey.split('-');
                          // Find city by matching cityId in AllCities
                          return AllCities.find(city => city.id.toString() === cityId);
                      })
                      .filter(city => city !== undefined) // Remove undefined values (cities not found)
                      .slice(0, 4) // Limit to 4 cities
                      .map((cityData, i) => {
                          return (
                              <div key={cityData?.id} className="col-xl-3 col-md-4 col-sm-6 col-11 mt-4">
                                  <div className="city-image-box top-city-box">
                                      <img loading="lazy" src={cityData.image} alt={cityData?.city_name} className="w100" />
                                      <Link to={`${config.appUrl}property/home-for-sale?search_query=${cityData?.city_name}&type=city`}>
                                          <div className="city_name">{cityData?.city_name}</div>
                                      </Link>
                                  </div>
                              </div>
                          );
                      })
                    }
                    {
                      highestPropertyCityState.length === 0 && (
                        Location === "" ? (
                          AllCities
                            .slice(4, 8) // Limit to 4 cities
                            .map((cityData, i) => {
                              return (
                                <div key={cityData?.id} className="col-xl-3 col-md-4 col-sm-6 col-11 mt-4">
                                  <div className="city-image-box top-city-box">
                                    <img loading="lazy" src={cityData.image} alt={cityData?.city_name} className="w100" />
                                    <Link to={`${config.appUrl}property/home-for-sale?search_query=${cityData?.city_name}&type=city`}>
                                      <div className="city_name">{cityData?.city_name}</div>
                                    </Link>
                                  </div>
                                </div>
                              );
                            })
                        ) : (
                          NearCities
                            ?.slice(0, 4) // Limit to 4 cities
                            .map((cityData, i) => {
                              return (
                                <div key={cityData?.id} className="col-xl-3 col-md-4 col-sm-6 col-11 mt-4">
                                  <div className="city-image-box top-city-box">
                                    <img loading="lazy" src={cityData.image} alt={cityData?.city_name} className="w100" />
                                    <Link to={`${config.appUrl}property/home-for-sale?search_query=${cityData?.city_name}&type=city`}>
                                      <div className="city_name">{cityData?.city_name}</div>
                                    </Link>
                                  </div>
                                </div>
                              );
                            })
                        )
                      )
                    } 
                  </div>
                )
              }
              {
                active_cities === 3 && (
                  <div className="row justify-content-md-start justify-content-center mx-0">
                    {
                      highestSaleCityState
                      ?.map((cityStateKey) => {
                          const [cityId, stateId] = cityStateKey.split('-');
                          // Find city by matching cityId in AllCities
                          return AllCities.find(city => city.id.toString() === cityId);
                      })
                      .filter(city => city !== undefined) // Remove undefined values (cities not found)
                      .slice(0, 4) // Limit to 4 cities
                      .map((cityData, i) => {
                          return (
                              <div key={cityData?.id} className="col-xl-3 col-md-4 col-sm-6 col-11 mt-4">
                                  <div className="city-image-box top-city-box">
                                      <img loading="lazy" src={cityData.image} alt={cityData?.city_name} className="w100" />
                                      <Link to={`${config.appUrl}property/home-for-sale?search_query=${cityData?.city_name}&type=city`}>
                                          <div className="city_name">{cityData?.city_name}</div>
                                      </Link>
                                  </div>
                              </div>
                          );
                      })
                    }{
                      highestSaleCityState.length === 0 && (
                        Location === "" ? (
                          AllCities
                            .slice(4, 8) // Limit to 4 cities
                            .map((cityData, i) => {
                              return (
                                <div key={cityData?.id} className="col-xl-3 col-md-4 col-sm-6 col-11 mt-4">
                                  <div className="city-image-box top-city-box">
                                    <img loading="lazy" src={cityData.image} alt={cityData?.city_name} className="w100" />
                                    <Link to={`${config.appUrl}property/home-for-sale?search_query=${cityData?.city_name}&type=city`}>
                                      <div className="city_name">{cityData?.city_name}</div>
                                    </Link>
                                  </div>
                                </div>
                              );
                            })
                        ) : (
                          NearCities
                            ?.slice(0, 4) // Limit to 4 cities
                            .map((cityData, i) => {
                              return (
                                <div key={cityData?.id} className="col-xl-3 col-md-4 col-sm-6 col-11 mt-4">
                                  <div className="city-image-box top-city-box">
                                    <img loading="lazy" src={cityData.image} alt={cityData?.city_name} className="w100" />
                                    <Link to={`${config.appUrl}property/home-for-sale?search_query=${cityData?.city_name}&type=city`}>
                                      <div className="city_name">{cityData?.city_name}</div>
                                    </Link>
                                  </div>
                                </div>
                              );
                            })
                        )
                      )
                    }                      
                  </div>
                )
              }
            </div>
            <div>
              <div className="textRight mr-15 pt-20 mt-4">
                {
                  (active_cities === 1 && highestRentCityState.length > 4) && (
                    <Link to={`${config.appUrl}our-cities`} className="bd-SeeAll-btn py-2 text-decoration-none"> See All <FontAwesomeIcon icon={faArrowRight} className="pl-10" /></Link>
                  )
                }
                {
                  (active_cities === 2 && highestPropertyCityState.length > 4) && (
                    <Link to={`${config.appUrl}our-cities`} className="bd-SeeAll-btn py-2 text-decoration-none"> See All <FontAwesomeIcon icon={faArrowRight} className="pl-10" /></Link>
                  )
                }
                {
                  (active_cities === 3 && highestSaleCityState.length > 4) && (
                    <Link to={`${config.appUrl}our-cities`} className="bd-SeeAll-btn py-2 text-decoration-none"> See All <FontAwesomeIcon icon={faArrowRight} className="pl-10" /></Link>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Rera Slider */}
      <div>
        <div className="pt-15 pb-15 prop-responsive" style={{ backgroundColor: '#F8F9FB' }}>
          <div className="d-flex justify-content-center align-items-center px-4">
            <div className="pd-first-city-arrow text-center fs-4"
              onClick={() => sliderRef?.current?.slickPrev()}>
              <FontAwesomeIcon icon={faChevronLeft} className="text-dark-blue" /></div>
            <div className="rera-slider mx-md-3 mx-2">
              <Slider ref={sliderRef} {...reraSettings}>
                {
                  ReraData?.map((rera) => {
                    return (
                      <div className="pd-first-city text-center" key={rera.id}>{rera.state_name} RERA: <span>{rera.rera_no}</span></div>
                    )
                  })
                }
              </Slider>
            </div>
            <div className="pd-first-city-arrow text-center fs-4"
              onClick={() => sliderRef?.current?.slickNext()}
            > <FontAwesomeIcon icon={faChevronRight} className="text-dark-blue" /></div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default SearchingCities;
