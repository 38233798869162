import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvent, useMap, LayersControl, Circle, Polygon } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import { faBath, faBed, faSquareCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import config from '../../../config';


// Function to create a round text marker
const createRoundTextMarker = (text, size, bg_color, borderColor, text_color) => {
    return new L.DivIcon({
        className: 'custom-icon',
        html: `<div class='font-saira fw-600' style="background-color: ${bg_color}; color: ${text_color}; outline: 8px solid ${borderColor}; border-radius: 50%; transform: translateX(15px) translateY(13px); text-align: center; line-height: 30px; width: 30px; height: 30px; font-size: ${size / 4}px; ">${text}</div>`,
        iconSize: [size, size],
        iconAnchor: [size / 2, size / 2],
        popupAnchor: [0, -size / 2]
    });
};

// Custom icons for cities, areas, and localities
const cityIcon = (text) => createRoundTextMarker(text, 60, '#f2be1a', '#1228444d', '#122844'); // Larger size
const areaIcon = (text) => createRoundTextMarker(text, 60, '#122844', '#1228444d', '#fff'); // Medium size
const localityIcon = (text) => createRoundTextMarker(text, 60, '#f2be1a', '#1228444d', '#122844'); // Smaller size

// map styles
const countryStyle = {
    color: 'black',
    opacity: 1,
    height: '100%',
    width: '100%'
};

// Custom hook to listen to zoom events and display areas/localities
const ZoomListener = ({ setShowAreas, setShowLocalities, zoomLevel, setZoomLevel }) => {
    const map = useMapEvent('zoomend', () => {
        const currentZoom = map.getZoom();
        setZoomLevel(currentZoom);
        if (currentZoom >= 11 && currentZoom < 16) {
            setShowAreas(true);
            setShowLocalities(false);
        } else if (currentZoom >= 16) {
            setShowAreas(false);
            setShowLocalities(true);
        } else {
            setShowAreas(false);
            setShowLocalities(false);
        }
    });
    return null;
};

// Function to handle map zoom and center
const ChangeView = ({ center, zoom }) => {
    const map = useMap();
    map.flyTo(center, zoom, { animate: true, duration: .8 }); // Smooth zoom
    return null;
};


// =====================
// for map border heighlight
// Function to calculate the centroid (center point) of given coordinates
const calculateCentroid = (coords) => {
    let latSum = 0;
    let lonSum = 0;
    coords.forEach(coord => {
        latSum += coord.latitude;
        lonSum += coord.longitude;
    });
    return [latSum / coords.length, lonSum / coords.length];
};

// Function to calculate the distance between two coordinates in meters
const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371000; // Radius of Earth in meters
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in meters
};


const PropertyMapView = ({ propertyData, property_type }) => {

    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedArea, setSelectedArea] = useState(null);
    const [zoomLevel, setZoomLevel] = useState(6);
    const [mapCenter, setMapCenter] = useState([19.6633, 75.3003]); // Initial center to Maharashtra
    const [showAreas, setShowAreas] = useState(false); // Control the display of areas
    const [showLocalities, setShowLocalities] = useState(false); // Control the display of localities
    const [properties, setProperties] = useState([])
    // for circle highlight
    const [centerHighlight, setCenterHighlight] = useState(false)
    const [maxRadius, setMaxRadius] = useState(0)

    const transformProperties = (properties) => {
        const cityMap = {};

        properties?.forEach(property => {
            const { city_name, locality_name, property_name } = property;

            if (!cityMap[city_name]) {
                cityMap[city_name] = {
                    id: property.city,
                    name: city_name,
                    count: 0, // This can be updated as needed
                    lat: Number(property.city_latitude),
                    lng: Number(property.city_longitude),
                    localities: {}
                };
            }

            if (!cityMap[city_name].localities[locality_name]) {
                cityMap[city_name].localities[locality_name] = {
                    lat: Number(property.localities_lat),
                    lng: Number(property.localities_lang),
                    name: locality_name,
                    count: 0, // This can be updated as  needed
                    properties: []
                };
            }

            cityMap[city_name].localities[locality_name].properties.push({
                lat: Number(property.latitude),
                lng: Number(property.longitude),
                name: property_name,
                id: property?.id,
                price: property?.price,
                address: property?.address,
                beds: property?.bhk,
                baths: property?.bathroom,
                size: property?.built_area,
                image: property?.image?.filter(img => img?.upload_type === 'image')[0]?.upload_name,
                count: 0 // This can be updated as needed
            });

            // Update counts
            cityMap[city_name].count += 1; // Increment city count
            cityMap[city_name].localities[locality_name].count += 1; // Increment locality count
            // cityMap[city_name].localities[locality_name].properties[property_name].count += 1;
        });

        // Convert the cityMap object into an array
        return Object.values(cityMap).map(city => ({
            ...city,
            localities: Object.values(city.localities)
        }));
    };

    useEffect(() => {
        async function getProperties() {

            // const availableProps = propertyData?.filter(prop => (prop.latitude && prop.longitude))
            const availableProps = propertyData;

            const newData = transformProperties(availableProps)

            const coordinates = availableProps?.map(prop => ({
                latitude: Number(prop.latitude),
                longitude: Number(prop.longitude)
            }))
            availableProps?.forEach(prop => {
                coordinates?.push({
                    latitude: Number(prop.localities_lat),
                    longitude: Number(prop.localities_lang)
                })
                coordinates?.push({
                    latitude: Number(prop.city_latitude),
                    longitude: Number(prop.city_longitude)
                })
            })

            // if (coordinates.length > 2) {
            // Calculate the centroid(center point) of all cities
            const center = calculateCentroid(coordinates);
            setCenterHighlight(center)
            // Calculate the maximum radius needed to cover all cities
            let max_radius = 0;
            coordinates?.forEach(city => {
                const distance = calculateDistance(center[0], center[1], city.latitude, city.longitude);
                if (distance > max_radius) {
                    max_radius = distance;
                }
            });
            setMaxRadius(max_radius)

            // console.log('center,max_radius', center,max_radius)

            // }

            newData.forEach((city) => {
                city.localities.forEach((locality) => {
                    const properties = locality.properties;

                    // Grouping properties by their latitude and longitude
                    let groupedProperties = [];

                    properties.forEach((property) => {
                        const existingGroup = groupedProperties.find(
                            (group) => group.lat === property.lat && group.lng === property.lng
                        );

                        if (existingGroup) {
                            existingGroup.all_properties.push(property);
                            existingGroup.count = existingGroup.count + 1
                        } else {
                            groupedProperties.push({
                                lat: property.lat,
                                lng: property.lng,
                                count: 1,
                                all_properties: [property],
                            });
                        }
                    });

                    // Assign the grouped properties back to the locality
                    locality.properties = groupedProperties;
                });
            })

            setProperties(newData)

            if (newData?.length === 1) {
                setSelectedCity(newData[0]);
                setMapCenter([newData[0].lat, newData[0].lng])
                setZoomLevel(12)
            }

            // console.log('newData', newData)
        }
        if (propertyData)
            getProperties()
    }, [propertyData])

    const handleCityClick = (city) => {
        console.log(city);
        setSelectedCity(city);
        setMapCenter([city?.localities[0].lat, city?.localities[0].lng]);
        setZoomLevel(11); // Zoom to city level
    };

    const handleAreaClick = (area) => {
        setSelectedArea(area);
        setCurrentIndex(0);
        setMapCenter([area.properties[0].lat, area.properties[0].lng]);
        setZoomLevel(17); // Zoom to area level
    };

    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePropClick = (value) => {
        // Logging before the state update to track changes
        console.log("Before update - Current Index:", currentIndex, "Direction:", value);
    
        // Check if we want to move forward (value === 1) and the currentIndex is within bounds
        if (value === 1 && currentIndex < selectedArea?.properties.length - 1) {
            // Increment the index to move to the next property
            const newIndex = currentIndex + 1;
    
            // Update the map center to the new property
            setMapCenter([selectedArea.properties[newIndex].lat, selectedArea.properties[newIndex].lng]);
    
            // Set the new current index
            setCurrentIndex(newIndex);
        }
    
        // Check if we want to move backward (value === 0) and the currentIndex is greater than 0
        if (value === 0 && currentIndex > 0) {
            // Decrement the index to move to the previous property
            const newIndex = currentIndex - 1;
    
            // Update the map center to the new property
            setMapCenter([selectedArea.properties[newIndex].lat, selectedArea.properties[newIndex].lng]);
    
            // Set the new current index
            setCurrentIndex(newIndex);
        }
    
        // Logging after the state update to track changes
        console.log("After update - Current Index:", currentIndex, "Direction:", value);
    }

    return (
        <div id='property_map' className='w-100 h-100 position-relative'>
            <MapContainer center={mapCenter}
                scrollWheelZoom={true}
                zoom={zoomLevel}
                // center={position}
                zoomControl={true}
                doubleClickZoom={true}
                touchZoom={true}
                boxZoom={false}
                style={countryStyle}
            >
                {/* Change the map's view dynamically when city/area is clicked */}
                <ChangeView center={mapCenter} zoom={zoomLevel} />

                {/* TileLayer to load the map tiles */}
                {/* basic view */}
                {/* manage satelite and map view */}
                <LayersControl position="topleft">
                    <LayersControl.BaseLayer checked name="Map View">
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            maxZoom={20}
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="Satellite View">
                        <TileLayer
                            url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                            subdomains={['mt1', 'mt2', 'mt3']}
                            maxZoom={20}
                            attribution='&copy; <a href="https://opentopomap.org/copyright">OpenTopoMap</a> contributors'
                        />
                    </LayersControl.BaseLayer>
                </LayersControl>

                {/* Listen to zoom events and manage area/locality visibility */}
                <ZoomListener
                    setShowAreas={setShowAreas}
                    setShowLocalities={setShowLocalities}
                    zoomLevel={zoomLevel}
                    setZoomLevel={setZoomLevel} />

                {/* Draw a single circle that covers all cities */}
                {
                    centerHighlight !== 0 && maxRadius !== 0 &&
                    <Circle
                        center={centerHighlight}
                        radius={maxRadius} // The radius is set to cover the farthest city
                        color="black"
                        fillColor="#f2f2f2"
                        fillOpacity={0.2}
                        weight={2}
                    />
                }

                {/* Highlight city boundaries using circles */}
                {/* <Polygon
                    positions={cityCoordinates}
                    color="black"
                    fillColor="#f2be1a"
                    fillOpacity={0.1}
                    weight={2}
                >

                </Polygon> */}

                {/* Render city markers with custom icons, hide when areas are displayed */}
                {!showAreas && !showLocalities && properties && properties.map((city) => (
                    <Marker
                        key={city.id}
                        position={[city.lat, city.lng]}
                        icon={cityIcon(city.count)} // Apply the custom icon for cities
                        eventHandlers={{
                            click: () => handleCityClick(city),
                        }}
                    >
                        {/* <Popup>{city.name}</Popup> */}
                    </Marker>
                ))}

                {/* If a city is selected and zoom is sufficient, show its areas */}
                {selectedCity && showAreas && selectedCity.localities.map((area, index) => (
                    <Marker
                        key={index}
                        position={[area.lat, area.lng]}
                        eventHandlers={{
                            click: () => handleAreaClick(area),
                        }}
                        icon={areaIcon(area.count)} // Apply the custom icon for areas
                    >
                        {/* <Popup>{area.name}</Popup> */}
                    </Marker>
                ))}

                {/* If an area is selected and zoom is sufficient, show its localities */}

                {selectedArea && showLocalities && selectedArea.properties.map((property, index) => (
                    <Marker
                        key={index}
                        position={[property.lat, property.lng]}
                        icon={localityIcon(property.count)} // Apply the custom icon for localities with count
                    >
                        <Popup
                            autoPan={false}
                            closeButton={false}
                            offset={[105, 5]}
                        >
                            <div className='map-property-popup-box'>
                                {
                                    property.all_properties.map((prop) => {
                                        return <PopUpBox property={prop} key={prop.id} property_type={property_type} />
                                    })
                                }
                                {/* <PopUpBox property={property} property_type={property_type} /> */}
                            </div>
                            <div className='mb-2 d-flex justify-content-between'>
                                {
                                    currentIndex > 0 && (
                                        <button className='btn text-black bg-white rounded-4 p-1 px-2' onClick={() => handlePropClick(0)}>
                                            <i className='bi bi-arrow-left-circle fs-6'></i>
                                        </button>
                                    )
                                }
                                {
                                    currentIndex < (selectedArea?.properties.length-1) && (
                                        <div className='text-end w-100'>
                                            <button className='btn text-black bg-white rounded-4 p-1 px-2' onClick={() => handlePropClick(1)}>
                                                <i className='bi bi-arrow-right-circle fs-6'></i>
                                            </button>
                                        </div>
                                    )
                                }
                            </div>
                        </Popup>
                    </Marker>
                ))}
            </MapContainer>
        </div>
    );
};


const PopUpBox = ({ property, property_type }) => {

    return (
        <Link to={`${config.appUrl}home-for-${property_type}/property-details/${property?.id}`} className='text-decoration-none'>
            <div className="bg-white map-property-popup mb-2">
                <div className='d-flex'>
                    <div>
                        <img src={property.image} alt="" />
                    </div>
                    <div className='popup-content ms-3'>
                        <h6 className='font-saira text-dark-blue fw-bold mb-1'> ₹ {property.price?.toLocaleString()}</h6>
                        <p className='font-saira mb-1 mt-0 text-dark-blue fw-500'> {property.address} </p>
                        {/* <hr className='my-1' /> */}
                        <div className="bd-icon-section-rent border-top py-1">
                            <div className="bd-three-rent">
                                <div className='font-saira fw-500'>  <FontAwesomeIcon icon={faBed} /> {property?.beds} <br /> Beds <span className="middle-line">  </span></div>
                            </div>
                            <div className="bd-three-rent " >
                                <div className='font-saira fw-500'> <FontAwesomeIcon icon={faBath} /> {property?.baths} <br /> Bath <span className="middle-line"> </span></div>
                            </div>
                            <div className="bd-three-rent">
                                <div className='font-saira fw-500'>
                                    <FontAwesomeIcon
                                        icon={faSquareCaretDown} />
                                    {property?.size} <br /> SqFt
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default PropertyMapView;
