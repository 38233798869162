import React from "react";
import soonbgImg from "../assets/HeroBanner.jpg"
import { useLocation } from "react-router-dom";
const ComingSoon = () => {

    const locationData = useLocation()
    
    return (
        <div>
            <div className="bd-banner-rera comming-soon-bg" style={{
                backgroundImage: `url(${soonbgImg})`
            }}>
                {/* <div className="rera-banner-overlay"></div> */}
                <div className="bd-rerabanner-section" style={{
                    backgroundColor: '#0d1b2c66'
                }}>
                    <div className="rerabanner-inner-section text-center text-white">
                        <h1 className="soon-head fw-bold">{locationData?.state?.data}</h1>
                        <div className="d-flex align-items-center">
                            <div className="yellow-line soon-line my-4 mx-auto"></div>
                            <div className="yellow-dot"></div>
                            <div className="yellow-line soon-line my-4 mx-auto"></div>
                        </div>
                        <h4 className="display-3 text-yellow fw-normal">Coming Soon...</h4>
                    </div>
                </div>
            </div>

             
        </div>
    );
};

export default ComingSoon;
