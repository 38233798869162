import React, { useEffect, useState } from "react";
import "../../assets/Css/Blog.css";
import BlogCard from "./BlogCard";
import Slider from "react-slick";
import config, { axiosbaseurl } from "../../config";
import CustomLoader from "../CustomLoader";
import moment from "moment";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import toast, { Toaster } from "react-hot-toast";
import { customToastOptions } from "../../Styles/popup_style";
import CommentSection from "./Components/CommentSection";

const Blog = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [blogs, setBlogs] = useState([]);
  const [currBlog, setcurrBlog] = useState({});
  const [blogHeading, setBlogHeading] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { blog_slug } = useParams();
  const credentials = secureLocalStorage.getItem("credentials");

  function getBlogSaveStatus(isSave) {
    return ((isSave === 0) ? false : true)
  }
  // getting all blogs
  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    const uid = credentials ? credentials?.user_id : 0;
    axiosbaseurl.get(`/blogs/${uid}`)
      .then((res) => {

        setBlogHeading(res.data.titles[0])

        let updatedData;
        if (credentials) {
          updatedData = res.data.data.map((post) => {
            return {
              ...post,
              isSaved: getBlogSaveStatus(post.savedBlog),
            };
          });
        } else {
          updatedData = res.data.data.map((post) => {
            return { ...post, isSaved: false };
          });
        }

        setBlogs(updatedData);
        if (!blog_slug) navigate(`${config.appUrl}blog/${updatedData[0].slug}`);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
    return () => {
      setBlogs([])
      controller.abort();
    };
  }, []);

  // getting blog by slug
  useEffect(() => {
    if (blog_slug && blogs.length > 0) { // Ensure blogs are fetched and avoid unnecessary fetches
      fetchBlogData();
    }
  }, [blog_slug, blogs.length]);  // Track both `blog_slug`, `blogs`, and `isBlogDataFetched`
  
  async function fetchBlogData() {
    if (blogs.length) {
      try {
        setLoading(true);
        const uid = credentials ? credentials?.user_id : 0;
        const response = await axiosbaseurl.get(`/blog_data/${blog_slug}/${uid}`);
        
        // If status is 0, redirect to the first blog
        if (response.data.status === 0) {
          if (blogs.length) {
            navigate(`${config.appUrl}blog/${blogs[0]?.slug}`);
          }
          return;
        }
  
        const current_blog = response.data;
        if (credentials) {
          current_blog.isSaved = getBlogSaveStatus(current_blog.savedBlog);
        }
        setcurrBlog(current_blog);
        window.scrollTo(0, 700);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  }

  // updating blog views based on current blog
  useEffect(() => {
    const update_views = blogs.map((blog) => {
      if (blog.id === currBlog.id) {
        blog.views = currBlog.views
        blog.comments = currBlog.comments
      }
      return blog;
    })

    setBlogs(update_views)
  }, [currBlog])

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    mobileFirst: true,
    arrows: false,
    afterChange: (current) => setCurrentSlide(current),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ],
    appendDots: (dots) => (
      <div style={{ padding: "10px" }}>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "6px",
          height: "6px",
          backgroundColor: "gray",
          borderRadius: "50%",
          display: "inline-block",
          margin: "0 4px",
        }}
      ></div>
    ),
  };

  const handleSavedPost = async (post_id) => {
    if (credentials) {
      setLoading(true);
      try {
        const postData = {
          user_id: credentials?.user_id,
          blog_id: post_id,
          type: 1,
        };

        const res = await axiosbaseurl.post("/saveBlog", postData);

        const updatedData = blogs.map((post) => {

          if (post.id == post_id) {
            post.isSaved = true
          }
          return post;
        });
        setBlogs(updatedData);

        setcurrBlog((prev) => updatedData.find((item) => item.id === prev.id));
        toast.success("Post saved successfully!");

        setLoading(false);
      } catch (error) {
        console.log(error);
        toast.error("Something Went Wrong");
        setLoading(false);
      }
    } else {
      toast.error("Please Login to save post.");
    }
  };

  const trending_posts = blogs?.filter(item => item.id != currBlog?.id).slice(0, 4);

  return (
    <div>
      {loading && <CustomLoader />}
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={customToastOptions}
      />

      <div className="pt-90">
        <div className="blogs auto-container-lg">
          <div className="position-relative ">

            <div className="section-1 text-md-start text-center blog-main-heading">
              <div className="mb-2">
                <h3 className="bd-header-overview font-saira ">
                  <span className="color-yellow font-saira">
                    {blogHeading?.title?.split(' ')[0]} </span>
                  {blogHeading?.title?.split(' ')?.slice(1)}
                </h3>
                <div className="yellow-line mx-md-0 mx-auto"></div>
              </div>

              <div className="span3">
                {blogHeading?.subtitle}
              </div>
            </div>

            <div className="blog-list blog-box mt-5 pt-2">
              <Slider
                {...settings}
                style={{ transform: "inherit", zIndex: "999" }}
              >
                {blogs.map((blog, index) => {
                  const visibleIndex =
                    (index - currentSlide + blogs.length) % blogs.length;
                  let translateY;
                  switch (visibleIndex) {
                    case 0:
                      translateY = 0;
                      break;
                    case 1:
                      translateY = -45;
                      break;
                    case 2:
                      translateY = -100;
                      break;
                    case 3:
                      translateY = -150;
                  }
                  return (
                    <BlogCard
                      key={index}
                      blog={blog}
                      translateY={translateY}
                      handleSavedPost={handleSavedPost}
                    />
                  );
                })}
              </Slider>
            </div>
          </div>


          <section className="row mt-5">
            <div className="col-md-8">
              <div
                className="news-bg-img mt-md-0 mt-5"
                style={{
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("${currBlog?.post_img}")`,
                  backgroundSize: "cover",
                  backgroundPosition: "10% 30%",
                }}
              >
                {currBlog?.isSaved ? (
                  <div className="p-3 text-end">
                    <span className="badge bg-yellow text-dark ">Saved</span>
                  </div>
                ) : (
                  <div className="save-text d-flex justify-content-end align-items-center">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSavedPost(currBlog?.id)}
                    >
                      Save and Read Later{" "}
                    </div>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSavedPost(currBlog?.id)}
                      className="save-span ms-3"
                    >
                      <i className="bi bi-arrow-down"></i>
                    </span>
                  </div>
                )}

                <div className="d-flex align-items-end h-100">
                  <div>
                    {currBlog?.category && (
                      <button className="property-btn">
                        <span>{currBlog?.category}</span>
                      </button>
                    )}
                    <div className="hotprop-content">{currBlog?.post_title}</div>
                    <div className="date">
                      <span>
                        {moment(currBlog?.created_at).format("MMMM D, YYYY")}
                        <span className="text-yellow fs-6 px-3">|</span>
                        <i className="bi bi-eye me-1"></i> {currBlog?.views}
                        <span className="text-yellow fs-6 px-3">|</span>
                        <i className="bi bi-wechat me-1"></i> {currBlog?.comments}
                      </span>
                    </div>
                    <div>
                      {/* <div className="read1 d-flex align-items-center">
                      READ MORE &nbsp; &nbsp; &nbsp;
                      <span className="save-span">
                        <i className="bi bi-arrow-right"></i>
                      </span>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="section-3">
                {/* dynamic content */}
                <div>
                  <div className="title font-saira mb-4">
                    {currBlog?.post_title}
                    <div className="yellow-line"></div>
                  </div>
                  {
                    <div
                      dangerouslySetInnerHTML={{ __html: currBlog?.post_content }}
                      className="editor_main_content justified-text"
                    />
                  }

                </div>

                {/* comments section */}
                <CommentSection post_id={currBlog?.id} type={"blog"} />
              </div>
            </div>

            <div className="col-md-4">
              <div className="trending-news">
                <div>
                  <span className="span1">Trending Blogs Post</span>
                  <div className="yellow-line"></div>
                </div>
                <div className="news-div mt-3">
                  {trending_posts?.map((blog) => {
                    return (
                      <Link
                        to={`${config.appUrl}blog/${blog?.slug}`}
                        key={blog?.id}
                        className="text-decoration-none"
                      >
                        <div className="news-div1">
                          <div>
                            <img src={blog?.url} alt=""></img>
                          </div>
                          <div className="content">
                            <span className="span1 lh-sm mb-1">
                              {blog?.post_title?.slice(0, 20)}..
                            </span>
                            <span>
                              {blog.category}
                            </span>
                            <p className="font-saira justified-text">
                              {blog?.post_content
                                ?.replace(/<[^>]+>/g, "")
                                .slice(0, 100)}
                              ..
                            </p>
                            <div className="fw-normal d-flex mt-2">
                              <span className="pe-2">
                                {moment(blog?.created_at).format(
                                  "MMMM D, YYYY"
                                )}
                              </span>
                              <span className="px-2 border-left-yellow">
                                <i className="bi bi-eye text-yellow me-1"></i>  {blog?.views}
                              </span>
                              <span className="px-2 border-left-yellow">
                                <i className="bi bi-wechat text-yellow me-1"></i> {blog?.comments}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>



        </div>
      </div>
    </div>
  );
};


export default Blog;
