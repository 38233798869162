import React, { useEffect, useState } from "react";
import "../../assets/Css/Neighbourhood.css"
import { Link } from "react-router-dom";
import config, { axiosbaseurl } from "../../config";
import CustomLoader from "../CustomLoader";
import SearchBar from "material-ui-search-bar";

const Neighbourhoodcities = ({ homePageData, loading }) => {


    const [cities, setCities] = useState([])
    const [allCities, setAllCities] = useState([])
    const [searchString, setSearchString] = useState("")
    const [headingData, setHeadingData] = useState({})

    const [page, setPage] = useState(1)

    const [items_perpage, setItemsPerPage] = useState(16)

    useEffect(() => {
        if (homePageData) {
            setHeadingData(homePageData?.titles[4])
            setCities(homePageData?.localitities?.filter(item => item.status == 1))
            setAllCities(homePageData?.localitities?.filter(item => item.status == 1))
        }
    }, [homePageData])

    // search functionality
    const handleSearch = (text) => {

        setSearchString(text)
        const filterCities = allCities?.filter((city) => {
            return city.locality_name.toLowerCase().includes(text.toLowerCase());
        })
        setCities(filterCities)

        if (page !== 1)
            setPage(1)
    }

    // pagination logic
    const total_pages = Math.ceil(cities?.length / items_perpage);

    const final_data = cities?.slice(page * items_perpage - items_perpage, page * items_perpage)

    const changePage = (pagecount) => {
        if (pagecount > 0 && pagecount <= total_pages) {
            setPage(pagecount)
            window.scrollTo({ top: 0, behavior: 'instant' })
        }
    }

    return (
        <>
            {loading && <CustomLoader />}

            <div className='pt-90'>
                <div className="bd-cites-section">
                    <section >
                        <div className="bd-main-service-section ">
                            <div className="bd-dashed-line mr-10">
                            </div>
                            <div>
                                <p className="bd-service-first-heading text-uppercase">{headingData?.title}</p>
                            </div>
                            <div>
                                <div className="bd-dashed-line ml-10"></div>
                            </div>
                        </div>
                    </section>

                    <section className="pb-4">
                        <div>
                            <div className="d-flex justify-content-center position-relative">
                                <div className="textCenter mx-3">
                                    <h2 className="bd-service-main-heading">{headingData?.subtitle}</h2>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section >
                        <div>
                            <div className="container-box bd-responsive-cities px-md-5 px-xxl-0" style={{ maxWidth: "1290px" }}>

                                <div className="row justify-content-center mb-4">
                                    <div className="col-md-6" >
                                        <SearchBar
                                            className="bd-home-search"
                                            value={searchString}
                                            cancelOnEscape
                                            onCancelSearch={() => {
                                                setPage(1)
                                                handleSearch('')
                                            }}
                                            onChange={(event) => handleSearch(event)}
                                            style={{ borderRadius: "25px" }}
                                        ></SearchBar>
                                    </div>
                                </div>

                                <div className="row justify-content-md-start justify-content-center">
                                    {final_data?.map((city) => (
                                        <div key={city.id} className="col-lg-3 col-md-4 col-sm-6 col-11 mt-4">
                                            <div className="city-image-box top-city-box">
                                                <img className="w100" src={city.image} alt={city.city_name} />
                                                <Link to={`${config.appUrl}propuser-locality/${city?.city}`}>
                                                    <div className="city_name">{city.locality_name}</div>
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                                    {
                                        cities?.length === 0 &&
                                        <h4 className="mt-5 text-center paragraph ">
                                            No Neighbour Found!
                                        </h4>
                                    }
                                </div>

                                {
                                    final_data?.length ?
                                        <div className='d-flex justify-content-center flex-wrap my-5'>

                                            <div className={`pagination-btn rounded-2 me-3 mt-2 
                                ${page === 1 && 'opacity-50'}`}
                                                onClick={() => changePage(page - 1)}>
                                                <i className='bi bi-arrow-left'></i>
                                            </div>

                                            {
                                                [...Array(total_pages)].map((_, pagecount) => {
                                                    pagecount += 1
                                                    return (
                                                        <div key={pagecount} className={`pagination-btn text-center rounded-2 mx-1 mt-2 ${page === pagecount && 'activepage'}`} onClick={() => changePage(pagecount)}>
                                                            {pagecount}
                                                        </div>
                                                    )
                                                })
                                            }

                                            <div className={`pagination-btn rounded-2 ms-3 mt-2 ${(page === total_pages) && 'opacity-50'}`}
                                                onClick={() => changePage(page + 1)}>
                                                <i className='bi bi-arrow-right'></i>
                                            </div>

                                        </div> : ''
                                }
                            </div>
                        </div>
                    </section>
                </div>

            </div>


        </>
    )

}
export default Neighbourhoodcities