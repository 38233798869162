import React, { useEffect, useState } from "react";
import "../../../Styles/sharedStyles/Footer.css";
import { Link } from "react-router-dom";
import config, { axiosbaseurl } from "../../../config";

function Footer() {
  const [social_links, setSocialLinks] = useState([]);
  const [footerData, setFooterData] = useState(null);
  const [pagePermissions, setPagePermissions] = useState([]);

  const handleMovetoTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const controller = new AbortController();
    async function getPermittedPages() {
      try {
        const { data } = await axiosbaseurl.get(`/activePages`);
        if (data.success == true) {
          setPagePermissions(data.data);
          getSocialLinks()
        }
      } catch (error) {
        console.log(error);
      }
    }
    getPermittedPages()

    return () => {
      controller.abort();
    };
  }, []);


  async function getSocialLinks() {
    try {
      const { data } = await axiosbaseurl.get(`/mediaLinks`);

      if (data.success == true) {
        setSocialLinks(data.data.links);
        setFooterData(data.data.footer[0])
      }
    } catch (error) {
      console.log(error);
    }
  }

  const checkAccess = (id) => {
    return pagePermissions?.find(page => page.id === id)?.status === 1
  }

  return (
    <div>
      <section className="bd-main-section-footer">
        <div className="pt-120">
          <div className="overlay footer-overlay px-md-0 px-2">
            <div className="footer-social-icons">
              <div className="key-icon social-circle">
                <a
                  href={social_links[0]?.url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-dark-blue "
                >
                  <i className="fa fa-facebook"></i>
                </a>
              </div>

              <div className="key-icon social-circle">
                <a
                  href={social_links[4]?.url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-dark-blue youtube-size"
                >
                  <i className="bi bi-youtube fs-2"></i>
                </a>
              </div>

              <div className="key-icon social-circle">
                <a
                  href={social_links[1]?.url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-dark-blue "
                >
                  <i className="bi bi-twitter-x fs-4"></i>
                </a>
              </div>

              <div className="key-icon social-circle">
                <a
                  href={social_links[2]?.url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-dark-blue "
                >
                  <i className="fa fa-instagram"></i>
                </a>
              </div>

              <div className="key-icon social-circle">
                <a
                  href={social_links[3]?.url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-dark-blue "
                >
                  <i className="fa fa-linkedin"></i>
                </a>
              </div>
            </div>

            <div className="bd-basic-sizing">
              <div className="footer-row ">
                <div className="bd-section-footer1">
                  <div className="bd-footer-header">
                    <p>Company</p>
                  </div>
                  {
                    checkAccess(1) &&
                    <div className="bd-section-all-txt">
                      <Link
                        onClick={handleMovetoTop}
                        to={`${config.appUrl}about-us`}
                      >
                        About Us
                      </Link>
                    </div>
                  }
                  {
                    checkAccess(2) &&
                    <div className="bd-section-all-txt">
                      <Link
                        onClick={handleMovetoTop}
                        to={`${config.appUrl}management-team`}
                      >
                        Management Team
                      </Link>
                    </div>
                  }
                  <div className="bd-section-all-txt">
                    <Link
                      onClick={handleMovetoTop}
                      to={`${config.appUrl}career`}
                    >
                      Career
                    </Link>
                  </div>
                  <div className="bd-section-all-txt">
                    <Link
                      onClick={handleMovetoTop}
                      to={`${config.appUrl}contact-us`}
                    >
                      Contact Us
                    </Link>
                  </div>
                  <div className="bd-section-all-txt">
                    <Link onClick={handleMovetoTop} to={`${config.appUrl}propreneur-experience`}>
                      Propreneur Experience
                    </Link>
                  </div>
                  <div className="bd-section-all-txt">
                    <Link
                      onClick={handleMovetoTop}
                      to={`${config.appUrl}office-location`}
                    >
                      Office Location
                    </Link>
                  </div>
                  {
                    checkAccess(4) &&
                    <div className="bd-section-all-txt">
                      <Link
                        onClick={handleMovetoTop}
                        to={`${config.appUrl}terms-conditions`}
                      >
                        Term & Conditions
                      </Link>
                    </div>
                  }
                  {
                    checkAccess(3) &&
                    <div className="bd-section-all-txt">
                      <Link
                        onClick={handleMovetoTop}
                        to={`${config.appUrl}privacy-policy`}
                      >
                        Privacy Policy
                      </Link>
                    </div>
                  }
                </div>

                <div className="bd-section-footer1">
                  <div className="bd-footer-header">
                    <p>App Market</p>
                  </div>
                  <div className="bd-section-all-txt">
                    <Link
                      onClick={handleMovetoTop}
                      to={`${config.appUrl}propuser-makeover`}
                    >
                      Prop Makeover
                    </Link>
                  </div>
                  <div className="bd-section-all-txt">
                    <Link
                      onClick={handleMovetoTop}
                      to={`${config.appUrl}coming-soon`}
                      state={{ data: "Prop Academy" }}
                    >
                      Prop Academy
                    </Link>
                  </div>
                  <div className="bd-section-all-txt">
                    <Link
                      onClick={handleMovetoTop}
                      to={`${config.appUrl}coming-soon`}
                      state={{ data: "Prop Samadhan" }}
                    >
                      Prop Samadhan
                    </Link>
                  </div>
                  <div className="bd-section-all-txt">
                    <Link
                      onClick={handleMovetoTop}
                      to={`${config.appUrl}coming-soon`}
                      state={{ data: "PropCafe" }}
                    >
                      Prop Cafe
                    </Link>
                  </div>
                  <div className="bd-section-all-txt">
                    <Link
                      onClick={handleMovetoTop}
                      to={`${config.appUrl}coming-soon`}
                      state={{ data: "Prop Wallet" }}
                    >
                      Prop Wallet
                    </Link>
                  </div>
                </div>

                <div className="bd-section-footer1">
                  <div className="bd-footer-header">
                    <p>Explore</p>
                  </div>
                  <div className="bd-section-all-txt">
                    <Link
                      onClick={handleMovetoTop}
                      to={`${config.appUrl}proprenuers`}
                    >
                      Propreneur
                    </Link>
                  </div>
                  <div className="bd-section-all-txt">
                    <Link onClick={handleMovetoTop} to={`${config.appUrl}news`}>
                      News
                    </Link>
                  </div>
                  <div className="bd-section-all-txt">
                    <Link onClick={handleMovetoTop} to={`${config.appUrl}blog`}>
                      Blogs
                    </Link>
                  </div>
                  <div className="bd-section-all-txt">
                    <Link
                      onClick={handleMovetoTop}
                      to={`${config.appUrl}neighbourhoods`}
                    >
                      Neighbourhood
                    </Link>
                  </div>
                  <div className="bd-section-all-txt">
                    <Link
                      onClick={handleMovetoTop}
                      to={`${config.appUrl}sitemap`}
                    >
                      Sitemap
                    </Link>
                  </div>
                  <div className="bd-section-all-txt">
                    <Link
                      onClick={handleMovetoTop}
                      to={`${config.appUrl}home-loan`}
                    >
                      Home Loan
                    </Link>
                  </div>
                  {
                    checkAccess(8) &&
                    <div className="bd-section-all-txt">
                      <Link onClick={handleMovetoTop} to={`${config.appUrl}rera`}>
                        RERA
                      </Link>
                    </div>
                  }
                  {
                    checkAccess(9) &&
                    <div className="bd-section-all-txt">
                      <Link
                        onClick={handleMovetoTop}
                        to={`${config.appUrl}authorities`}
                      >
                        Authorities
                      </Link>
                    </div>
                  }
                </div>

                <div className="bd-section-footer2">
                  <div className="bd-footer-header">
                    <p> Terms of Service</p>
                  </div>
                  {/* Get the App */}
                  {/* <div className="d-md-block d-flex">
                    <img className="get-app-img mb-30 me-sm-4 me-2" src={img1}></img>
                    <img className="get-app-img mb-30" src={img2}></img>
                  </div> */}
                  <div>
                    <p className="bd-policy justified-text">
                      <span dangerouslySetInnerHTML={{ __html: footerData?.title }} />
                    </p>
                    {/* <p className="inform-text">
                      <span dangerouslySetInnerHTML={{__html: footerData?.subtitle}} />
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="bd-text-policy text-white px-md-0 px-4 mb-0">
          {" "}
          @ 2020-25 All right reserved RTN Propusers Pvt Ltd
        </p>
      </section>
    </div>
  );
}

export default Footer;
