import React from "react";
import StartingPage from "./StartingPage";
import MakeOver from "./MakeOver";
import Services from "../LandingPage/Services";
import Cities from "./Cities";
import Tesimonials from "./Tesimonials";
import SearchingCities from "./SearchingCities";
import "../../Styles/Responsive/HomeResponsive.css";

import CustomLoader from "../CustomLoader";

function LandingPage({ homePageData, loading }) {

  const filterTitle = (type) => {
    const data = homePageData?.titles?.filter(item => item.section?.toLowerCase()?.includes(type?.toLowerCase()))
    return data ? data : [""]
  }

  return (
    <>
      {loading && <CustomLoader />}

      <StartingPage homeData={homePageData} />

      <MakeOver makeOverData={homePageData?.page_data?.second} keyFeatures={homePageData?.page_data?.fourth} />

      <Services serviceData={homePageData?.page_data?.third} heading={filterTitle("Property_type")[0]} />

      <Cities
        heading={filterTitle("Neighbourhood")[0]}
        loading={loading}
        cityData={homePageData?.cities?.filter(city => (city.show_on_locality == 1))}
      />

      <Tesimonials
        heading={filterTitle("Testimonial")[0]}
        testimonialData={homePageData?.testimonials?.filter(item => item.status == 1)}
      />

      <SearchingCities
        heading={filterTitle("Cities")[0]}
        topCityData={homePageData?.cities}
        ReraData={homePageData?.states_with_rera?.map((rera) => ({
          ...rera,
          rera_no: rera.rera_no || 'NA'
        }))}
      />

    </>
  );
}

export default LandingPage;
